.event_list{
    .e_list{
        @extend .m-t-20;
        border-bottom: 1px solid $grey-300;
        display: inline-block;
        width: 100%;

        .e_name{
            font-size: $font-size + 5px;
            line-height: 18px;
        }
        address{
            font-size: $font-size + 1px;
            color: $grey-500;
            margin: 0;
            i{
                font-size: $font-size + 2px;
            }
        }
    }
}
.event-control {
    background-color: $grey-200;
    font-size: $font-size + 1px;
    cursor: pointer;

    &:hover {
        background-color:$white;
    }
    a {
        color: $black;
        cursor: pointer;
        opacity: .5;
        &:hover {
            opacity: 1;
        }
    }
    &[class*='bg-'] {
        &:not(.bg-default):not(.bg-white) {
            a {
                color: $black !important;
                opacity: 1;
                &:hover {
                    color:$white !important;
                }
            }
        }
    }
}
.fc-overlay {
    display: none;
    position: absolute;
    min-width: 260px;
    padding: 15px 0;
    top: auto;
    left: 50%;
    margin-left: -150px;
    z-index: 1000;
    color: $black !important;

    &.left {
        left: 100%;
        top: -15px;
        padding: 0 10px;
        margin: 0;
    }
    &.right {
        left: auto;
        right: 100%;
        top: -15px;
        padding: 0 10px;
        margin: 0;
    }
    .panel {
        padding: 10px;
    }
}
.fc {
    .fc-toolbar {
        .fc-button {
            color: $grey-500;
            background: $transparent-bg;
            border: none;
            box-shadow: none;
            text-shadow: none;
            margin: 0;            

            &:hover,
            &.fc-state-active{
                background: $blue;
                color: $white;
            }
        }
        h2 {
            margin: 0;
            font-size: $font-size + 6px; 
        }
        @media only screen and (max-width: $break-small - 1px){
            .fc-left{
                margin-bottom: 10px;
            }
            .fc-right{
                width: 100%;
            }
        }
    }

    .fc-view-container {
        
        .fc-view {
            &.fc-basic-view,
            &.fc-agenda {
                >table {
                    >thead {
                        tr {
                            th,
                            td {
                                &.fc-widget-header {
                                    font-size: $font-size;                                    
                                    background-color: $grey-300;
                                    color: $black;
                                    padding: 10px;
                                    border: 0;                                    
                                }
                                &.fc-agenda-gutter {
                                    padding: 0;
                                }
                            }
                            td {
                                &.fc-widget-header {
                                    padding: 2px;
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                &.fc-widget-content {
                                    border: 0;
                                    border-bottom: 1px solid $grey-200;
                                    border-right: 1px solid $grey-200;

                                    &.fc-state-highlight {
                                        background: $white;
                                    }
                                }
                                .fc-day-number {
                                    font-size: $font-size + 2px;
                                    padding: 5px 10px;
                                    margin: 5px;
                                    color: $black;

                                    &.fc-state-highlight {
                                        @include border-radius(50%);
                                        background-color: $red;
                                        color: $white;                                        
                                        padding: 4px 6px;
                                        float: right;
                                    }
                                }
                                &:first-child {
                                    &.fc-widget-content {
                                        border-left: 1px solid $grey-200;
                                    }
                                }
                            }
                        }
                    }
                }
                .fc-body {
                    .fc-row .fc-bg {
                        border-bottom: 1px solid $grey-200;
                    }
                    .fc-row:last-of-type .fc-bg {
                        border-bottom: 0;
                    }
                }
            }

            &.fc-agenda {
                .fc-agenda-allday {
                    .fc-agenda-axis,
                    .fc-agenda-gutter {
                        font-size: $font-size;
                        border-left: 1px solid $grey-300;
                        border-right: 1px solid $grey-300;
                        background-color: $grey-200;
                        color: $black;
                        font-weight: $font-weight-300;
                        padding: 10px 5px;
                    }
                }
                >table {
                    >tbody {
                        >tr {
                            >td {
                                &.fc-state-highlight {
                                    background: $grey-200;
                                }
                                &.fc-sat {
                                    border-right: 0;
                                }
                            }
                        }
                    }
                }
                .fc-agenda-slots {
                    tr {
                        line-height: 35px;
                        th {
                            font-size: $font-size;
                            font-weight: $font-weight-300;
                            border-left: 1px solid $grey-300;
                            border-right: 1px solid $grey-300;
                            background-color: $grey-200;
                            color: $black;                                                        
                            padding-top: 10px;
                            border: 0;
                        }
                        td.fc-widget-content {
                            border-bottom: 1px solid $grey-300;
                            line-height: 50px;

                            &:last-child {
                                border-right: 0;
                            }
                        }
                        &.fc-minor {
                            td.fc-widget-content {
                                border-bottom: 1px solid $grey-300;
                            }
                        }
                    }
                }
                .fc-agenda-divider {
                    border: 1px solid $grey-300;
                    
                    .fc-agenda-divider-inner {
                        background-color: $grey-200;
                        height: 3px;
                    }
                }
            }
            @media only screen and (max-width: $break-small - 1px){
                .fc-scroller{
                    height: 100% !important;
                }   
            }            
        }
        .badge{
            color: $white !important;
        }
    }
    .fc-more-popover{
        @include border-radius(10px);
        width: 250px;
        box-shadow: 0 1px 15px rgba($dark,.2);
        
        .fc-header{
            padding: 10px;
            background: $transparent-bg;
        }
    }
    .fc-event{
        border: 0;
        color: $white !important;
    }
}