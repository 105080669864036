//Query Node
.theme-light-pqs {
    svg.leader-line {
      path {
        fill: none;
        stroke-width: 1.5;
      }

      g,
      .leader-line-plugs-face {
        filter: invert(1);
      }

      text {
        stroke-width: 0 !important;
        font-size: 12px;
        filter: invert(1);
      }
    }

    .btn.btn-icon.btn-45 {
        background: #ffffff !important;
        border: 1px solid #DBDADE !important;
        border-radius: 12px !important;
        width: 4.5rem !important;
        min-width: 4.5rem !important;
        height: 4.5rem !important;
    
        &.invalid-props {
          // border: 3px solid red !important;
          opacity: 0.35;
        }
    
        .pds-i-active,
        .pds-i,
        .alt-pds-i {
          padding: 2px;
        }
    
        .pds-i-active,
        .alt-pds-i:hover,
        .pds-i:hover {
          color: #222222 !important;
        }
    
        &:hover,
        &.btn-outline-active {
          color: #ffffff !important;
          background-color: #11a5b8 !important;
          transition: 0.1s all;
    
          .qry-cmd-item-title {
            img {
              // filter: none;
              filter: brightness(100);
            }
          }
    
          .pds-i-active,
          .alt-pds-i:hover,
          .pds-i:hover {
            color: #d8d8d8 !important;
          }
        }
    
        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          border-bottom: 5px solid transparent;
        }
    
        .qry-cmd-item-title {
          display: flex;
          flex-direction: column;
          align-items: center;
    
          img {
            width: 20px;
            margin-bottom: 3px;
            filter: invert(1);
          }
    
          span {
            font-size: 10px;
          }
        }
    
        /* REFACTORED */
        &.btn-outline-search,
        &.btn-outline-select,
        &.btn-outline-selectdb,
        &.btn-outline-cfg,
        &.btn-outline-config,
        &.btn-outline-createidx,
        &.btn-outline-createindex,
        &.btn-outline-drop {
            color: #FF7E6B;
            font-weight: bold;
            // border-bottom: 5px solid #e73f5b !important;
        }
    
        &.btn-outline-aggr,
        &.btn-outline-sort,
        &.btn-outline-aggregate,
        &.btn-outline-explode,
        &.btn-outline-implode,
        &.btn-outline-merge,
        &.btn-outline-store {
            color: #977390;
            font-weight: bold;
            // border-bottom: 5px solid #00bfff !important;
        }
    
        /* EXISTING */
        &.btn-outline-source {
            color: rgb(231, 63, 91);
            font-weight: bold;
            // border-bottom: 5px solid rgb(231, 63, 91) !important;
        }
    
        &.btn-outline-success {
          &:after {
            border-bottom: 5px solid #0abb87 !important;
          }
        }
    
        &.btn-outline-warning {
          &:after {
            border-bottom: 5px solid #ffb822 !important;
          }
        }
    
        &.btn-outline-yellow {
          &:after {
            border-bottom: 5px solid #d7c100 !important;
          }
        }
    
        &.btn-outline-corelate,
        &.btn-outline-correlateref,
        &.btn-outline-extract,
        &.btn-outline-limit {
            color: #FF934F;
            font-weight: bold;
            // border-bottom: 5px solid rgb(210, 107, 45) !important;
        }
    
        &.btn-outline-transpose {
            color: #9ed600;
            font-weight: bold;
            // border-bottom: 5px solid #9ed600 !important;
        }
    
        &.btn-outline-copy,
        &.btn-outline-view,
        &.btn-outline-filter,
        &.btn-outline-dedup {
            color: #A5B5BF;
            font-weight: bold;
            // border-bottom: 5px solid #a4be00 !important;
        }

        /* GROUPING DATA PROCESSING - DIAGRAM COLOR ICON */ 
        /* Group Red Salmon Color*/
        &.btn-outline-search img {
          filter: invert(66%) sepia(86%) saturate(18%) saturate(2259%) hue-rotate(316deg) brightness(100%) contrast(102%)
        }
        &.btn-outline-select img {
          filter: invert(66%) sepia(86%) saturate(18%) saturate(2259%) hue-rotate(316deg) brightness(100%) contrast(102%)
        }
        &.btn-outline-selectdb img {
          filter: invert(66%) sepia(86%) saturate(18%) saturate(2259%) hue-rotate(316deg) brightness(100%) contrast(102%)
        }
        &.btn-outline-cfg img {
          filter: invert(66%) sepia(86%) saturate(18%) saturate(2259%) hue-rotate(316deg) brightness(100%) contrast(102%)
        }
        &.btn-outline-config img {
          filter: invert(66%) sepia(86%) saturate(18%) saturate(2259%) hue-rotate(316deg) brightness(100%) contrast(102%)
        }
        &.btn-outline-createidx img {
          filter: invert(66%) sepia(86%) saturate(18%) saturate(2259%) hue-rotate(316deg) brightness(100%) contrast(102%)
        }
        &.btn-outline-createindex img {
          filter: invert(66%) sepia(86%) saturate(18%) saturate(2259%) hue-rotate(316deg) brightness(100%) contrast(102%)
        }
        &.btn-outline-drop img {
          filter: invert(66%) sepia(86%) saturate(18%) saturate(2259%) hue-rotate(316deg) brightness(100%) contrast(102%)
        }

        /* Group Cadey Grey Color*/
        &.btn-outline-view img {
          filter: invert(92%) sepia(4%) saturate(1414%) hue-rotate(174deg) brightness(80%) contrast(82%);
        }
        &.btn-outline-copy img {
          filter: invert(92%) sepia(4%) saturate(1414%) hue-rotate(174deg) brightness(80%) contrast(82%);
        }

        /* Group Atomic Tangerine Color*/
        &.btn-outline-extract img {
          filter: brightness(0) saturate(100%) invert(85%) sepia(28%) saturate(6897%) hue-rotate(324deg) brightness(103%) contrast(101%);
        }
        &.btn-outline-limit img {
          filter: brightness(0) saturate(100%) invert(85%) sepia(28%) saturate(6897%) hue-rotate(324deg) brightness(103%) contrast(101%);
        }
        &.btn-outline-corelate img {
          filter: brightness(0) saturate(100%) invert(85%) sepia(28%) saturate(6897%) hue-rotate(324deg) brightness(103%) contrast(101%);
        }
        &.btn-outline-correlateref img {
          filter: brightness(0) saturate(100%) invert(85%) sepia(28%) saturate(6897%) hue-rotate(324deg) brightness(103%) contrast(101%);
        }

        /* Group Light Blue Color*/
        &.btn-outline-aggr img {
          filter: invert(52%) sepia(8%) saturate(1238%) hue-rotate(260deg) brightness(91%) contrast(87%);
        }
        &.btn-outline-sort img {
          filter: invert(52%) sepia(8%) saturate(1238%) hue-rotate(260deg) brightness(91%) contrast(87%);
        }
        &.btn-outline-aggregate img {
          filter: invert(52%) sepia(8%) saturate(1238%) hue-rotate(260deg) brightness(91%) contrast(87%);
        }
        &.btn-outline-explode img {
          filter: invert(52%) sepia(8%) saturate(1238%) hue-rotate(260deg) brightness(91%) contrast(87%);
        }
        &.btn-outline-implode img {
          filter: invert(52%) sepia(8%) saturate(1238%) hue-rotate(260deg) brightness(91%) contrast(87%);
        }
        &.btn-outline-merge img {
          filter: invert(52%) sepia(8%) saturate(1238%) hue-rotate(260deg) brightness(91%) contrast(87%);
        }
        &.btn-outline-store img {
          filter: invert(52%) sepia(8%) saturate(1238%) hue-rotate(260deg) brightness(91%) contrast(87%);
        }
    }

    //leftbar button icon
    .sidebar {
      .btn-group img {
        filter: brightness(0) saturate(100%) invert(29%) sepia(47%) saturate(1116%) hue-rotate(144deg) brightness(90%) contrast(92%);
      }
    }

    &.theme-dark {
      .sidebar {
        .btn-group img {
          filter: brightness(0) saturate(100%) invert(60%) sepia(66%) saturate(4697%) hue-rotate(1deg) brightness(105%) contrast(103%);        
        }
      }

      svg.leader-line {
        g,
        .leader-line-plugs-face {
          filter: invert(0.7);
        }
  
        text {
          filter: invert(1);
        }
      }
    }
}