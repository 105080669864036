﻿.media {
    @extend .m-b-20;
    .media-body {
        color: $grey-700;
        font-size: $font-size + 2px;

        .media-heading {
            @extend .margin-0;
            font-size: $font-size + 4px;
            font-weight: $font-weight-700;
            color: $grey-900;            
        }
    }
    .media-left{
        @extend .m-r-20;
    }
    p:last-child{
        @extend .m-b-0;
    }
    &.mleft{
        .media-left{
            @extend .m-r-20;
        }
    }
    &.mright{
        .media-body{
            text-align: right;
        }
        .media-right{
            @extend .m-l-20;
        }
    }    
    &.media-middle{
        align-items: center;
    }
    &.media-bottom{
        align-items: flex-end;
    }
}