/**** LEFTBAR ******/

.theme-light-pqs {
    .sidebar {
      top: 49px;
      left: 10px;
      background: #ffffff;
      border-right: 1px solid #d4d4d6;
      border-left: 1px solid #d4d4d6;
      width: 230px;
      z-index: 21;
      border-radius: 30px;
  
      .menu {
        .list {
          // height: calc(100% - 95px) !important;
          // margin: 0px;
          // a {
          //     font-size: 10px;
          // }
          .panel-group {
            .panel {
              .panel-heading {
                .side-title {
                  font-size: 10px;
                  line-height: 1.1em;
                }
                a > span {
                  font-size: 8px !important;
                }
              }
            }
            .panel-primary {
              .panel-title {
                font-size: 10px;
              }
            }
          }
          // .panel-content {
          //     color: #8e8e8e;
          // }
          .dp-sidebar {
            max-height: calc(90% - 65px) !important;
          }
          .dv-sidebar {
            max-height: calc(98% - 65px) !important;
          }
          button.btn-delete {
            background-color: transparent;
            background-repeat: no-repeat;
            border: none;
            font-size: 17px;
  
            &.active {
              color: #8c8c8c;
            }
          }
  
          a {
            i {
              transform: unset;
              color: #8c8c8c;
            }
  
            &:hover {
              i {
                transform: unset;
                color: #5cc5cd;
              }
            }
          }
        }
        .slimScrollDiv {
          height: auto !important;
        }
      }
    }

    .sidebar-style-2 {
      top: 53px;
      width: 210px;
      z-index: 11;
      border-radius: 10px;
      margin-top: 105px;
      margin-left: 5px;
      height: calc(100vh - 0px);
      position: fixed;
      background: #f7f7f7;
  
      .sidebar-list-menu {
        background: #e1e4e6;
        padding: 15px;
        border-bottom: 1px solid #cacaca;
      }
    }
  
    .navbar-right {
      transition: all 0.5s;
      font-family: 'Gilroy', sans-serif;
      width: 200px;
      height: calc(100vh - 0px);
      position: fixed;
      top: 49px;
      right: 10px;
      // left: auto;
      box-shadow: none;
      z-index: 21;
      display: inline-block;
      background: #ffffff;
      border-left: 1px solid #d4d4d6;
      border-right: 1px solid #d4d4d6;
      border-radius: 30px;
      // overflow: auto;
  
      .menu {
        .list {
          height: calc(100% - 80px) !important;
        }
      }
  
      .button-container {
        z-index: 9;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        padding: 10px;
        background: #f7f7f7;
        border-bottom: 0.01em solid #d4d4d6;
        text-align: center;
  
        .mat-raised-button {
          box-shadow: none;
        }
      }

      .panel-body {
        .btn-outline {
          img {
            filter: invert(1);
          }
        }
      }
    }
  
    .dq-sc {
      max-height: calc(100vh - 15.25rem);
      overflow-x: hidden;
      overflow-y: scroll;
      min-height: 300px;
    }
  
    .dp-h93v {
      height: calc(100vh - 120px);
      overflow-y: auto;
      border-top-left-radius: 30px;  
      border-top-right-radius: 30px; 
    }
  
    .dv-sc {
      max-height: calc(100vh - 15.5rem);
      overflow-x: hidden;
      overflow-y: scroll;
      min-height: 300px;
  
      &.dvc-sc {
        max-height: calc(100vh - 12.9rem);
      }
    }

    &.theme-dark {
      .sidebar {
        background: #ffffff !important;
        border-right: 1px solid #d4d4d6;
        border-left: 1px solid #d4d4d6;
  
        .slimScrollDiv {
          height: auto !important;
        }

        .menu {
          .list {
            .panel-primary {
              .panel-title {
                p, span {
                  // color: #394142 !important;
                }
              }
            }
          }
        }
      }
  
      .sidebar-style-2 {
        background: #ffffff;
  
        .sidebar-list-menu {
          background: #ffffff;
          border-bottom: 1px solid #d4d4d6;
        }
      }
  
      .navbar-right {
        background: #ffffff;
        border-left: 1px solid #d4d4d6;
  
        .button-container {
          background: #ffffff;
          border-bottom: 0.01em solid #d4d4d6;
        }
      }
    }
}

@media only screen and (max-width: 767px) {
  .sidebar {
    left: inherit;
  }
}