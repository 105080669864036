/**** RIGHTBAR ******/

.theme-light-pqs {
    .navbar-right {
        transition: all 0.5s;
        font-family: 'Gilroy', sans-serif;
        width: 200px;
        height: calc(100vh - 0px);
        position: fixed;
        top: 49px;
        right: 10px;
        // left: auto;
        box-shadow: none;
        z-index: 12;
        display: inline-block;
        background: #ffffff;
        border-left: 1px solid #d4d4d6;
        border-right: 1px solid #d4d4d6;
        border-radius: 30px;
        // overflow: auto;

        .menu {
        .list {
            height: calc(100% - 80px) !important;
        }
        }

        .button-container {
            z-index: 9;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            padding: 10px;
            background: #f7f7f7;
            border-bottom: 0.01em solid #d4d4d6;
            text-align: center;
            border-top-right-radius: 21px;
            border-top-left-radius: 26px;

            .mat-raised-button {
                box-shadow: none;
            }
        }
    }
}