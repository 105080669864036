// Import Variable
@import '../../scss/ui-kit/variables';
@import '../../scss/inc/mixins';
@import '../../scss/skins/dark';

/******** THEME PDS *********/
.theme-pds{
    .nav-tabs{
        .nav-link.active {
            color: #ffffff;
            border-radius: 50px !important;
            background: rgb(10,99,111);
            background: -moz-linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
            background: linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a636f",endColorstr="#13bed5",GradientType=1);
        }
    }

    &.theme-dark {
        background-color: #F6F6F6 !important;

        h2, h3 {
          color: #394142;
        }

        .nav-tabs{
            .nav-link.active {
                background: #FD7B03;
                color: #ffffff;
            }
        }
    }
}

/******** GENERAL ***********/
/* Sign-In
------- */

.theme-light-pqs {
  .signin-title {
    font-family: 'Gilroy', sans-serif;
    background: url(../../images/background-gradient.svg) no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 500px;
    width: 950px;

    .welcome {
      font-size: 26px;
      color: #0e8293;
      font-weight: 100;
      margin-bottom: 0px;
    }

    .pqs {
      width: 410px;
      font-size: 18px;
      color: #534D59;
      font-weight: bolder;
      margin-bottom: 0px;
      padding-bottom: 20px;
      // border-bottom: 1px solid #353c40;
    }

    .desc {
      width: 340px;
      font-size: 40px;
      background: linear-gradient(90deg, #1E92A3, #0A636F);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bolder;
      line-height: normal;
    }

    .welcome-container {
      display: flex;
      justify-content: left;
      align-items: center;
      height: 80vh;
      padding-top: 0;
    }
  }

  .authentication {
    .auth_form {
      transition: all 0.5s;
      border-radius: 10px;
      // background: #f5f5f5;
      // border: 5px solid #ffffff;
      // box-shadow: 0 0 16px rgb(41 42 51 / 6%), 0 6px 20px rgb(41 42 51 / 2%);

      a {
        background: linear-gradient(90deg, #1E92A3, #0A636F);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-decoration: none;
      }
    }

    .signin-form {
      // background: rgba(250, 250, 250, 0.85);

      .header,
      .body {
        display: block;
        background-color: transparent !important;
      }

      input {
        font-size: 12px;
      }
    }

    .signin-form-app {
      // background: rgba(250, 250, 250, 0.85);

      .header {
        background: transparent !important;
        text-align: center;

        .title {
          font-size: 24px;
          color: white;
          margin-bottom: 0px;
        }

        .acc {
          font-size: 14px;
          color: white;
          font-weight: 100;
        }
      }

      .body {
        background: transparent !important;

        .icon-form {
          position: absolute;
          line-height: 1.375rem;
          left: 0.3rem;
          bottom: 0.5rem;
          color: #2E3536;
        }
      }
    }

    .title {
      font-size: 36px;
      color: #0e8293;
      margin-bottom: 0px;
    }

    .acc {
      font-size: 16px;
      color: #0e8293;
      font-weight: 100;
    }
  }

  .layer {
    // background-color: rgba(50, 50, 50, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .signin-bgimg {
    background: url(../../images/pq-signin-image.svg) no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
  }

  &.theme-dark {
    .signin-title {
      .welcome {
        color: $t_dark1;
      }

      .desc {
        background: linear-gradient(187deg, rgb(8, 79, 129) 0%, rgba(8, 79, 129, 0.6) 42.4%, rgba(127, 103, 67, 0.6) 52.4%, rgb(255, 128, 0) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bolder;
        line-height: normal;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bolder;
        line-height: normal;
      }
    }

    .authentication {
      .signin-form {
        background: transparent;
        border: none;

        .body {
          background: transparent;
        }
      }

      .signin-form-app {
        background: transparent;
        border: none;
      }

      .title,
      .acc {
        color: $t_dark_light;
      }

      .auth_form {
        a {
          background: linear-gradient(90deg, #FD7B03, #FD7B03);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

/* Home
---- */

.theme-light-pqs {
  .home-title {
    font-family: 'Gilroy', sans-serif;

    .welcome {
      font-size: 17px;
      color: #0e8293;
      font-weight: 100;
      margin-bottom: 0px;
    }

    .pqs {
      font-size: 47px;
      color: #ff7f00;
      font-weight: 900;
      margin-bottom: 0px;
    }

    .desc {
      font-size: 24px;
      color: #999999;
      font-weight: 400;
    }
  }

  .card.card-home {
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    background: rgba(250, 250, 250, 0.85);
    border: 1px solid #d4d4d6;
    // box-shadow: 0 0 16px rgba(41, 42, 51, 0.06), 0 6px 20px rgba(41, 42, 51, 0.02);

    .header {
      padding-left: 20px;
      padding-right: 20px;
      background: transparent !important;
      display: block;

      h5 {
        font-size: 16px;
        color: #999999;
        text-transform: uppercase;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 26px;
        font-weight: 400;
        margin-bottom: 0px;
        background: linear-gradient(90deg, #1E92A3, #0A636F);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .body {
      background: transparent !important;
      padding-top: 0px;

      p {
        color: #2E3536;
      }
    }

    .footer {
      padding-left: 20px;
      padding-right: 20px;

      span,
      ul {
        color: #676767;
      }

      ul {
        list-style: disc;

        li {
          display: list-item;
          margin-left: 13px;
        }
      }
    }
  }

  .home-bgimg {
    // background: url(../../images/pq-home-image.svg) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 0 100%;
  }

  &.theme-dark {
    .home-title {
      .welcome {
        color: #c7c2c2;
      }

      .pqs {
        color: #ffffff;
      }

      .desc {
        color: #f3c404;
      }
    }

    .card.card-home {
      background: #ffffff;
      border: none;
      .header {
        h5 {
          color: #9fabad;
        }

        h3 {
          background: linear-gradient(187deg, #FD7B03 0%, #FD7B03 42.4%, #FD7B03 52.4%, #FD7B03 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .body {
        p {
          color: #2E3536;
        }
      }

      .footer {
        span,
        ul {
          color: #9fabad;
        }
      }
    }
  }
}

.theme-light-pqs {
    background-color: #F6F6F6 !important;
  
    .item-center {
      align-items: center !important;
    }
  
    .t-0 {
      top: 0 !important;
    }
  
    .mb0 {
      margin-bottom: 0;
    }
  
    .pql-mr3 {
      margin-right: 3em;
    }
  
    .h-25 {
      height: 25px;
    }
  
    .w0 {
      width: 0;
    }
  
    .w-30 {
      width: 30px;
    }
  
    .w-65 {
      width: 65%;
    }
  
    .d-inline-box {
      display: -webkit-inline-box !important;
      color: #534D59;
    }
  
    .loader {
      display: inline-block;
    }
  
    img {
      &.logo-light {
        display: inline;
      }
      &.logo-dark {
        display: none;
      }
    }
  
    // svg {
    //     path, rect {
    //         fill: #5CC5CD;
    //     }
    // }
  
    .loader {
      svg {
        path {
          fill: #5cc5cd;
        }
      }
    }
  
    .borders {
      border: 2px solid #5cc5cd !important;
    }
  
    .roundeds {
      border-radius: 0.35rem !important;
    }
  
    @media (min-width: 1200px) {
      .container {
        max-width: 1140px;
      }
    }
  
    .w-fill {
      width: -webkit-fill-available !important;
    }
  
    .border-collapse {
      border-collapse: collapse !important;
    }
  
    .fill-tosca {
      svg {
        rect {
          fill: #5cc5cd;
        }
      }
    }
  
    .op-7 {
      opacity: 0.7;
    }
  
    ol {
      padding: inherit;
      line-height: normal;
    }
  
    .cursor-auto {
      cursor: auto;
    }
  
    select {
      cursor: pointer;
    }
  
    .log-service-icon {
      display: inline-block;
      width: 12px;
      height: 12px;
      background-image: url('../../images/log-service.svg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
  
      &.top-menu {
        width: 16px;
        height: 16px;
        background-image: url('../../images/log-service-b.svg');
      }
    }
  
    &.theme-dark {
    //   background: linear-gradient(180deg, #09090a 0%, #1f2325 31.77%, #3a4246 100%);
    //   background-attachment: fixed;
    //   background-color: #181a1b !important;
    background-color: #F6F6F6;
  
      img {
        &.logo-light {
          display: none;
        }
        &.logo-dark {
          display: inline;
        }
      }
  
      .log-service-icon {
        display: inline-block;
        width: 12px;
        height: 12px;
        background-image: url('../../images/log-service.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
  
        &.top-menu {
          width: 16px;
          height: 16px;
          background-image: url('../../images/log-service-b.svg');
        }
      }
    }
  }


/******** BUTTON ***********/
.theme-light-pqs {
    .btn {
        line-height: 1.3em;
        color: #ffffff;
        border-radius: 50px !important;
        background: linear-gradient(90deg, #1E92A3, #0A636F);
    
        i {
        vertical-align: text-bottom;
        }
    
        &.disabled {
        pointer-events: initial;
        }
    }
    
    .btn-outline {
        border: 0.5px #DBDADE solid;
        // line-height: 20px;
        color: #212529;
        background: #ffffff;
        // height: 1.5rem !important;
        // height: 24px!important;
        // height: 20px !important;
        min-height: 26px !important;
        min-width: 1.5rem !important;
        border-radius: 0.2875rem;
        font-size: 10px;
        border-radius: 184px;

        &:hover {
         color: #ffffff !important;
          background-color: #92C5CE !important;
        }

        &.disabled:hover,
        &.disabled:hover > i {
          color: #212529 !important;
          background: #d4d4d6 !important;
        }

        &.btn-outline-mini {
          line-height: 27px;
          // width: 1.5rem;
          width: 24px;
          height: 20px;
          border-radius: 184px;

          span,
          i {
              line-height: 25px;
          }

          &:focus i {
              color: #212529;
          }
        }

        &.btn-outline-maxi {
        // height: 2.75rem !important;
        // width: 2.75rem;
        height: 40px !important;
        width: 40px;

        img {
            filter: brightness(0.65);
            width: 28px;
        }

        &:focus,
        &:active,
        &:hover {
            border: none;
            color: #ffffff;
            background: #117a8b;
            transition: 0.3s all;

            img {
            filter: none;
            }
        }

        &.active {
            img {
            filter: none;
            }
        }
        }

        &.btn-qcommand {
        height: 30px !important;
        width: 30px;
        padding: 7px;
        background: #ffffff;
        border: 1px solid #E2E2E2;

        // img {
        //     filter: invert(1);
        // }

        &:hover {
            img {
            filter: none;
            transition: 0.3s all;
            }
        }
        }

        &.btn-lg {
        height: 2.5rem !important;
        font-size: 12px;
        line-height: 9px;
        }

        &.bg-transparent:hover,
        &.bg-transparent:not(:disabled):not(.disabled):active {
        color: #5cc5cd !important;
        background-color: transparent !important;
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
        color: #fff;
        background-color: #117a8b !important;
        border-color: #117a8b;
        }

        &.btn-toggle-table {
          &:not(:disabled):not(.disabled):active,
          &:not(:disabled):not(.disabled).active {
              color: #fff;
              background-color: #8c8c8c !important;
              border-color: #8c8c8c;
          }
          &:hover {
              background-color: #8c8c8c !important;
          }
        }

        // img {
        //   filter: invert(1);
        // }

        span img {
          filter: invert(1);
        }
    }

    .btn:hover {
        // color: #5CC5CD;
        box-shadow: none;
    }
    
    .btn-green {
        color: #ffffff;
        background-color: #84cfea !important;
    }
    
    .btn-danger {
        background-color: #ee2558;
    }
    
    .btn-light {
        background-color: #f4f4f4;
    }
    
    .btn-icon {
        line-height: 0.8rem;
    }
    
    .btn-dark {
        background-color: #f4f4f4;
    
        &:hover,
        &:focus,
        &:active {
        background-color: #299da4 !important;
        border-color: transparent !important;
        box-shadow: none !important;
        }
    
        &.active {
        background-color: #299da4 !important;
        }
    }

    .form-horizontal {
      .btn-group {
        .btn-color-palette {
          background: #eceff1 !important;
          border: 1px solid #e2e5ec !important;
        }
      }
    }

    .btn-color-palette,
    .btn-color-palette:active {
        white-space: break-spaces;
        text-align: left;
        margin: 0px;
        background-color: #eceff1 !important;
        border: 1px solid #e2e5ec !important; 
        padding: 8px 20px 5px 10px;
    
        &:after {
        position: absolute;
        right: 10px;
        top: 38%;
        }
    
        span {
        margin-bottom: 3px;
        }
    
        + .dropdown-menu {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 5px;
        min-width: 0px;
    
        .dropdown-item {
            flex-flow: wrap;
            padding: 7px 13px 5px;
            margin-bottom: 0px;
    
            span {
            margin-bottom: 3px;
            }
        }
        }
    }
    
    .signin-other-account {
        background: transparent;
        border-style: solid;
        border-color: #ff9f0e;
        font-size: 12px;
        color: #ff9f0e !important;
        border-radius: 0px;
        width: 100%;
    }
    
    .signin-btn {
        // background: linear-gradient(180deg, #ff9f0e 0%, #f77b00 100%) !important;
        color: #ffffff;
        background: rgb(10,99,111);
        background: -moz-linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
        background: linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a636f",endColorstr="#13bed5",GradientType=1);
        font-size: 12px;
        border-radius: 50px;
        width: 100%;
    }
    
    .signin-btn-app {
        background: #f40155 !important;
        color: white;
        font-size: 12px;
        border-radius: 0px;
        width: 100%;
    }
    
    .dtp-btn-now {
        background: #eeeeee !important;
    }
    
    .dtp-btn-clear {
        background: #eeeeee !important;
    }
    
    .dtp-btn-cancel {
        background: #eeeeee !important;
    }
    
    .dtp-btn-ok {
        background: #eeeeee !important;
    }
    
    .btn-result {
        position: absolute;
        top: 7px;
        margin: 0px;
        z-index: 1;
        min-height: 20px !important;
    
        &.close-result {
        right: 15px;
        }
    
        &.resize-result {
        right: 70px;
        }
    }
    
    .link {
        color: #222222 !important;
        font-weight: 500;
    }
    
    &.theme-dark {
        .btn {
          color: #FFFFFF;
          background: #FD7B03;
        }
    
        .btn-outline {
            color: #2E3536 !important;
            background-color: #ffffff;
    
            &:hover {
                color: #ffffff !important;
                background-color: #FD7B03 !important;
            }
    
            &.disabled:hover,
            &.disabled:hover > i {
                color: #d8d8d8 !important;
                background-color: #383f43 !important;
            }
    
            &.btn-outline-mini {
                &:focus i {
                color: #d8d8d8;
                }
            }
    
            // &.btn-qcommand {
            //     img {
            //       filter: invert(1);
            //     }
            // }
    
            &.btn-outline-maxi {
                img {
                filter: none;
                }
            }
    
            &.bg-transparent:hover {
                color: #5cc5cd !important;
                background-color: transparent !important;
            }
    
            &.btn-toggle-table {
                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled).active {
                color: #fff;
                background-color: #272e2f !important;
                border-color: #272e2f;
                }
                &:hover {
                background-color: #272e2f !important;
                }
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                color: #ffffff !important;
                background-color: #FD7B03 !important;
                border-color: #FD7B03;
            }

            // img {
            //   filter: invert(1);
            // }

            span img {
              filter: invert(1);
            }
        }
    
        .btn-green {
            color: #ffffff;
            background-color: #84cfea;
        }
    
        .btn-dark {
            background-color: #626567;
        }
    
        .btn-color-palette,
        .btn-color-palette:active {
            background-color: #ECEFF1 !important;
            border: 1px solid #e2e5ec !important; 
        }
    
        .btn-danger {
            background-color: #ee2558;
        }
    
        .dtp-btn-now {
            background: #383f43 !important;
        }
    
        .dtp-btn-clear {
            background: #383f43 !important;
        }
    
        .dtp-btn-cancel {
            background: #383f43 !important;
        }
    
        .dtp-btn-ok {
            background: #383f43 !important;
        }
    
        .link {
            color: #ffffff !important;
        }

        .signin-btn {
          background: var(--orange-trueai, #FD7B03);
          color: #ffffff;
        }
    }
}

/******** CARD ***********/
.theme-light-pqs {
    .card {
      .header {
        // padding: 15px;
        padding: 10px 15px;
        background-color: #ECEFF1;
        border-radius: 0.35rem 0.35rem 0px 0px;
        display: flex;
        justify-content: space-between;
        // margin-bottom: -.35rem;
        z-index: 1;
  
        .header-dropdown {
          // top: 8px;
          // right: 10px;
          top: 0px !important;
          right: 0px !important;
          position: absolute;
          align-items: center;
          opacity: 0;
          transition: all 0.5s;
          // right: 0px !important;
          // top: -25px !important;
          border-radius: 0.35rem;
          padding: 0px 5px !important;
          background-color: #eceff1;
  
          &.h-title-bg {
            background-color: #f7f7f7;
          }
  
          li {
            a {
              padding: 5px;
            }
          }
          i {
            color: #808080;
          }
          i:hover {
            cursor: pointer;
            color: #5cc5cd;
          }
  
          li {
            .dropdown-menu {
              li {
                a {
                  color: #8c8c8c;
                }
                a:hover,
                a.active {
                  color: #5cc5cd;
                }
              }
            }
          }
        }
        h2 {
          max-width: 100%;
          text-transform: none;
          display: inline-block;
          padding-right: 10px !important;
          align-items: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .list-right-body {
          width: 100%;
          position: absolute;
          bottom: -4.5em;
          // left: 89%;
          align-items: center;
        }
        &.header-transparent {
          background-color: transparent !important;
          padding: 0px !important;
        }
      }
  
      .body {
        background: #ffffff;
        border-radius: 0px 0px 0.35rem 0.35rem;
        // border-radius: .35rem;
  
        // Show Hide Selected Rightbar
        .has-search .form-control-feedback {
          position: absolute;
          z-index: 2;
          display: block;
          line-height: 1.375rem;
          text-align: center;
          pointer-events: none;
          color: #2E3536;
          right: 0rem;
        }
  
        &.border-t {
          content: '';
          left: 0;
          top: 50px;
          width: 100%;
          border-top: 5px solid #5cc5cd;
          margin-top: 0.35rem;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
        &.border-r {
          content: '';
          right: 0;
          bottom: 0;
          height: 100%;
          border-right: 5px solid #5cc5cd;
          border-bottom-right-radius: 0px;
        }
        &.border-b {
          content: '';
          left: 0;
          bottom: 0;
          width: 100%;
          border-bottom: 5px solid #5cc5cd;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        &.border-l {
          content: '';
          left: 0;
          bottom: 0;
          height: 100%;
          border-left: 5px solid #5cc5cd;
          border-bottom-left-radius: 0px;
        }
  
        &.body-transparent {
          background: transparent !important;
        }
  
        .containerList {
          transition: all 0.5s;
        }
      }
  
      .footer ul li {
        display: inherit;
      }
  
      &.info-box-2 {
        background-color: transparent;
        border-radius: 10px;
        .body {
          &.bg-pqs-dark {
            i {
              color: #3a3a3a;
            }
  
            .checkbox {
              position: absolute;
              left: 10px;
              top: 10px;
            }
  
            .content {
              .text {
                font-size: 14px;
                color: #3a3a3a;
              }
            }
          }
  
          .content {
            padding-bottom: 0px;
            .text {
              font-size: 16px;
              color: #534D59;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            span {
              font-size: 12px;
              color: #534D59;
              font-weight: 400;
            }
            h5 {
              font-size: 11px;
              color: #534D59;;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
              max-width: 80%;
            }
            .w210 {
              width: 167px;
            }
            label {
              margin-bottom: 0px;
            }
          }
        }
  
        &.s-results {
          margin-bottom: 25px !important;
  
          .body {
            .icon {
              overflow: unset;
              height: 300px;
              display: grid;
              padding: 0px;
              .body {
                padding: 0px;
              }
            }
            .content {
              .text {
                color: #212529;
              }
            }
          }
  
          &:hover {
            box-shadow: none;
          }
        }
      }
  
      &.info-box-2:hover {
        cursor: pointer;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        box-shadow: 0 0 3px rgba(41, 42, 51, 0.1), 0 6px 20px rgba(14, 16, 32, 0.32);
      }
  
      &.card-pqs {
        .body {
          background: #e1e4e6;
          border-radius: 0.35rem;
        }
      }
  
      .panel-pqs {
        .body {
          background: #e1e4e6;
        }
      }
  
      &.box-menu {
        display: flex;
        cursor: pointer;
        background-color: transparent;
        position: relative;
        overflow: hidden;
        text-align: center;
        align-items: center;
        transition: all 0.5s;
  
        .icon {
          overflow: hidden;
          display: inline-block;
          height: 136px;
          width: 136px;
          border-radius: 100px;
          background-color: #fff;
  
          img {
            height: inherit;
            width: inherit;
            padding: 30px;
            // margin: 10px;
          }
        }
  
        .content {
          .text {
            color: #808080;
            font-size: 16px;
            font-weight: 500;
            padding: 0px 10px 10px;
            margin-top: 10px;
            width: min-content;
          }
        }
      }
  
      &:hover {
        .header {
          .header-dropdown {
            opacity: 1;
            transition: all 0.5s;
            right: 0px !important;
            top: -25px !important;
          }
        }
      }
      &.card-list {
        .header {
          border: 1px solid #ced4da;
        }
  
        .body {
          background: #e0e4e6;
          border: 1px solid #ced4da;
          border-top: none !important;
        }
      }
    }
  
    .selected-card {
      border-top: 2px solid #5cc5cd;
      border-left: 2px solid #5cc5cd;
      border-right: 2px solid #5cc5cd;
      border-bottom: 6px solid #5cc5cd;
    }
  
    .chart-display {
      .card {
        height: 300px !important;
  
        .body {
          height: 100%;
  
          &.w-header {
            height: calc(100% - 38px);
          }
  
          .demo-chart {
            height: 100%;
          }
  
          .c-pie {
            height: 145%;
            margin-top: -55px;
          }
        }
      }
    }
  
    .custom-card {
      .clickable {
        z-index: 1;
        box-shadow: none;
      }
    }
  
    .small_mcard_1 {
      background: #f7f7f7;
  
      h6 {
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        color: #3a3a3a;
      }
  
      .user img {
        width: 76px;
        height: 76px;
      }
  
      .checkbox {
        position: absolute;
        right: 10px;
        top: 50px;
      }
    }
  
    &.theme-dark {
      .card {
        .header {
          background-color: #ECEFF1;
  
          .header-dropdown {
            background-color: #ECEFF1;
  
            &.h-title-bg {
              background-color: #ECEFF1;
            }
          }
  
          h2 {
            color: #2E3536;
          }
        }
        .body {
          background: #ffffff;
          // border: 2px solid #272e2f;
  
          ul.breadcrumb {
            background-color: #ECEFF1 !important;
          }
        }
  
        &.card-pqs,
        .panel-pqs {
          .body {
            background: #ffffff;
          }
        }
  
        &.box-menu {
          .icon {
            background-color: #2e3435;
          }
        }
  
        &.info-box-2 {
          &.s-results {
            .body {
              .content {
                .text {
                  color: #ffffff;
                }
              }
            }
          }
          .body {
            background: #ffffff;
            &.bg-pqs-dark {
              i {
                color: #ffffff;
              }
  
              .content {
                .text {
                  color: #c7c2c2;
                }
              }
            }
          }
        }
        &.card-list {
          .header {
            background-color: #ECEFF1 !important;
            border: 1px solid #ced4da;
          }
  
          .body {
            background-color: #E0E4E6 !important;
          }
        }
      }
  
      .bgRight {
        background-color: #272e2f;
      }
  
      .small_mcard_1 {
        background: #2e3435;
  
        h6 {
          color: #c7c2c2;
        }
      }
    }
  }

/**** SELECT BUTTON ******/
.theme-light-pqs {
    .btn-group {
      > .btn {
        display: flex;
        justify-content: center;
        border: 0.5px #DBDADE solid;
  
        &:first-child {
            border-radius: 184px 0px 0px 184px; 
            border: 0.5px #DBDADE solid;
        }
  
        &:last-child {
            border-radius: 0px 184px 184px 0px;
            border: 0.5px #DBDADE solid;
        }
      }
  
      &.switch-mode {
        background: #ffffff;
        padding: 4px;
        border-radius: 50px;
  
        > .btn {
          border-radius: 50px;
          min-height: auto !important;
          display: flex;
        }
      }
    }
  
    &.theme-dark {
      .switch-mode {
        background: #ffffff;
      }
    }
  }


/**** INPUT *******/
.theme-light-pqs {
    /* For WebKit browsers (Chrome, Safari, Opera) */
    .form-control:-webkit-input-placeholder {
      color: #BBBBBB;
    }

    /* For Mozilla Firefox */
    .form-control:-moz-placeholder {
      color: #BBBBBB !important;
    }

    /* For Microsoft Edge and Internet Explorer */
    .form-control:-ms-input-placeholder {
      color: #BBBBBB !important;
    }

    /* For older Microsoft Edge */
    .form-control:-ms-input-placeholder {
      color: #BBBBBB !important;
    }

    /* Standard syntax */
    .form-control::placeholder {
      color: #BBBBBB !important;
    }

    .form-group {
      // margin-bottom: 0.8rem;
      margin-bottom: 12px;
    }
  
    .form-control,
    .form-control:focus {
      box-shadow: none;
      border: 1px solid #ced4da;
      border-radius: 10px;
      background-color: #ffffff;
      outline: none;
      padding: 0.4rem 0.6rem;
      font-size: 10px;
      min-height: 27px;
      -webkit-appearance: menulist !important;
      -moz-appearance: menulist !important;
      -ms-appearance: menulist !important;
      -o-appearance: menulist !important;
      appearance: menulist !important;
      // cursor: pointer;
    }
  
    .form-bottomline,
    .form-bottomline:focus {
      border-bottom: 0.01em solid #999 !important;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      background-color: transparent !important;
      border-radius: 0px;
  
      // &.group-bottomline {
      //     border-bottom-left-radius: 0px;
      //     border-bottom-right-radius: 0.25rem;
      // }
  
      &[disabled],
      &[readonly] {
        + .group-bottomline {
          background-color: #c6c6c8 !important;
          opacity: 0.5;
          margin-left: 0px;
        }
      }
    }
  
    .input-group {
      i {
        color: #74788d !important;
      }
    }
    .form-control:disabled,
    .form-control[readonly] {
      background-color: #ffffff !important;
      // opacity: 0.5;
      color: #181a1b !important;
    }
  
    ::-webkit-calendar-picker-indicator {
      filter: invert(0.55);
      height: 15px;
      width: 15px;
    }
  
    &.theme-dark {
      .form-control,
      .input-group .input-group-append .btn-outline-secondary,
      .input-group .input-group-prepend .btn-outline-secondary,
      .input-group-text,
      .bootstrap-select > .dropdown-toggle {
        color: #2E3536;
      }
  
      .pad-23 {
        padding: 0 0 0 23px;
      }
  
      .form-control,
      .form-control:focus {
        // box-shadow: none;
        border: 1px solid #ced4da;
        color: #2E3536 !important;
        background-color: #ffffff;
      }
  
      .form-control:disabled,
      .form-control[readonly] {
        background-color: #FFFFFF !important;
        // opacity: 0.5;
        color: #2E3536 !important;
      }
      
      .bg-logservice {
        background-color: #272e2f !important;
      }
  
      .select2:focus {
        > option {
          border: 0px;
        }
        > option:checked {
          background-color: #8c8c8c !important;
        }
        > option:hover {
          background-color: #8c8c8c !important;
        }
      }
  
      .show-tick {
        > option:checked {
          background-color: #8c8c8c !important;
        }
        > option:hover {
          background-color: #000 !important;
        }
      }
    }
  }


/**** DROP DOWN *******/

.theme-light-pqs {
  .dropdown {
    z-index: unset;

    &.dropdown-result {
      z-index: 999;
      margin-top: 20px;
      border-top: 1px solid #808080;
    }

    .dropdown-menu {
      &.dropdown-menu-right {
        a {
          padding: 5px 15px;
        }
      }
    }
    // .metadata-dropdown {
    //     position: fixed !important;
    //     top: auto !important;
    //     left: auto !important;
    //     width: inherit !important;
    // }
  }
  .dropdown-menu {
    .body {
      min-width: 260px;
    }
    top: 30px;
  }
  .ShareURLDropdown {
    top: 36px;
    right: 48px;
  }
  .notifScroll {
    overflow: auto;
    max-height: calc(100vh - 150px);
  }
  .colorpalette-dropdown {
    position: absolute;
    will-change: transform;
    top: 2px;
    left: 12px;
    transform: translate3d(-12px, 26px, 0px);

    .colorpalette-dropdown-item {
      padding: 8px 8px 8px 8px;
    }
  }
  .dropdown-item-metadata {
    display: block;
    width: 100%;
    // margin: 5px 0 10px 0;
    // padding: 0.8em 0 0 1em;
    margin: 0;
    padding: 5px 0 0 10px;
    // padding: 1em 1em 0;
    clear: both;
    font-weight: 400;
    color: #8c8c8c;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    // &:last-child {
    //     margin-bottom: 12px;
    // }
  }

  &.theme-dark {
    .dropdown-menu {
      background: $t_dark_light;
      box-shadow: 0px 10px 50px 0px rgba(41, 42, 51, 0.2);
      // @include box-shadow(0px 2px 20px 0px rgba($white,0.1));
      .header,
      .footer {
        border-color: $t_dark_border;
        color: $t_dark1;
        a {
          border-color: $t_dark_border;
        }
      }
      li {
        &.body {
          label {
            color: #2E3536;
          }
        }
      }
      > li > a:hover {
        color: $t_dark_light;
      }
      ul.menu .menu-info {
        h4 {
          color: #394142;
        }

        .btn-group .btn-outline {
          background-color: transparent;
        }
      }
    }
  }
}

/******** PANEL ***********/
.theme-light-pqs {
    .panel-group {
        .panel {
            #dragWindow {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: calc(100% - 45px);
            }
        
            #dragWindow .draggerTop {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
            }
        
            .fullDisplayResult {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: calc(100% - 265px) !important;
            }

            .shade-handle {
                text-align: center;
                line-height: 8px;
                cursor: row-resize;
                width: 50px;
                height: 6px;
                border-radius: 30px 30px 0 0;
                background: rgb(10,99,111);
                background: -moz-linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
                background: -webkit-linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
                background: linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a636f",endColorstr="#13bed5",GradientType=1);
                margin: 0 auto;
                display: block;
                position: sticky;
                z-index: 91 !important;
        
                // &:after {
                //   content: '\f19c';
                //   font-family: 'Material-Design-Iconic-Font';
                //   line-height: 0px;
                // }
            }

            .panel-heading {
                z-index: 91 !important;
                // position: sticky;
        
                a {
                    // padding: 10px 15px;
                    padding: 10px;
                    text-align: inherit !important;
                    font-size: 10px;
                    margin-left: 0px;
                }
                a:hover {
                    color: #5cc5cd;
                }
            }
        }

        .panel-primary {
            .panel-title {
                background-color: #ffffff;
                color: #222;
                border-top: 0.01em solid #d4d4d6;
                font-size: 12px;
        
                a {
                  overflow-wrap: anywhere;
                  color: #212529;
        
                  span,
                  i {
                    margin-top: 3px;
                    display: inline !important;
                  }
        
                  > i.zmdi.zmdi-info {
                    vertical-align: middle;
                  }
                }
        
                .menu-toggle {
                  &:after {
                    @include transition(all 0.5s);
                    font-size: $font-size + 2px;
                    right: 11px;
                    font-family: 'Material-Design-Iconic-Font';
                    position: inherit;
                    float: right;
                  }
                  &:after {
                    content: '\f2fb';
                  }
        
                  &.toggled {
                    &:after {
                      content: '\f2f9';
                    }
                  }
                }
        
                .menu-toggle-close {
                  &:after,
                  &:before {
                    @include transform(scale(0));
                    @include transition(all 0.5s);
                    font-size: $font-size + 2px;
                    position: absolute;
                    right: 25px;
                    font-family: 'Material-Design-Iconic-Font';
                  }
                  &:before {
                    content: '\FF0B';
                    @include transform(scale(1));
                  }
                  &:after {
                    content: '\f136';
                    @include transform(scale(0));
                  }
                }

                .is-active {
                  color: #0A636F !important;
                }
            }

            .panel-body {
                background: #ffffff;
                font-size: 10px;
        
                .editable-text {
                    align-items: center;
                    display: flex;
                    height: 20px;
        
                    label {
                    font-size: 10px;
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    }
        
                    .relative-right {
                    position: relative;
                    left: 0;
                    }
        
                    .text-edit-input {
                    font-size: 10px;
                    display: none;
                    border: 0;
                    height: auto;
                    // margin: 0 .5em 0 0;
                    padding: 0;
                    width: 100%;
                    background: transparent;
                    border-radius: 0;
                    // box-shadow: 0 1px 0 gray;
                    color: #5cc5cd;
                    text-decoration: underline;
        
                    // &:focus,
                    // &:active {
                    //     box-shadow: 0 1px 0 #5CC5CD;
                    // }
        
                    &:target,
                    &:focus {
                        position: relative;
                        top: -0.125em;
        
                        display: inline-block;
                        width: 100%;
        
                        + label {
                        display: none;
                        }
        
                        ~ .start-editing {
                        display: none;
                        }
        
                        ~ .stop-editing {
                        display: inline-block;
                        }
                    }
                    }
        
                    svg {
                    fill: currentColor;
                    height: 1.25em;
                    width: 1.25em;
                    }
        
                    .stop-editing {
                    display: none;
                    }
                }
        
                .lh-12 {
                    line-height: 10px;
                }
        
                .mat-raised-button {
                    box-shadow: none;
                    span {
                      img {
                        filter: brightness(0.6);
                      }
                    }
                }

                .form-group {
                  .btn-outline {
                    img {
                      filter: invert(1);
                    }
                  }
                }
            }
        
            .auto-sc-right {
                overflow-y: scroll;
                max-height: 170px;
                width: 100%;
                overflow-x: hidden;
                padding: 10px 20px 20px 20px;
            }

            .header-rounded {
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
            }

            .header-rounded-green {
                border-radius: 30px 30px 0px 0px;
                border-top: 1px solid var(--green-gradient, #0A636F);
            }
        }
    }

    .panel-bottom {
        position: fixed;
        bottom: 39px;
        width: 200px;
    }

    &.theme-dark {
        .panel-group {
          .panel {
            .shade-handle {
               background: #FD7B03;
            }
          }
    
          .panel-primary {
            .panel-title {
              background-color: #ffffff; 
              color: #394142;
              a { 
                color: #222222;
                &:hover {
                  color: #FD7B03;
                } 
              }
              .is-active { color: #FD7B03 !important; }
            }
    
            .panel-body {
              background: #ffffff;

              .form-group {
                .btn-outline {
                  img {
                    filter: invert(1);
                  }
                }
              }

              .mat-raised-button {
                span {
                  img {
                    filter: brightness(0.6);
                  }
                }
              }
            }

            .header-rounded-green {
                border-radius: 30px 30px 0px 0px;
                border-top: 1px solid var(--gradient-1, #FF8105);
            }
          }
        }
    }
}

/******** TABLE ***********/
.theme-light-pqs {
    .mat-table {
        overflow: auto;
        // max-height: 400px;
        // max-height: calc(100vh - 215px);
        max-height: calc(100vh - 360px);
        display: inline-grid;
        width: 100%;
    
        &.table-result {
          max-height: calc(100vh - 250px);
          mat-header-row {
            border-bottom: 0.01px solid #dfdfdf;
          }
          mat-row:nth-child(even) mat-cell:first-child {
            background-color: #EAF0F7 !important;
            border-bottom: 0.01px solid #dfdfdf;
          }
          mat-row:nth-child(odd) mat-cell:first-child {
            background-color: #EAF0F7;
            border-bottom: 0.01px solid #dfdfdf;
          }
        }
    
        .mat-header-cell {
          font-size: 10px !important;
          width: 50px;
          min-width: 120px;
          padding: 5px 20px;
          border: 1px solid #dfdfdf;
          background: #EAF0F7 !important;
          overflow: inherit;
    
          ul {
            display: inherit !important;
          }
    
          .truncatetxt {
            width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
    
        .mat-header-cell:first-of-type {
          padding: 5px 20px;
        }
    
        .mat-header-row {
          z-index: 10 !important;
          min-height: 30px;
          border-bottom-width: 0;
        }
    
        .mat-cell {
          font-size: 10px;
          min-height: 20px;
          font-size: 10px !important;
          white-space: break-spaces;
          word-break: break-word;
          padding: 5px 20px;
          width: 50px;
          min-width: 120px;
          background: transparent;
          display: inline-flex;
          border: 1px solid #dfdfdf;
    
          a {
            color: #212529;
            // background: linear-gradient(#5CC5CD, #5CC5CD) bottom/0 0.1em no-repeat;
            // background-position: left bottom;
            transition: all 0.5s;
    
            &:hover {
              text-decoration: underline !important;
              color: #5cc5cd;
              // background-size: 100% .1em;
            }
          }
        }
    
        .mat-footer-row,
        .mat-header-row,
        .mat-row {
          border-bottom-width: 0;
        }
    
        .mat-row {
          align-items: inherit;
          min-height: 20px;
        }
    
        .mat-sort-header-arrow {
          height: 12px;
          width: 12px;
          min-width: 12px;
          position: absolute;
          right: 10px;
          display: flex;
          // transform: none !important;
          // opacity: 1 !important;
          color: #5cc5cd;
        }
    
        .mat-sort-header-container {
          position: unset;
        }
    
        .mat-footer-row {
          .mat-footer-cell {
            justify-content: flex-end;
            font-size: 10px;
            white-space: break-spaces !important;
            padding: 10px 20px;
          }
        }
    }

    mat-table {
        color: #212529;
        background-color: #ffffff;
    
        .mat-footer-row,
        .mat-header-row,
        .mat-header-cell,
        .mat-row {
          color: #212529 !important;

          .mat-cell {
            background: #f7f8fa !important; 
          }

        }
    
        .mat-row:nth-child(even) {
          background: #f7f8fa !important;
    
          .mat-cell {
            background-color: #ffffff !important;
          }
        }
    
        .number-cell {
          &.mat-cell,
          &.mat-header-cell {
            min-width: 0;
            max-width: fit-content;
            padding: 5px 10px !important;
          }
        }
    }

    &.theme-dark {
      mat-table {
        background-color: #ffffff;
        color: #212529 !important;

        .mat-footer-row,
        .mat-header-row,
        .mat-header-cell,
        .mat-row {
          color: #212529 !important;

          .mat-cell {
            background: #f7f8fa !important; 
          }
        }
  
        &.table-result {
          mat-header-row {
            border-bottom: 0.01px solid #E1E1E1;
          }
          mat-row:nth-child(even) mat-cell:first-child {
            background-color: #FFFFFF !important;
            border-bottom: 0.01px solid #E1E1E1;
          }
          mat-row:nth-child(odd) mat-cell:first-child {
            background-color: #FFFFFF !important;
            border-bottom: 0.01px solid #E1E1E1;
          }
        }

        .mat-row:nth-child(even) {
          background: #F7F8FA !important; 
    
          .mat-cell {
            background-color: #ffffff !important;
          }
        }
      }
  
      table {
        color: #2E3536;
        background-color: #ffffff;
  
        thead {
          th,
          td {
            background: #ffffff;
          }
        }
  
        th,
        td {
          border-color: #DBDADE;
        }
  
        &.table-striped {
          tbody {
            tr:nth-of-type(odd) {
              background-color: #ffffff;
            }
            tr:nth-of-type(even) {
              background-color: #ffffff;
            }
          }
        }
  
        &.table-hover {
          tbody {
            tr:hover {
              color: #2E3536;
              background-color: #ffffff;
            }
          }
        }
      }
  
      .fixed-table {
        background: #ffffff !important;
      }
  
      mat-footer-row,
      mat-header-row,
      mat-header-cell,
      mat-row {
        background: #ffffff !important;
        color: #394142 !important;
      }
  
      .mat-row:nth-child(even) {
        background-color: #f7f8fa !important;
        color: #394142 !important;
  
        .mat-cell {
          background-color: #ffffff !important;
          color: #394142 !important;
        }
      }
  
      .mat-header-cell {
        background: #EAF0F7 !important;
        border: 0.01px solid #E1E1E1;
      }
  
      .mat-cell {
        border: 1px solid #E1E1E1;
        color: #394142 !important;
  
        a {
          color: #394142;
        }
      }

      .mat-table {
        &.table-result {
          mat-row:nth-child(even) mat-cell:first-child {
            background-color: #EAF0F7 !important;
          }
          mat-row:nth-child(odd) mat-cell:first-child {
            background-color: #EAF0F7 !important;
          }
        }

        .mat-header-cell {
          background: #EAF0F7 !important;
          border: 0.01px solid #E1E1E1;
        }
      }
    }
}

/******** ACCORDION ***********/
.theme-light-pqs {
    .pql_accordion {
        position: fixed;
        width: calc(100% - 430px);
        bottom: 40px;
        margin-left: 230px;
        margin-right: 200px;
        transition: all 0.5s;
        z-index: 10;
        // right: 0;
        // left: 0;
        // border: 1px solid #d4d4d6;
        // border-bottom: none;
        // border-top: none;

        .pql_body {
            height: calc(100vh - 130px);
            // text-align: center;

            .body {
            border-radius: 0.35rem !important;
            }
        }
    }
    
    .pql_accordion,
        .footer_accordion {
        .h4 {
            border-top: 1px solid #e1e4e6;
        }
    }

    .footer_accordion {
        position: fixed;
        width: calc(100% - 430px);
        bottom: 0;
        z-index: 12;
        background: #f7f7f7;
        margin-left: 230px;
        margin-right: 200px;
        transition: all 0.5s;
        z-index: 10;

        .result_body {
          overflow: overlay;
          height: calc(100vh - 125px);
          transition: all 0.5s;

          .mat-table {
              .ellipsis_cell > ul li p > span {
              padding: 0;
              }
              .mat-header-cell,
              .mat-cell {
                padding: 3px;
                border: none;
                border: 0.01px solid #E1E1E1;
              }
          }

          .nav-tabs {
            border-radius: 0;
            border-bottom: 0;

            &.nav-tabs-vertical {        
              .nav-item .nav-link {
                padding-left: 10px;
                border-bottom: 1px solid #f0f0f0;
                margin: 0 0px 5px 0;
              }
              &.active {
                border-bottom: 1px solid #f0f0f0;
              }
            }
          }
        }

        .panel .shade-handle {
          + .panel-heading + .panel-collapse {
              .result_body {
              height: calc(100vh - 130px);
              padding-top: 0;

              .mat-cell {
                  cursor: text;
              }

              .data-null {
                  color: #2E3536 !important;
                  text-transform: uppercase;
              }
              }
          }
        }
    }

    &.theme-dark {
        .pql_body {
            .card-pqs .body {
            background-color: #ffffff;
            }
        }
        .pql_accordion,
        .footer_accordion {
            background: #ffffff;
            .h4 {
                border-top: 1px solid #ffffff;
            }

            .panel .shade-handle {
              + .panel-heading + .panel-collapse {
                  .result_body {
                    .mat-cell {
                        color: #212529 !important;
                    }
                  }
                }
            }
        }

        .footer_accordion {
          .result_body {
            .mat-table {
                .mat-header-cell,
                .mat-cell {
                  border-left: 0.01px solid #E1E1E1;
                }
            }
          }
        }
    }
}

/******** MODAL MATERIAL *******/
.theme-light-pqs {
    .dialog-common {
      background-color: #ffffff !important;
  
      .mat-dialog-title {
        font-size: 16px;
        margin-bottom: 30px;
      }
  
      .mat-dialog-content {
        font-size: 14px;
        overflow-wrap: break-word;
      }
  
      .mat-dialog-actions {
        button {
          font-weight: 400;
          font-size: 12px;
          min-width: 55px;
          line-height: 27px;
          opacity: 0.5;
          color: #394142 !important;
          background-color: transparent !important;
  
          &:hover {
            opacity: unset;
            background-color: transparent !important;
          }
  
          .mat-button-focus-overlay {
            display: none;
          }
        }
  
        button:last-child {
          opacity: unset;
          color: #ffffff !important;
          background: rgb(10,99,111);
          background: -moz-linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
          background: -webkit-linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
          background: linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a636f",endColorstr="#13bed5",GradientType=1);
  
          &:hover {
            color: #ffffff !important;
            background-color: #117a8b !important;
            transition: all 0.15s;
          }
        }
      }
    }

    .modal-body {
      .ng-select.custom .ng-select-container {
        background-color: #ffffff !important;
      }
    }

    &.theme-dark {
      .dialog-common {
        .mat-dialog-title {
          color: #394142;
        }

        .mat-dialog-actions {
          button {
            color: #394142;
          }

          button:last-child {
            opacity: unset;
            color: #ffffff !important;
            background: #FD7B03 !important;
          }
        }
      }

      .modal-body {
        .ng-select.custom .ng-select-container {
          background-color: #ffffff !important;
        }
      }
    }
}

/******** WORKSPACE ***********/
/* Workspace
--------- */

.theme-light-pqs {
    color: $t_dark_font;
    section {
      &.content {
        @include border-radius(0px);
        border: none;
        margin: 0px 210px 0px 230px;
        height: calc(100vh - 85px);
        top: 85px;
        padding: 25px;
        background: #F8F7FA;
        overflow: overlay;
        transition: none !important;
  
        &.fitArea {
          top: 40px;
          height: calc(100vh - 40px) !important;
        }
  
        &.content-full {
          margin: 0px;
          width: 100%;
          // height: calc(100vh - 0px);
  
          &.content-full-max {
            height: calc(100vh);
            top: 0px;
            padding-top: 50px !important;
  
            &.pt-5 {
              padding-top: 25px !important;
            }
          }
  
          &.content-query {
            position: fixed;
            // width: 200%;
            // height: 200%;
            padding: 0px;
          }
        }
  
        &.content-dp {
          margin: 0px;
        }
  
        h3 {
          margin-bottom: 20px;
        }
  
        .top-0 {
          top: 0px;
        }
  
        .p100 {
          padding: 100px 0;
        }
  
        .border-transparent {
          border: 2px solid transparent;
        }

        &.right_icon_toggle {
          section.content {
            margin-right: 0px;
          }
      
          &.left_icon_toggle {
            section.content {
              width: calc(100%);
              margin-left: 0 !important;
            }
          }
        }
      }
  
      &.content-full-page {
        width: 100%;
        min-height: 100vh;
        padding: 25px;
        background-color: #181a1b;
        max-height: 100%;
      }
  
      &.content-pamedi {
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        border-radius: 0px;
        border: none;
        // top: 35px;
        margin: 48px 5px 0 230px;
        padding: 0;
      }
    }
    a:not(.btn) {
      color: #212529; //#009ba7; //74ade2;
      text-decoration: none;
      font-size: 12px;
    }
  
    .pivot-container {
      height: 100%;
  
      .dataTables_filter {
        margin-bottom: 10px;
      }
  
      .dataTables_wrapper {
        height: 100%;
  
        .table-pivot {
          width: 100% !important;
          height: calc(100% - 80px);
          overflow: auto;
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  
    #workspace {
      .card {
        margin-bottom: 0px;
  
        > .body {
          min-height: calc(100vh - 15rem);
          max-height: 100%;
          padding: 2% !important;
  
          .demo-chart {
            height: calc(100vh - 18rem);
  
            svg path {
              fill: transparent;
            }
  
            &.h-85 {
              height: calc(100vh - 23rem) !important;
            }
          }
  
          pq-bignumber-async {
            .demo-chart {
              margin-top: -35px;
            }
          }
        }
      }
    }
  
    #rowDashboard {
      pq-bignumber-async {
        .demo-chart {
          margin-top: -35px;
        }
      }
  
      .wrapTableView {
        overflow: hidden;
        max-height: calc(100% - 30px) !important;
      }
  
      .scrolling-table {
        overflow: auto;
        height: calc(100% - 80px) !important;
  
        .mat-table {
          max-height: 100% !important;
        }
      }
  
      .scrolling-table-comparison {
        overflow: auto;
        height: calc(100% - 40px) !important;
  
        .mat-table {
          max-height: 100% !important;
          margin-bottom: 0 !important;
        }
      }
  
      [pq-basic-chart-async] {
        height: 100%;
      }
  
      pq-tableview-async,
      pq-pivot-async,
      pq-filterbox-async {
        .card {
          > .body {
            overflow: auto;
          }
        }
      }
  
      .demo-chart {
        height: 100% !important;
        // height: calc(100% - 50px) !important;
  
        svg path {
          fill: transparent;
        }
      }
  
      &.dash-grid {
        padding: 0px;
      }
  
      &.grid-stack-static {
        .grid-stack-item-content {
          .card {
            .body {
              .demo-chart {
                &.h-85 {
                  height: calc(100% - 43px) !important;
                }
  
                &.h-85-big {
                  height: calc(100% - 30px) !important;
                }
              }
            }
          }
        }
      }
  
      .grid-stack-item-content {
        border-radius: 0.5rem;
        overflow: unset;
        text-align: left;
        background-color: transparent;
        // top: 10px;
        left: 5px;
        right: 5px;
        height: 100%;
  
        .card {
          margin-bottom: 0px;
          height: inherit;
          display: block;
  
          .header {
            // min-height: 42px;
            > h2 {
              text-align: left;
              display: flex;
              text-align: left;
              display: flex;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .loader {
              margin-right: 5px;
            }
          }
  
          > .body {
            height: calc(100% - 40px) !important;
            // height: calc(100% - 33px)!important;
            overflow-y: unset !important;
            // display: flex;
  
            &.ch-full {
              height: 75vh;
            }
            &.ch-half {
              height: 50vh;
            }
            &.ch-1-3 {
              height: 25vh;
            }
            &.roundeds {
              height: calc(100% - 2px) !important;
            }
          }
  
          .demo-chart {
            height: 100% !important;
          }
        }
      }
  
      .grid-stack-item {
        .ui-resizable-se,
        .ui-resizable-sw {
          bottom: 5px;
        }
      }
    }
  
    &.theme-dark {
      color: $t_dark_font !important;
      h6 { color: $t_dark_font !important; }
      section {
        &.content,
        &.content-full {
          background: #F8F7FA;

          .form-control {
            border: none;
          }
        }
      }

      .is-active {
        color: #FD7B03 !important;
        // background:#F2F2F2 !important;
      }
    }
  
    // Menu Builder: Content Menu - Menu List
    .cdk-drag-placeholder {
      display: none;
    }
  
    .cdk-drag-preview {
      box-sizing: border-box;
      box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
  
      background: #fff;
      opacity: 0.3;
    }
  
    .drop-inside {
      border: 1px dashed #aaa;
    }
  
    .drop-before {
      border-top: 1px dashed #aaa;
    }
  
    .drop-after {
      border-bottom: 1px dashed #aaa;
    }
  
    .node-item {
      display: block;
      position: relative;
  
      .node-item-wrapper {
        position: relative;
        flex-direction: row;
        display: flex;
        align-items: center;
  
        a,
        .img-menu {
          width: 20px;
          height: 20px;
          text-align: center;
          align-self: center;
        }
  
        img {
          vertical-align: baseline;
        }
  
        .node-title {
          margin: 5px;
          background: transparent;
          border: 2px solid #d4d4d6;
          border-color: rgba(128, 128, 128, 0.2);
          cursor: pointer;
          width: 100%;
          padding: 5px;
          border-radius: 5px;
          display: flex;
          align-items: center;
  
          &:hover,
          &.active {
            background: rgba(128, 128, 128, 0.1);
            color: #009ba7;
            font-weight: 500;
            border: 2px solid #5cc5cd;
          }
  
          .item-notes {
            margin-left: 4px;
            margin-right: 10px;
            font-size: 12px;
            word-break: break-word;
          }
        }
      }
  
      .node-children {
        position: relative;
        transition: height 0.2s;
        margin-left: 22px;
      }
    }
  
    .leftbar-app {
      .node-title {
        width: 80%;
        color: #8c8c8c;
        padding: 5px 0 5px 10px;
        align-items: center;
        cursor: pointer;
        margin-right: 0;
  
        &:active {
          background: rgba(128, 128, 128, 0.1);
          color: #009ba7;
        }
      }
      .node-item {
        width: 100%;
      }
      .node-children {
        width: 100%;
      }
      .mat-icon-button {
        margin-left: auto;
        width: 14%;
        align-self: center;
      }
      .is-active {
        color: #0A636F !important;
        background:#F2F2F2 !important
      }
    }
  
    &.theme-dark {
      .node-item {
        .node-item-wrapper {
          .node-title {
            &:hover,
            &.active {
              color: #5cc5cd;
            }
          }
        }
      }
      a:not(.btn) {
        color: #394142; //#009ba7; //74ade2;
        text-decoration: none;
        font-size: 12px;
      }
    }
  }

/******** HIDE MENU *********/
.theme-light-pqs {
    .hide-menu {
      display: inline-block;
      position: fixed;
      bottom: 85px;
      margin-left: 229px;
      transition: all 0.5s;
      border: none;
      background: none;
      padding: unset;
  
      .btn-toggle {
        // width: 20px;
        // height: 50px;
        width: 15px;
        height: 40px;
        border-radius: 50%;
        position: relative;
        padding: 10px 5px;
        text-align: center;
        vertical-align: middle;
        display: inline-block;
        border-width: 2px;
        font-weight: 400;
        font-size: 0.8171em;
        // margin: 6px 1px;
        border: none;
        cursor: pointer !important;
  
        &.btn-toggle-menu {
          background: transparent;
          border-color: transparent;
  
          &:after {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            // transform: rotate(45deg);
            // background: #FFFFFF;
            // border-radius: 0px 4px 4px 0px;
            // border: 1px solid #d4d4d6;
  
            // border-top: 10px solid transparent;
            // border-bottom: 10px solid transparent;
            // border-left: 10px solid white;
  
            background: white;
            transform: perspective(2vw) rotateY(30deg);
            border: 1px solid #d4d4d6;
            border-left: 1px solid #ffffff;
          }
          &:hover,
          &:focus {
            background: transparent !important;
            border-color: transparent !important;
          }
        }
      }
  
      .btn-menu {
        line-height: 21px;
        font-family: 'Material-Design-Iconic-Font';
        display: inline;
        font-size: 15px;
        margin: 20px 0px 20px -2px;
  
        &::after {
          content: '\f2fa';
        }
      }
  
      &.rtl-sidebar {
        margin-left: -15px;
        transform: rotateY(180deg);
  
        // .btn-toggle {
        //     &.btn-toggle-menu {
        //         &:after {
        //             border-radius: 4px 0px 0px 4px;
        //         }
        //     }
        // }
  
        // .btn-menu {
        //     &::after {
        //         content:'\f2fb';
        //     }
        // }
      }
    }
  
    &.theme-dark {
      .hide-menu {
        .btn-toggle {
          &.btn-toggle-menu {
            &:after {
              background: #ffffff;
              border: 1px solid #d4d4d6;
              border-left: 1px solid #ffffff;
            }
          }
        }
      }
    }
  }

/******** APP SIDEBAR MENU *********/
.theme-light-pqs {
  &.app-preview > kt-base > #kt_wrapper > kt-header {
    display: none;
  }

  &.menu-displayed {
    .navbar-top,
    .sub-top-nav,
    .content.content-full,
    .app-footer {
      margin-left: 250px;
      width: calc(100vw - 250px);
    }

    .app-sidebar {
      margin-left: 0px;
      transition: all 0.5s;

      .custom-menu .btn-toggle .btn-menu::after {
        content: '\f136';
        font-family: 'Material-Design-Iconic-Font';
        display: inline;
        font-size: 14px;
      }
    }
  }

  // &.notif-displayed {
  //     kt-base #kt_wrapper kt-header.navbar.navbar-top.navbar-expand-lg.fixed-top {
  //         display: none;
  //     }
  // }

  .app-sidebar {
    margin-left: -250px;
    height: 100%;
    width: 250px;
    // width: 75px;
    position: fixed;
    z-index: 14;
    top: 0;
    left: 0;
    transition: all 0.5s;
    overflow: unset;
    white-space: nowrap;
    background: #ffffff;
    border-right: 2px solid #d4d4d6;
    z-index: 1000;
    padding-top: 15px;

    &:hover {
      margin-left: 0px !important;
    }

    .custom-menu {
      display: inline-block;
      position: fixed;
      // position: absolute;
      top: 0px;
      // right: 0;
      // margin-right: -25px;
      // left: 0;
      margin-left: 247px;
      // margin-left: -20px;
      transition: all 0.5s;
      border: none;
      background: none;
      padding: unset;

      .btn-toggle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        padding: 5px;
        text-align: center;
        vertical-align: middle;
        display: inline-block;
        border-width: 2px;
        font-weight: 400;
        font-size: 0.8171em;
        margin: 6px 1px;
        border: none;
        cursor: pointer !important;

        &.btn-toggle-menu {
          background: transparent;
          border-color: transparent;

          &:after {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            // transform: rotate(45deg);
            background: #eceff1;
            border-radius: 0px 4px 4px 0px;
            border: 2px solid #d4d4d6;
          }
          &:hover,
          &:focus {
            background: transparent !important;
            border-color: transparent !important;
          }
        }

        .btn-menu {
          margin-left: 0px;
          line-height: 21px;

          &::after {
            content: '\f197';
            font-family: 'Material-Design-Iconic-Font';
            display: inline;
            font-size: 14px;
          }
        }
      }
    }

    .list-group.panel > [fxlayout='row'] {
      overflow: auto;
      // height: calc(100vh - 55px);
      height: calc(100vh);
      padding-top: 15px;
      background-color: #eceff1;
    }

    .list-group {
      .top-logo {
        z-index: 9;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        padding: 12px;
        justify-content: center;
        display: none !important;
      }

      .collapse {
        .list-group-item {
          padding-left: 30px;
        }

        > .collapse {
          .list-group-item {
            padding-left: 40px;
          }

          > .collapse .list-group-item {
            padding-left: 50px;
          }
        }
      }

      .list-group-item {
        border-radius: 0;
        background-color: #eceff1;
        color: #222;
        border-left: 0;
        border-right: 0;
        border-color: #d4d4d6;
        white-space: nowrap;
        text-decoration: none;
        font-size: 12px;
        white-space: break-spaces;
        display: flex;
        cursor: pointer;

        .menu-icon {
          filter: invert(1);
          margin-right: 5px;
          width: 20px;
          height: 20px;
          filter: none;
          position: absolute;
          top: calc(50% - 11px);
          left: 15px;
        }

        .menu-list {
          vertical-align: bottom;
          margin: 0px 7px 0px 25px;
        }

        &:not(.collapsed) {
          background-color: #eceff1;
        }

        &[aria-expanded='false']::after {
          content: '\f2f6';
          font-family: 'Material-Design-Iconic-Font';
          display: inline;
          text-align: right;
          float: right;
          padding-left: 5px;
          position: absolute;
          top: calc(50% - 11px);
          right: 17px;
        }

        &[aria-expanded='true'] {
          background-color: #f7f7f7;

          &::after {
            content: ' \f2f2';
            font-family: 'Material-Design-Iconic-Font';
            display: inline;
            text-align: right;
            float: right;
            padding-left: 5px;
            position: absolute;
            top: calc(50% - 11px);
            right: 17px;
          }
        }
      }
    }

    .dd-list {
      .dd-item {
        background-color: #eceff1;

        .nestable-item-container {
          // padding-left: 0px;
          margin-bottom: 0px;

          .custom-card.btn1 {
            .dd-handle {
              border-color: transparent;

              > img[src=''] {
                display: none;
              }

              .zmdi {
                color: #009ba7;
              }
              // &:hover {
              //     background: transparent;
              // }
            }
          }
        }
      }
    }
  }

  .card.clickable {
    .menu-list {
      margin-left: -8px;

      ul.dd-list {
        li.dd-item {
          .nestable-item-container.mat-list-item {
            // width: calc(50vh - 130px);
            padding-left: 0px;

            .custom-card.btn1 {
              // width: calc(50vh - 165px);

              .dd-handle {
                // width: 12vw;
                width: 100%;
                span {
                  overflow-wrap: anywhere;
                }
              }
            }
          }

          ul.dd-list {
            padding-left: 20px !important;

            // ul.dd-list {
            //     padding-left: 25px !important;
            // }
          }

          .empty-space {
            padding-left: 25px;
          }
        }

        .dd-placeholder {
          margin-left: 25px;
        }
      }
    }
  }

  .nestable-item-container.mat-list-item {
    &:hover {
      background-color: transparent;
    }

    .custom-card.btn1 {
      width: 100%;

      .dd-handle {
        font-weight: 400;
        font-size: 12px;
        padding: 5px 0px 5px 10px;
        display: flex;
        border-color: rgba(128, 128, 128, 0.2);
        align-items: center;

        &:hover {
          background: rgba(128, 128, 128, 0.1);
          color: #009ba7;
          border-color: transparent;
        }

        .zmdi,
        img {
          width: 15px;
          height: 15px;
          text-align: center;
          // position: absolute;
          // top: calc(50% - 8px);
          font-size: 16px;
          margin-left: 7px;
          align-self: center;

          &.zmdi-edit {
            color: #009ba7;
          }
        }

        span {
          margin-left: 10px;
          margin-right: 10px;
          font-size: 12px;
          width: 100%;
          white-space: pre-wrap;
          overflow-wrap: anywhere;
        }
      }

      &.active,
      &.active:hover {
        background-color: inherit;
        .dd-handle {
          background: rgba(128, 128, 128, 0.1);
          color: #009ba7;
        }
      }
    }
  }

  .content.content-full.content-full-max {
    &.dash-view {
      height: calc(100vh - 40px);
      top: 40px;
    }
  }

  .dash-view {
    .content.content-full {
      height: calc(100vh - 85px);
    }
  }

  .chart-view {
    .content.content-full {
      height: calc(100vh);
    }
  }

  .app-content {
    // padding-bottom: 150px;
    padding-bottom: 10px;

    .row.py-1.sub-top-nav {
      margin-left: -25px;
      top: 42px !important;
    }

    .content.content-full.content-full-max {
      height: 100%;
      overflow: unset;
      top: -20px !important;
      margin: 0;
      padding: 0px !important;
      width: 100%;
      box-shadow: none;

      .row.clearfix.grid-stack.grid-stack-static {
        &#rowDashboard {
          &[data-gs-current-row='0'] {
            height: auto !important;
          }
        }
      }
    }
  }

  ul .dd-placeholder {
    background: transparent !important;
  }

  &.theme-dark {
    .app-sidebar {
      background-color: #FFFFFF;
      border-right: 2px solid #d4d4d6;

      .list-group {
        .list-group-item {
          color: #ccc;
          background-color: #272e2f;
          border-color: #202627;

          .menu-icon {
            filter: none;
          }

          &[aria-expanded='true'] {
            background-color: #181a1b;
          }
        }
      }

      .list-group.panel > [fxlayout='row'] {
        background-color: #272e2f;
      }

      .custom-menu {
        .btn-toggle {
          &.btn-toggle-menu {
            &:after {
              background: #ECEFF1;
            }
          }
        }
      }

      .dd-list {
        .dd-item {
          background-color: #272e2f;
        }
      }
    }
  }
}

/****** Sidebar Toggle ******/
.theme-light-pqs {
  &.ls-toggle-menu {
    .sidebar {
      width: 230px;
      left: -230px;
    }
    section.content {
      margin-left: 0px;
    }

    .pql_accordion {
      margin-left: 0px;
      width: calc(100% - 200px);
    }

    &.right_icon_toggle {
      .pql_accordion {
        width: calc(100%);
      }
    }

    &.ls-closed {
      .pql_accordion {
        width: calc(100%);
      }
    }
  }

  &.right_icon_toggle {
    // .navbar-right{
    //     width: 200px;
    //     right: -200px;
    // }
    section.content {
      margin-right: 0px;
    }

    .pql_accordion {
      margin-right: 0px;
      width: calc(100% - 230px);
    }

    .footer_accordion {
      width: calc(100%) !important;
    }

    &.left_icon_toggle {
      section.content {
        width: calc(100%);
        margin-left: 0 !important;
      }
    }
  }

  &.ls-closed {
    .navbar-right {
      width: 0;
    }
    section.content {
      margin: 0px;
    }
  }

  &.left_icon_toggle {
    .sidebar {
      width: 230px;
      left: -230px;

      .hide-menu {
        .btn-menu {
          &::after {
            content: '\f2fb';
          }
        }
      }
    }

    .footer_accordion {
      margin-left: 0px;
      width: calc(100% - 200px);
    }
  }

  &.right_icon_toggle {
    .navbar-right {
      width: 200px;
      right: -200px;

      .hide-menu {
        .btn-menu {
          &::after {
            content: '\f2fb';
          }
        }
      }
    }
  }
}

/******* Sidebar Menu ******/
.theme-light-pqs {
  .right-sidebar {
    height: calc(100vh - 40px);
    background: #ffffff;
    left: -300px;
    width: 225px;

    &.open {
      left: 20px;
    }
  }

  .sidebar-menu {
    padding-left: 20px;
    list-style: none;

    .side-item {
      margin-bottom: 15px;
      padding-left: 10px;

      &.active {
        .side-link:not(.btn) {
          background-color: #e1e4e6;
          border-radius: 0.2875rem;
          padding: 5px 10px;
        }
      }

      .side-link {
        color: #222222;
        padding: 5px 10px;
        font-size: 12px;

        &.disabled {
          color: #8c8c8c !important;
          pointer-events: none;
        }

        &.side-link:focus,
        &.side-link:hover,
        &.side-link:active {
          background-color: #e1e4e6;
          border-radius: 0.2875rem;
          padding: 5px 10px;
          -moz-transition: all 0.25s;
          -o-transition: all 0.25s;
          -webkit-transition: all 0.25s;
          transition: all 0.25s;
        }

        &.side-link:disabled {
          opacity: 0.65;
        }
      }
    }
  }

  &.theme-dark {
    .right-sidebar {
      background: #383f43;
    }

    .sidebar-menu {
      .side-item {
        &.active {
          .side-link:not(.btn) {
            background-color: rgba(222, 222, 222, 0.5);
          }
        }

        .side-link {
          color: #ffffff;

          &.side-link:focus,
          &.side-link:hover,
          &.side-link:active {
            background-color: rgba(222, 222, 222, 0.5);
          }
        }
      }
    }
  }
}

/***** MODAL POPUP ****/
.theme-light-pqs {
  .close {
    text-shadow: none;
  }

  .modal-header {
    .title,
    .modal-title,
    .modal-title label {
      font-size: 16px;
      color: #8c8c8c;
      display: flex;
    }
    .close,
    .close:hover {
      color: #8c8c8c !important;
      position: unset;
    }
  }

  .modal-content {
    .modal-body {
      .row h5 {
        overflow-wrap: break-word;
      }
    }
  }

  .modal-footer,
  .table {
    .btn.btn-neutral.btn-simple {
      color: #ffffff;
    }
  }

  .modal-backdrop {
    background: rgba(0, 0, 0, 0.95);
  }

  // multiple color picker
  .color-pickers {
    height: 128px;
    width: 224px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    background-color: #eee;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    transform: scale(0, 0);
    transform-origin: bottom left;
    transition: transform 0.2s ease;
    will-change: transform;
  }

  .hex {
    width: 96px;
    height: 32px;
    padding: 8px;
    box-sizing: border-box;

    input {
      width: 100%;
      outline: none;
      box-sizing: border-box;
      padding: 0;
      border: none;
      font-family: 'RobotoDraft';
      background-color: transparent;
    }

    .underline {
      width: 100%;
      box-sizing: border-box;
      height: 2px;
      background-color: #ccc;
      position: relative;
    }
  }

  .underline:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #03a9f4;
    transform: scaleX(0);
    transition: transform 0.2s ease;
  }

  .hex input:focus + .underline:before {
    transform: scaleX(1);
  }

  input[type='color'] {
    // width: 64px;
    // height: 32px;
    width: 30px !important;
    height: 30px !important;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    transition: box-shadow 0.5s ease;

    &:active,
    &:focus {
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.25), 0px -5px 10px rgba(0, 0, 0, 0.25);
    }
  }

  .color-pickers > div {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .transparent {
    border: 1px solid #333;
    box-sizing: border-box;
    background-image: linear-gradient(
      -45deg,
      transparent,
      transparent 48%,
      red 49%,
      red 51%,
      transparent 52%,
      transparent
    );
  }

  .btnDelGauge {
    position: absolute;
    left: 9em;
    bottom: 0.3em;
  }

  #close {
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    color: #333;
  }

  .paper-button {
    padding: 2px 42px;
    border: none;
    background-color: #2196f3;
    color: white !important;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: box-shadow 0.1s ease;
    position: absolute;
  }

  .opened + .color-pickers {
    transform: scale(1, 1) translate(0, 0);
  }

  .translate {
    transform: translate(0);
    position: absolute;
    z-index: 99;
    top: 0em;
    right: 28em;
  }

  .material {
    position: relative;
    color: white;
    margin: 20px auto;
    height: 400px;
    width: 500px;
    background: #f45673;
  }

  .ripple {
    overflow: hidden;
  }

  .ripple-effect {
    position: absolute;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: white;
    animation: ripple-animation 2s;
  }

  @keyframes ripple-animation {
    from {
      transform: scale(1);
      opacity: 0.4;
    }

    to {
      transform: scale(100);
      opacity: 0;
    }
  }
  // end multiple color picker

  .modal.left,
  .modal.right {
    .modal-dialog {
      position: fixed;
      margin: auto;
      // width: 320px;
      // height: 100%;
      top: 85px;
      height: calc(100vh - 86px);
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
    }

    .modal-content {
      height: 100%;
      overflow-y: auto;
    }
  }

  /*Left*/
  .modal.left.fade {
    .modal-dialog {
      left: -320px;
      -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
      -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
      -o-transition: opacity 0.3s linear, left 0.3s ease-out;
      transition: opacity 0.3s linear, left 0.3s ease-out;
    }
    &.show .modal-dialog {
      left: 0;
    }
  }

  /*Right*/
  .modal.right.fade {
    color: #8c8c8c;

    .modal-dialog {
      right: -320px;
      -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
      -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
      -o-transition: opacity 0.3s linear, right 0.3s ease-out;
      transition: opacity 0.3s linear, right 0.3s ease-out;
    }
    &.show .modal-dialog {
      right: 0;
    }
  }

  &.theme-dark {
    .modal {
      .modal-dialog {
        .modal-content {
          background-color: #ffffff;
        }
      }
      .modal-header {
        .close,
        .modal-title,
        .modal-title label {
          color: #2E3536;
        }
      }
      .modal-footer,
      .table {
        .btn.btn-neutral.btn-simple {
          color: #ffffff;
        }
      }
    }
    .color-theme {
      background-color: #ffffff !important;

      .modal-title {
        color: #fff;
      }

      .title {
        color: #fff;
      }

      label {
        color: #747576;
      }
    }
  }
}

/*** Checkbox Material ***/
.theme-light-pqs {
  .mat-checkbox {
    .mat-checkbox-frame {
      border-color: #808080;
      border-width: 1px;
    }
    .mat-checkbox-background {
      svg {
        path {
          fill: none;
        }
      }
    }
  }

  .mat-checkbox-label {
    line-height: 17px;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #FD7B03 !important;
  }

  &.theme-dark {
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: #FD7B03 !important;
    }
  }
}

/**** Icon List ****/

.theme-light-pqs {
  .file_manager {
    .file {
      cursor: pointer;

      .icon {
        i {
          font-size: 62px;
        }
      }

      .file-name2 {
        background-color: #f7f7f7;
        border-color: none;
        padding: 20px 20px;
        transition: 0.3s all;

        > img {
          filter: brightness(0.6);
        }
        > span {
          color: #2E3536;
          font-size: 14px;
        }
      }

      > [type='checkbox'] {
        display: none;

        &:checked {
          + .file-name2 {
            background-color: #117a8b !important;
            .text-muted {
              color: #f7f7f7 !important;
            }
          }
        }
      }

      .zmdi-chart {
        color: inherit;
      }

      &:hover {
        .file-name2 {
          background-color: #117a8b !important;
          transition: 0.3s all !important;

          > img {
            filter: none !important;
          }
          > span {
            color: #ffffff !important;
          }
        }
      }
    }
    .isDisabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.theme-dark {
    .file_manager {
      .file {
        .file-name {
          border-color: #2e3435;
        }

        .file-name2 {
          background-color: #f7f7f7;

          > img {
            filter: none;
          }
          > span {
            color: #2E3536;
          }
        }

        &:hover {
          .file-name2 {
            background-color: #F1C79B !important;
            transition: 0.3s all !important;
  
            > img {
              filter: none !important;
            }
            > span {
              color: #ffffff !important;
            }
          }
        }
      }
      .isDisabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }
  }
}