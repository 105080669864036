﻿.sweet-alert {
    @include border-radius(0 !important);

    p {
        font-size: $font-size + 2px !important;
    }

    .sa-input-error {
        top: 23px !important;
        right: 13px !important;
    }

    h2 {
        font-size: $font-size + 6px !important;
        margin: 0 0 5px 0 !important;
    }

    button {
        @include border-radius(0 !important);
        font-size: $font-size + 3px !important;        
        padding: 5px 20px !important;
    }
}