/* Roboto Font Family Normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/Roboto-Thin.ttf') format('truetype');
  font-display: swap; 
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Roboto-Light.ttf') format('truetype');
  font-display: swap; 
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-display: swap; 
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
  font-display: swap; 
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
  font-display: swap; 
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Roboto-Black.ttf') format('truetype');
  font-display: swap; 
}


/* Roboto Italic Font Family */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-display: swap; 
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/Roboto-LightItalic.ttf') format('truetype');
  font-display: swap; 
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Roboto-Italic.ttf') format('truetype');
  font-display: swap; 
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-display: swap; 
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-display: swap; 
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-display: swap; 
}

/* Material Icons Fonts */

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Material_Icons.woff2') format('woff2');
  font-display: swap; 
}
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}