﻿.colorpicker {
    z-index: 99;

    &:before,
    &:after {
        display: none !important;
    }

    &.colorpicker-with-alpha {
        max-width: max-content;
    }
}