// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.nav-tabs {
    border: 0;
    padding: $padding-large-vertical $padding-base-horizontal;

    > .nav-item{
        
        > .nav-link{
            color: $default-color;
            margin: 0;
            margin-right: 5px;
            background-color: $transparent-bg;
            border: 1px solid $transparent-bg;
            border-radius: 30px;
            font-size: $font-size-base;
            padding: $padding-btn-vertical $padding-round-horizontal;
            line-height: $line-height-general;

            &:hover {
                background-color: $transparent-bg;
            }

            &.active{
                border: 1px solid $default-color;
                border-radius: 30px;
            }

            i.now-ui-icons{
                font-size: 14px;
                position: relative;
                top: 1px;
                margin-right: 3px;
            }

        }

        &.disabled > .nav-link,
        &.disabled > .nav-link:hover {
            color: rgba(255,255,255,0.5);
        }
    }

    &.nav-tabs-neutral{
        > .nav-item{
            > .nav-link{
                color: $white-color;

                &.active{
                    border-color: $opacity-5;
                    color: $white-color;
                }
            }
        }
    }
    &.nav-tabs-primary{
        > .nav-item{
            > .nav-link{
                &.active{
                    border-color: $primary-color;
                    color: $primary-color;
                }
            }
        }
    }
    &.nav-tabs-info{
        > .nav-item{
            > .nav-link{
                &.active{
                    border-color: $info-color;
                    color: $info-color;
                }
            }
        }
    }
    &.nav-tabs-danger{
        > .nav-item{
            > .nav-link{
                &.active{
                    border-color: $danger-color;
                    color: $danger-color;
                }
            }
        }
    }
    &.nav-tabs-warning{
        > .nav-item{
            > .nav-link{
                &.active{
                    border-color: $warning-color;
                    color: $warning-color;
                }
            }
        }
    }
    &.nav-tabs-success{
        > .nav-item{
            > .nav-link{
                &.active{
                    border-color: $success-color;
                    color: $success-color;
                }
            }
        }
    }
    &.sm{
        > .nav-item{

            > .nav-link{                
                font-size: $font-size;
                padding: 6px 5px;

                &.active,
                &:hover{
                    padding: 6px 20px;
                }
            }
        }
    }
}
