// Import Variable
@import '../../scss/ui-kit//variables';


/*
=============================
    GLOBAL HEADER AREA 
=============================
*/

/******** NAVBAR ***********/
.theme-light-pqs {
    .navbar {
      background: 
        var(--White-Mint, linear-gradient(90deg, rgba(219, 219, 219, 0.60) -3.12%, rgba(29, 144, 161, 0.60) 31.33%, rgba(29, 144, 161, 0.60) 40.87%, rgba(20, 72, 80, 0.60) 87.97%, rgba(33, 33, 33, 0.60) 114.2%)),
        url('../../images/layer-header.svg') center no-repeat;
      background-blend-mode: overlay;
      min-height: 40px;
      transition: all 0.5s;
      z-index: 13;
      box-shadow: none;
  
      .icons {
        color: black;
      }
  
      a {
        &:not(.btn):not(.dropdown-item) {
          color: #222222;
          font-weight: 500;
        }
      }
  
      .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
      }
  
      .navbar-nav {
        flex-direction: row;
  
        .nav-item {
          .nav-link.notif-visible {
            .zmdi-notifications-active {
              vertical-align: 8.5px;
            }
            .badge {
              vertical-align: 15px;
            }
          }
        }
  
        .nav-link:not(.btn) {
          font-size: 0.55em;
          padding: 0.45rem 0.6rem;
          line-height: 0.7142em;
  
          &.disabled {
            color: #808080;
          }
        }
  
        .nav-item.active .nav-link:not(.btn),
        .nav-item .nav-link:not(.btn):focus,
        .nav-item .nav-link:not(.btn):hover,
        .nav-item .nav-link:not(.btn):active {
          background: var(--green-gradient, linear-gradient(90deg, #0A636F, #13BED5));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 900;
        }
        
        .dropdown-menu {
          position: absolute;
        }
  
        &.menu-nav {
          display: none;
  
          a.nav-link {
            &:hover,
            &.active {
              background-color: rgba(222, 222, 222, 0.5);
              border-radius: 6px;
            }
          }
        }
      }
  
      .navbar-brand {
        padding-top: 0.35rem;
        padding-bottom: 0.35rem;
      }
    }

    kt-menu-horizontal {
      width: calc(100% - 65px);
    }
  
    &.theme-dark {
      .navbar {
        background: linear-gradient(90deg, rgba(8, 79, 129, 0.12) 0%, rgba(8, 79, 129, 0.60) 42.4%, rgba(127, 103, 67, 0.60) 82.4%, rgba(255, 128, 0, 0.36) 100%),
        url('../../images/layer-header.svg') center no-repeat;
  
        a {
          &:not(.btn):not(.dropdown-item) {
            color: #394142;
          }
        }
  
        .icons {
          color: #999;
        }
  
        .navbar-brand {
          i {
            color: #fff;
          }
        }

        .nav-item.active .nav-link:not(.btn),
        .nav-item .nav-link:not(.btn):focus,
        .nav-item .nav-link:not(.btn):hover,
        .nav-item .nav-link:not(.btn):active {
          background: var(--orange-trueai, #FD7B03);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 900;
        }
      }
    }
}

@media (max-width: 560px) {
  .theme-light-pqs {
    .navbar {
      .navbar-collapse {
        .navbar-nav {
          display: none;

          .dropdown-menu {
            position: fixed !important;
            left: 5%;
            width: 90%;
          }

          &.menu-nav,
          &.right-nav {
            display: flex;
          }
        }
      }
    }
  }
}

/* Sub Top Navigation
------------------ */

.theme-light-pqs {
  .sub-top-nav {
    position: fixed;
    top: 42px;
    width: 100%;
    z-index: 12;
    margin: 0;
    background: var(--White-Mint, linear-gradient(90deg, rgba(219, 219, 219, 0.60) -3.12%, rgba(29, 144, 161, 0.60) 31.33%, rgba(29, 144, 161, 0.60) 40.87%, rgba(20, 72, 80, 0.60) 87.97%, rgba(33, 33, 33, 0.60) 114.2%));
    border-bottom: 1px solid #d4d4d6;
    transition: all 0.5s;

    .nav {
      padding: 0px;
      position: relative;
      flex-wrap: unset;
      .nav-item {
        width: calc(100% - 175px);
        margin-top: 7px;
        .nav-link {
          padding: 4px;
        }
      }
    }
  }

  &.theme-dark {
    .sub-top-nav {
      background: linear-gradient(90deg, rgba(8, 79, 129, 0.12) 0%, rgba(8, 79, 129, 0.60) 42.4%, rgba(127, 103, 67, 0.60) 82.4%, rgba(255, 128, 0, 0.36) 100%);
    }
  }
}