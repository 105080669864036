﻿.inbox_table{
    table-layout: fixed;
    @media only screen and (max-width: $break-small - 1px){
        table-layout: auto;
    }

    td,th{
        white-space: nowrap;
        vertical-align: middle;
    }
    .chb{
        width: 50px;
    }
    .starred{
        width: 25px;
        padding: 1rem 5px;
        a{
            color: $grey-400;
        }
        &.active{
            a{
                color: $orange;
            }
        }
    }
    .u_image{
        width: 45px;
        padding: 1rem 5px;
        @media only screen and (max-width: $break-small - 1px){
            display: none;
        }
    }
    .u_name{
        width: 130px;
        padding: 1rem 5px;
        h5{
            @include three-dots-overflow();    
        }
    }
    .max_ellipsis{
        @include three-dots-overflow();
        padding: 1rem 5px;
        @media only screen and (max-width: $break-small - 1px){
            display: none;
        }
        a{
            color: $grey-700;
            font-size: $font-size + 2px;
            &:hover{
                color: $blue;
            }
        }
        .badge{
            margin: 0;
        }
    }
    .clip{
        text-align: center;
        width: 25px;
        padding: 1rem 5px;
        @media only screen and (max-width: $break-small - 1px){
            display: none;
        }
    }
    .time{
        font-size: $font-size + 1px;
        width: 80px;
        text-align: right;
        color: $grey-400;
    }
    .unread{
        .u_name{
            h5{
                font-weight: $font-weight-700;
            }
        }
    }
}

.inbox{
    &.left{
        @include transition(all .3s ease-in-out);
        width: 240px;
        padding-right: 20px;
        display: block;

        @media only screen and (max-width: $break-small){
            background-color: $white;
            position: fixed;
            left: 0;
            top: 0;
            padding: 20px;
            height: 100vh;
            z-index: 99;

            &:not(.show) {
                display: none;
                + {
                    .right {
                        width: 100%;
                    }
                }
            }
        }

        .mail-side{
            .nav{
                flex-direction: column;

                li{
                    @extend .m-b-5;
                    padding: 0 10px;
                    line-height: 40px;

                    a{
                        color: $grey-700;
                        display: flex;
                        align-items: center;                        
                        
                        i{
                            font-size: $font-size + 4px;
                            width: 25px;                        
                        }

                        .badge {
                            margin-left: auto;
                            margin-right: 0;
                        }
                    }

                    &:hover,
                    &.active{
                        background-color: $white;
                        a{
                            color: $dark;
                        }
                    }
                }
            }

            h3{
                font-size: $font-size + 3px;
                font-weight: 500;
                margin-bottom: 15px;
                margin-top: 30px;
                line-height: 20px;
            }
        }
    }
    &.right{
        @include transition(all .3s ease-in-out);
        width: calc(100% - 240px);
        position: relative;

        @media only screen and (max-width: $break-small){
            width: 100%;
        }

        .i_action{
            background: $white;
            padding: .75rem;

            @media only screen and (max-width: $break-small - 1px){
                display: none !important;
            }
        }
    }
}

.mobile-left{
    display: none;
    position: fixed;
    z-index: 9999;
    right: 20px;
    bottom: 20px;

    @media only screen and (max-width: $break-small){
        display: block;        
    }
}

.file_folder{

    a{
        border-radius: $border-radius-small;
        border: 1px solid $grey-200;
        display: inline-block;        
        position: relative;
        overflow: hidden;
        width: 220px;

        @media only screen and (max-width: $break-small - 218px){
            width: 100%;
        }
    }
    .icon {
        @extend .m-r-10;
        @extend .align-center;
        float: left;
        width: 60px;

        i {
            font-size: $font-size + 17px;
            vertical-align: middle;
            line-height: 65px;
        }
    }

    .file-name {
        padding: 10px;        
        .date {
            float: right;
        }
    }
}