//BEGIN THEME DARK CUSTOM
th {
  font-size: 10px;
}
td {
  font-size: 10px;
}

.dataTables_wrapper {
  font-size: 10px;
}
.dataTables_wrapper .dataTables_length label {
  font-size: 10px;
}
.dataTables_wrapper .dataTables_filter label {
  font-size: 10px;
}
.dataTables_wrapper .dataTables_filter label input {
  font-size: 10px;
}

.dataTables_wrapper select {
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  -ms-box-shadow: none;
  box-shadow: none;
  background-color: #ddd;
  color: #000;
}

.btn2 {
  border-width: 2px;
  font-weight: 400;
  font-size: 0.8171em;
  line-height: 1.35em;
  margin: 5px 1px;
  border: none;
  border-radius: 0.2875rem;
  padding: 8px 18px;
  cursor: pointer;
  background-color: white;
  color: #000;
}

.btn.btn-icon.btn-info i {
  color: #000;
}

.bg-container {
  background: white;
}

.bg-home-pds {
  background: white;
  color: gray;
}

.disabled-gray {
  // opacity:.5;
  // filter:alpha(opacity=50);
  color: gray !important;
}

.mat-menu-item-highlighted:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item:hover:not([disabled]) {
  background: rgba(0, 0, 0, 0.2);
}

.mat-menu-item {
  background: transparent;
  color: #888888;
  font-size: 10px;
  line-height: 25px !important;
  height: 25px !important;
}

.nav-pills,
.nav-tabs {
  margin: 0 0 0 0 !important;
}

.theme-light-pqs .navbar {
  // background: #FFFFFF;
  max-height: 40px;
  min-height: 42px !important;
}

.nav-tabs {
  padding: 0px 0px 12px;
  display: table;
  width: 100%;

  .nav-item {
    display: table-cell;
    text-align: center;
  }
}

.mat-tree-node {
  min-height: 15px !important;
}

.btn-tree-node {
  background: transparent;
  border: none;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400 !important;
  font-size: 10px !important;
}

// .theme-light-pqs .btn-outline {
//      height: 2.5rem !important;
// }

// .theme-light-pqs .sub-top-nav {
//     top: 46px;
// }

// .theme-light-pqs .sidebar {
//     top: 77px;
// }

// .theme-light-pqs .navbar-right {
//     top: 77px;
// }

// .theme-light-pqs .sub-top-nav .nav .nav-item {
//     margin-top: 5px !important;
// }

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label {
  font-size: 10px;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  box-sizing: content-box;
  background: none;
  border: 0;
  box-shadow: none;
  outline: 0;
  cursor: default;
  width: 100%;
  color: black;
}

.theme-light-pqs .card .header .header-dropdown {
  top: 8px;
  right: 10px;
}

.ng-resizable-handle.ng-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: -47px !important;
}

.ng-select .ng-clear-wrapper {
  cursor: pointer;
  position: absolute !important;
  width: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.grabber {
  width: 16px;
  height: 16px;
  background-position: -64px -224px;
  background-image: url(http://code.jquery.com/ui/1.10.4/themes/smoothness/images/ui-icons_222222_256x240.png);
  position: fixed;
  color: #000;
  right: 0;
  bottom: 0;
  z-index: 90;
  cursor: se-resize;
}

.page-loader-page {
  z-index: 99999999;
  width: 100%;
  height: 100%;
  background: rgb(65, 64, 64);
  opacity: 0.5;
  overflow: hidden;
  text-align: center;
  position: absolute;
}
.page-loader-page p {
  font-size: 13px;
  font-weight: 700;
  color: #777;
  margin-top: 10px;
  left: 50%;
  top: 50%;
}
.page-loader-page .loader {
  position: relative;
  // top:calc(40% - 30px);
  margin: auto;
  left: 50%;
  right: 0;
  top: 50%;
  bottom: 0;
  position: fixed;
}

.theme-light-pqs .btn-outline.btn-outline-mini {
  width: 2rem;
}

// .theme-light-pqs .btn-outline {
// 	height: 2rem !important;
// }

.btn {
  margin: 5px 3px;
}

.theme-dark {
  .btn.btn-icon.btn-info i {
    color: #2E3536;
  }

  .btn2 {
    border-width: 2px;
    font-weight: 400;
    font-size: 0.8171em;
    line-height: 1.35em;
    margin: 5px 1px;
    border: none;
    border-radius: 0.2875rem;
    padding: 8px 18px;
    cursor: pointer;
    background-color: #000;
    color: #fff;
  }

  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    box-sizing: content-box;
    background: none;
    border: 0;
    box-shadow: none;
    outline: 0;
    cursor: default;
    width: 100%;
    color: #2E3536;
  }

  .page-loader-page {
    z-index: 99999999;
    width: 100%;
    height: 100%;
    background: rgb(65, 64, 64);
    opacity: 0.5;
    overflow: hidden;
    text-align: center;
    position: absolute;
  }
  .page-loader-page p {
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    margin-top: 10px;
    left: 50%;
    right: 0;
    top: 50%;
    bottom: 0;
    position: fixed;
  }
  .page-loader-page .loader {
    position: relative;
    // top:calc(40% - 30px);
    margin: auto;
    left: 50%;
    right: 0;
    top: 40%;
    bottom: 0;
    position: fixed;
  }

  .dataframe {
    background-color: rgb(66, 65, 65);
    color: white;
  }

  .dataframe tbody tr:hover {
    background-color: darkgray;
  }

  table.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > td:first-child:before,
  table.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > th:first-child:before {
    // background-color: #18eaea!important;
    background-color: gray !important;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }

  // .mat-spinner-color circle {
  // 	stroke: #ffffff !important;
  // }

  table.dataTable tbody tr {
    background-color: #ffffff;
    color: #2E3536;
  }

  // mat-footer-row,
  // mat-header-row,
  // mat-header-cell,
  // mat-row {
  // background: #343a40 !important;
  // color: white !important;
  // font-size: 10px;
  // border-style: none !important;
  // width: 100%;
  // }

  .table-dark {
    color: #fff !important;
    background-color: #ffffff !important;
  }

  .table-container {
    // max-height: 400px;
    // overflow: auto;
    display: flex;
    flex-direction: column;
    // max-height: 400px;
  }

  .mat-table {
    overflow: auto;
    // max-height: 400px;
    //width: 100%;
  }

  .mat-cell,
  .mat-footer-cell {
    color: white !important; //#3e3e3e
    font-size: 10px !important;
    //white-space: nowrap !important;
    white-space: break-spaces;
    padding: 10px 20px;
    min-width: 150px;
    //margin: 0 5px !important;
    //overflow: auto;
    // border-top: 1px solid #95999c!important;
  }

  .mat-header-cell {
    color: white !important;
    font-size: 10px !important;
    min-width: 150px;
  }

  // .mat-row:nth-child(even) {
  // 	// background-color:#343a40!important;
  // 	background-color: rgba(255, 255, 255, 0.05) !important;
  // 	font-size: 10px !important;
  // }

  // .mat-row:nth-child(even) .mat-cell{
  // 	background-color: rgba(255, 255, 255, 0.05)!important;
  // 	font-size: 10px !important;
  //   }

  .mat-row:nth-child(odd) {
    // background-color:#343a40!important;
    font-size: 10px !important;
  }

  /* width */
  // ::-webkit-scrollbar {
  // 	width: 7px;
  // }

  // ::-webkit-scrollbar-track {
  // 	background: transparent !important;
  // }

  // table {
  // 	width: 100% !important;
  // }

  mat-cell > span.ellipsis {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }

  table.dataTable.hover tbody tr:hover,
  table.dataTable.display tbody tr:hover {
    background-color: darkgray;
  }

  .dataTables_wrapper .dataTables_filter input {
    margin-left: 0.5em;
    background: black;
  }

  .dataTables_wrapper .dataTables_filter input {
    background: #3e3e3e;
    color: #8c8c8c;
  }

  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter,
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_processing,
  .dataTables_wrapper .dataTables_paginate {
    color: #fff;
  }

  .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
    width: 55px;
  }

  // .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
  // .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
  // .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  // 	// color: #fff !important;
  // }

  .dataTables_wrapper .dataTables_paginate .paginate_button {
    color: #fff !important;
  }

  .dataTables_wrapper select {
    border-radius: 4px;
    height: 25px;
    border-bottom: none;
    color: #fff;
    margin: 0 3px;
    background-color: #394142;
  }

  .form-control2,
  .theme-dark .form-control2:focus {
    box-shadow: none;
    border: none;
    background-color: #ffffff; 
    color: #394142;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  .sidebar {
    background: #2e3435 !important;
  }

  .mat-tree {
    background: transparent;
    color: #8c8c8c;
  }

  // .icon-dark {
  // 	color: #8c8c8c;
  // }

  .text-dark {
    color: #888888 !important;
  }

  .bg-home-pds {
    background: #383f43;
    color: #fff;
  }

  .bg-container {
    background: black;
  }

  // /* for block of numbers */
  td.hljs-ln-numbers {
    user-select: none;
    text-align: center;
    color: #cccccc6b;
    border-right: 1px solid #cccccc1c;
    vertical-align: top;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  // .nav-tabs .nav-item .nav-link.active i,
  // .nav-tabs .nav-item .nav-link:active i,
  // .nav-tabs .nav-item .nav-link i {
  // 	color: #222 !important;
  // }

  // .nav-tabs .nav-item .nav-link i {
  // 	color: #888 !important;
  // }

  // .nav-tabs .nav-item .nav-link i:hover {
  // 	color: #888 !important;
  // }

  // .nav-tabs > .nav-item > .nav-link:hover {
  // 	background-color: rgba(0, 0, 0, 0);
  // 	color: #888 !important;
  // }

  .mat-menu-item {
    background: transparent;
    color: #394142;
    font-size: 10px;
  }

  .mat-select-value {
    color: #FD7B03 !important;
  }

  .mat-select-arrow {
    color: #fff;
  }

  .mat-button.mat-primary[disabled],
  .mat-button.mat-accent[disabled],
  .mat-button.mat-warn[disabled],
  .mat-button[disabled][disabled],
  .mat-icon-button.mat-primary[disabled],
  .mat-icon-button.mat-accent[disabled],
  .mat-icon-button.mat-warn[disabled],
  .mat-icon-button[disabled][disabled],
  .mat-stroked-button.mat-primary[disabled],
  .mat-stroked-button.mat-accent[disabled],
  .mat-stroked-button.mat-warn[disabled],
  .mat-stroked-button[disabled][disabled] {
    color: #888;
  }

  .ng-select .ng-select-container {
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Gilroy', sans-serif;
    font-size: 10px;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #ced4da;
    min-height: 30px;
    align-items: center;
    transition: all 0.3s;
    box-sizing: border-box;
    padding-right: 11px;
    // padding-left: 11px;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    position: absolute;
    z-index: 1;
    font-size: 10px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #ffffff;
    color: #2E3536 !important;
    padding: 5px 12px;
    transition: background 0.3s ease;
    font-size: 10px;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin-top: 3px;
    margin-right: 4px;
    font-size: 0.9em;
    background-color: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    height: 24px;
    line-height: 22px;
    color: #2E3536;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #808080;
    background-color: #2e3435;
  }

  .kt-portlet {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    background-color: #383f43;
    margin-bottom: 20px;
    border-radius: 4px;
    color: #fff;
  }

  .kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff;
  }

  .mat-button.mat-primary,
  .mat-icon-button.mat-primary,
  .mat-stroked-button.mat-primary {
    color: #5cc5cd;
  }

  .resizable {
    min-width: 40px;
    padding-right: 10px;
    position: relative;
  }

  .grabber {
    width: 16px;
    height: 16px;
    background-position: -64px -224px;
    background-image: url(http://code.jquery.com/ui/1.10.4/themes/smoothness/images/ui-icons_222222_256x240.png);
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 90;
    cursor: se-resize;
  }
}
//END THEME DARK CUSTOM

//BEGIN GENERAL CUSTOM NOT FOR COLOR OR BACKGROUND
// .template-editor {
//     text-align: left!important;
// }

.hljs {
  padding: 0;
  border: none;
  transition: border ease 1s;
}

.hljs-ln td {
  padding: 0;
  text-align: left;
}
/* for block of code */
td.hljs-ln-code {
  padding-left: 10px !important;
}

.template-editor {
  height: calc(100vh - 360px) !important;
  text-align: left;
}

.right_icon_toggle {
  section.content {
    margin-right: 0px;
    width: calc(100% - 230px);
  }
}

.left_icon_toggle {
  .left_icon_toggle_btn {
    i::before {
      content: '\f2ee';
    }
  }

  // .sidebar {
  // 	width: 250px;
  // 	left: -250px;
  // }

  section.content {
    margin-left: 0px !important;
    width: calc(100% - 210px);
  }

  .pql_accordion {
    margin-left: 0px;
    width: calc(100% - 200px);
  }

  &.right_icon_toggle {
    section.content {
      width: calc(100%);
    }

    .pql_accordion {
      width: calc(100%);
    }
  }
}

//END GENERAL CUSTOM NOT FOR COLOR OR BACKGROUND

// .right-sidebar-container {
//     transition: all 0.5s;
//     position: fixed !important;
//     top: 101px;
//     right: 0;
//     width: 250px;
//     z-index: 1;
//     background: #2E3435;
// }

// .sidebar-menu {
//     padding-left: 25px;
//     list-style: none;

//         .side-item  {
//             margin-bottom: 15px;

//             &.active {
//                 .side-link:not(.btn) {
//                     background-color: rgba(255,255,255,0.2);
//                     border-radius: .2875rem;
//                     padding: 5px 10px;
//                 }
//             }

//             .side-link {
//                 color: #ffffff;

//                 &.side-link:focus, &.side-link:hover, &.side-link:active {
//                     background-color: rgba(255,255,255,0.2);
//                     border-radius: .2875rem;
//                     padding: 5px 10px;
//                     -moz-transition: all 0.25s;
//                     -o-transition: all 0.25s;
//                     -webkit-transition: all 0.25s;
//                     transition: all 0.25s;
//                 }
//             }
//         }
// }

// .theme-dark .dropdown-menu {
//     box-shadow: none;
//     // left: -7px !important;
// }

// .left_icon_toggle{
//     .left_icon_toggle_btn{
//         i::before{
//             content: '\f2ee';
//         }
//     }

//     .sidebar {
//         width: 250px;
//         left: -250px;
//     }

//     section.content{
//         margin-left: 20px;
//     }

//     section.content {
//         margin-left: 0px;
//     }
// }

// .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
//     color: #fff!important;
//     background-color: #117a8b!important;
//     border-color: #10707f!important;
// }

// .nav-pills, .nav-tabs {
//     margin: 0 0 0 0;
// }

// .dropify-wrapper {
//     height: 600px!important;
//     background-image: -webkit-linear-gradient(white 2px, transparent 2px),
//                   -webkit-linear-gradient(0, white 2px, transparent 2px),
//                   -webkit-linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
//                   -webkit-linear-gradient(0, rgba(255,255,255,.3) 1px, transparent 1px);
// background-image: -moz-linear-gradient(white 2px, transparent 2px),
//                   -moz-linear-gradient(0, white 2px, transparent 2px),
//                   -moz-linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
//                   -moz-linear-gradient(0, rgba(255,255,255,.3) 1px, transparent 1px);
// background-image: linear-gradient(white 2px, transparent 2px),
//                   linear-gradient(90deg, white 2px, transparent 2px),
//                   linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
//                   linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px);
// -pie-background: linear-gradient(white 2px, transparent 2px) -2px -2px / 100px,
//                  linear-gradient(90deg, white 2px, transparent 2px) -2px -2px / 100px,
//                  linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px) -1px -1px / 20px,
//                  linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px) -1px -1px / 20px,
//                  #269;
// }

// tambahan eko
// .mat-input-element.mat-form-field-autofill-control.ng-valid[required], .mat-input-element.mat-form-field-autofill-control.ng-valid.required  {
//   border: 1px solid #42A948;
// }
// .mat-input-element.mat-form-field-autofill-control.ng-invalid:not(form)  {
//   border: 1px solid #fd397a;
// }
.pq-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 50%;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pq-trasparent {
  z-index: 999;
  opacity: 0.5;
  background-color: #888888;
}
/* how to use
<div class="placeholder">
    <div class="placeholder-shimmer"></div>
</div>
*/
.placeholder {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  // background-color: #eee;
  border-radius: 5%;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.theme-light-pqs .placeholder-shimmer {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 20%, #ffffff 30%);
  //    background-size: 800px 104px;
  height: 100%;
  position: relative;
}
.theme-light-pqs.theme-dark .placeholder-shimmer {
  background: linear-gradient(to right, #211f1f 10%, #272a2b 20%, #2f2f2f 30%);
}
// begin directed force
:host ::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #49c5b6;
}

:host ::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #49c5b6;
}
//   .mb-0 {
// 	  color: #c7c2c2 !important;
//   }
.form-control-label {
  color: #989b9e !important;
}
//   .mat-focused {
// 	  width: 100% !important;
//   }
.mat-full-width {
  width: 100% !important;
  margin-bottom: 10px !important;
}
g.zoom-controls {
  cursor: pointer !important;
  pointer-events: all !important;
}
g.zoom-controls rect {
  fill: white !important;
  stroke: #596877 !important;
  stroke-width: 1 !important;
}
g.zoom-controls line {
  stroke: #596877 !important;
  stroke-width: 2 !important;
}
title.d3-tip {
  line-height: 1 !important;
  font-weight: bold !important;
  padding: 12px !important;
  background: #001 !important;
  color: #f00 !important;
  border-radius: 2px !important;
  pointer-events: none !important;
}
title.d3-tip span {
  color: #755f70 !important;
}
// end directed force

.theme-dark .card .header-transparent,
.card .header-transparent {
  background: transparent !important;
}
.cdk-overlay-pane {
  z-index: 9999 !important;
}
.tips {
  position: relative;
  display: inline-block;
  text-decoration: none;
  background-color: #383f43;
  color: #ffffff;
}
.tips table tr td {
  background-color: #383f43 !important;
  color: #ffffff !important;
}
.box2 {
  font: 10px sans-serif;
}

.box2 line,
.box2 rect,
.box2 circle {
  stroke: #000 !important;
  stroke-width: 1px !important;
}

.box2 .center {
  stroke-dasharray: 3, 3 !important;
}

.box2 .outlier {
  fill: none !important;
  stroke: #000 !important;
}

.axis {
  font: 12px sans-serif;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000 !important;
  shape-rendering: crispEdges !important;
}

.x.axis path {
  fill: none;
  stroke: #000 !important;
  shape-rendering: crispEdges !important;
}

.box {
  // display: flex;
  align-items: center;
  justify-content: center;
}

.theme-light-pqs .card .header-transparent,
.card .header-transparent {
  background-color: transparent !important;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #2754e6 !important;
  border-color: #1c4be4;
}
.radio-group-choose-colorpicker {
  display: flex !important;
  flex-direction: column !important;
}
// has problem grid stack on light
.theme-light-pqs .grid-stack-item-content {
  color: #2c3e50;
  text-align: center;
  background-color: transparent !important;
}

.theme-light-pqs .navbar-right .button-container {
  // z-index: 9;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 10px;
  // background: #f7f7f7;
  border-bottom: 0.01em solid #d4d4d6;
}
.anchorBL {
  display: none !important;
}
// datepicker
.dtp > .dtp-content > .dtp-date-view > header.dtp-header,
.dtp div.dtp-date,
.dtp div.dtp-time {
  background: #383f43 !important;
}
.ng-select .ng-select-container .ng-value-container .ng-value {
  font-size: 14px;
  // color: #eee !important;
}
.dtp table.dtp-picker-days tr td a.selected {
  background: #2e3435 !important;
}
.mat-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width: 150px !important;
}

.row-active {
  border: 1px solid #18eaea;
}

.modal-content .modal-header button {
  position: absolute;
  right: 0px !important;
  top: -50px !important;
  outline: 0;
}

// .mr-1 {
// 	padding: 2px;
// 	margin: 4px;
// }
.dd-list {
  display: block !important;
  // height: 674px !important;
}

// @media (min-width: 768px) {
// 	.col-md-8 {
// 		flex: 6 0 66.66666667%;
// 		max-width: 74.666667%;
// 	}
// }
.viztype-selector-container {
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  //padding: 5px;
  //border: 1px solid #aaa;
}
img {
  vertical-align: middle;
  border: 0;
}
.viztype-label {
  text-align: center;
  font-size: 12px;
}
.modal-content .modal-header button {
  position: absolute;
  right: 0px !important;
  top: -65px !important;
  outline: 0;
}
.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  min-width: 100%;
  // transform: translateY(200px) !important;
}

.ng-select.custom {
  border: 0px;
  min-height: 0px;
  border-radius: 0;
}
.ng-select.custom .ng-select-container {
  min-height: 0px;
  border-radius: 0;
}
// ng-select.ng-invalid.ng-touched .ng-select-container {
// 	border-color: #dc3545;
// 	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
// }
label.error {
  color: #e60808 !important;
}
input.require-error,
textarea.form-control.require-error,
select.require-error {
  border: 1px solid #e60808 !important;
}
input.error,
input.error:focus {
  border: 1px solid #e60808;
}
// ccolor chema
.color-scheme-container {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.color-scheme-container li {
  flex-basis: 9px;
  height: 10px;
  margin: 9px 1px;
}
.Select,
.Select div,
.Select input,
.Select span {
  box-sizing: border-box;
}
button.right {
  float: right;
}
#download {
  display: none !important;
}

.dataTables_wrapper.no-footer {
  overflow: auto !important;
}
.dataTables_scroll > .dataTables_scrollHead {
  overflow: none !important;
  position: relative;
  border: 0px;
  width: 100%;
}
.dataTables_scroll > .dataTables_scrollBody {
  position: relative;
  overflow: none !important;
  height: 305px;
  width: 100%;
}

.mat-standard-chip {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 7px 12px;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 16px !important;
  height: 1px;
}
// .mat-icon {
// 	padding-top: 1px !important;
// 	padding-right: 10px;
// }
.mat-standard-chip .mat-chip-remove,
.mat-standard-chip .mat-chip-trailing-icon {
  margin-left: 5px !important;
  margin-right: -7px !important;
}

.grid-stack {
  background: inherit;
}

.grid-stack-item-content {
  color: #2c3e50;
  text-align: center;
  background-color: #383f43;
}

.dash-grid {
  border: 1px dashed #808080;
  padding: 5px;
}

.dialog-toastr-panel {
  position: absolute !important;
  top: 40px;
  right: 10px;
}