﻿/* Google Maps */
.gmap {
    width: 100%;
    height: 400px;
}

/* jVector Map */
.jvector-map {
    width: 100%;
    height: 370px;
    
}
