@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin box-shadow($shadowinfo) {
    -webkit-box-shadow: $shadowinfo;
    -moz-box-shadow: $shadowinfo;
    -ms-box-shadow: $shadowinfo;
    box-shadow: $shadowinfo;
}

@mixin transform($transform) {
    -moz-transform: $transform;
    -ms-transform: $transform;
    -o-transform: $transform;
    -webkit-transform: $transform;
    transform: $transform;
}

@mixin transition($transition) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin three-dots-overflow() {
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}