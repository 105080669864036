/*
======================
PAQUES Custom CSS Page
======================
*/

/* Body
-----*/

body {
  background-color: transparent;
  color: #2E3536;
  overflow: hidden;
}

::-webkit-scrollbar {
  // width: 7px;
  width: calc(1.3vh);
  height: 7px;
}
::-webkit-scrollbar-track {
  background-color: inherit;
  border-radius: inherit;
}
::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 25px;
}
::-webkit-scrollbar-corner {
  display: none;
}

* {
  scrollbar-color: #aaaa transparent;
  scrollbar-width: thin;
}

/* Intro JS
----------*/
.theme-light-pqs {
  .introjs-helperNumberLayer {
    display: none;
  }
  .introjs-helperLayer {
    background: white;
    opacity: 0.2 !important;
  }

  &.theme-dark {
    .introjs-button:hover {
      box-shadow: none !important;
    }
    .introjs-tooltip {
      background: #2e3435 !important;
    }
    .introjs-arrow.top {
      border-bottom-color: #2e3435;
    }
    .introjs-arrow.left {
      border-right-color: #2e3435;
    }
    .introjs-arrow.right {
      border-left-color: #2e3435;
    }
    .introjs-tooltip-header {
      display: none !important;
    }
    .introjs-tooltipbuttons > .introjs-skipbutton {
      background: transparent;
      float: left;
      color: white;
      text-shadow: none;
      border: none;
    }
    .introjs-prevbutton {
      background: #383f43 !important;
      color: white !important;
      text-shadow: none;
      border: none;
      border-right: 1px solid #2e3435;
    }
    .introjs-nextbutton {
      background: #383f43 !important;
      color: white !important;
      text-shadow: none;
      border: none;
    }
  }
}

/* Font Size
----------*/

.theme-light-pqs,
.theme-light-pqs.theme-dark {
  .f-5 {
    font-size: 5px !important;
  }
  .f-8 {
    font-size: 8px !important;
  }
  .f-10 {
    font-size: 10px !important;
  }
  .f-11 {
    font-size: 11px !important;
  }
  .f-12 {
    font-size: 12px !important;
  }
  .f-14 {
    font-size: 14px !important;
  }
  .f-15 {
    font-size: 15px !important;
  }
  .f-16 {
    font-size: 16px !important;
  }
  .f-18 {
    font-size: 18px !important;
  }
  .f-20 {
    font-size: 20px !important;
  }
  .f-22 {
    font-size: 22px !important;
  }
  .f-24 {
    font-size: 24px !important;
  }
  .f-28 {
    font-size: 28px !important;
  }
  .f-32 {
    font-size: 32px !important;
  }
  .f-36 {
    font-size: 36px !important;
  }
  .f-42 {
    font-size: 42px !important;
  }
  .f-55 {
    font-size: 55px !important;
  }
  .f-90 {
    font-size: 90px !important;
  }
}

/* Background Color
---------------- */

.theme-light-pqs,
.theme-light-pqs.theme-dark {
  .bg-white {
    background-color: #ffffff !important;
  }
  .bg-cyan {
    background-color: #38aebf !important;
  }
  .bg-orange {
    background-color: #ff8105 !important;
  }
  .bg-blue {
    background-color: #3585dc !important ;
  }
  .bg-pqs-dark {
    background-color: #2e3435;
  }
  .bg-pqs-tosca {
    background-color: #1f93a4 !important;
  }
  .bg-pqs-red {
    background-color: #e3516a !important;
  }
  .bg-pqs-green {
    background-color: #15ac8d !important;
  }
  .bg-pqs-blue {
    background-color: #3f87c9 !important;
  }
  .bg-pqs-purple {
    background-color: #70509a !important;
  }
  .bg-pqs-brown {
    background-color: #ac6d22 !important;
  }
  .bg-pastel-green {
    background-color: #e1eed8 !important;
  }
}

/* Text Color
---------- */

.theme-light-pqs,
.theme-light-pqs.theme-dark {
  .text-red {
    color: #e73f5b !important;
  }
  .text-yellow {
    color: #d7c100 !important;
  }
  .text-cyan {
    color: #5cc5cd !important;
  }
  .text-orange {
    color: #ff8105 !important;
  }
  .text-white {
    color: #ffffff !important;
  }
  .text-blue {
    color: #46b6fe !important;
  }
  .text-grey {
    color: #888888 !important;
  }
  .text-yellow {
    color: #fec600 !important;
  }
  .text-red {
    color: #ed4b57 !important;
  }
  .text-light-yellow {
    color: #faf504 !important;
  }
  .text-pastel-green {
    color: #e1eed8 !important;
  }
  .text-deep-green {
    color: #376c14 !important;
  }
}

/* Border
------ */

.theme-light-pqs,
.theme-light-pqs.theme-dark {
  .border-b-10 {
    border-bottom: 10px solid;
  }
  .border-l-10 {
    border-left: 10px solid;
  }
  .border-t-7 {
    border-top: 7px solid;
  }
  .border-twitter {
    border-color: #55acee !important;
  }
  .border-facebook {
    border-color: #0063e0 !important;
  }
  .border-instagram {
    border-color: #e4405f !important;
  }
  .border-media {
    border-color: #e2640d !important;
  }
  .border-red {
    border-color: #FF7E6B !important;
  }
  .border-yellow {
    border-color: #EDAE49 !important;
  }
  .border-cyan {
    border-color: #5cc5cd !important;
  }
  .border-green {
    border-color: #91AC86 !important;
  }
  .border-blue {
    border-color: #A0CED9 !important;
  }
  .border-purple {
    border-color: #977390 !important;
  }
  .border-orange {
    border-color: #FF934F !important;
  }
}

/* Margin
------- */

.theme-light-pqs,
.theme-light-pqs.theme-dark {
  .ml-0 {
    margin-left: 0 !important;
  }

  .ml-1 {
    margin-left: 0.25rem !important;
  }

  .ml-2 {
    margin-left: 0.5rem !important;
  }

  .ml-3 {
    margin-left: 1rem !important;
  }

  .ml-4 {
    margin-left: 1.5rem !important;
  }

  .ml-5 {
    margin-left: 3rem !important;
  }

  .mr-0 {
    margin-right: 0 !important;
  }

  .mr-1 {
    margin-right: 0.25rem !important;
  }

  .mr-2 {
    margin-right: 0.5rem !important;
  }

  .mr-3 {
    margin-right: 1rem !important;
  }

  .mr-4 {
    margin-right: 1.5rem !important;
  }

  .mr-5 {
    margin-right: 3rem !important;
  }
}

/* Padding
------- */

.theme-light-pqs,
.theme-light-pqs.theme-dark {
  .pl-0 {
    padding-left: 0 !important;
  }

  .pl-1 {
    padding-left: 0.25rem !important;
  }

  .pl-2 {
    padding-left: 0.5rem !important;
  }

  .pl-3 {
    padding-left: 1rem !important;
  }

  .pl-4 {
    padding-left: 1.5rem !important;
  }

  .pl-5 {
    padding-left: 3rem !important;
  }

  .pr-0 {
    padding-right: 0 !important;
  }

  .pr-1 {
    padding-right: 0.25rem !important;
  }

  .pr-2 {
    padding-right: 0.5rem !important;
  }

  .pr-3 {
    padding-right: 1rem !important;
  }

  .pr-4 {
    padding-right: 1.5rem !important;
  }

  .pr-5 {
    padding-right: 3rem !important;
  }
}

/* Text Alignment
-------------- */

.theme-light-pqs,
.theme-light-pqs.theme-dark {
  .text-left {
    text-align: left !important;
  }

  .text-right {
    text-align: right !important;
  }
}

/* Paques Loading Animation
------------------------ */

.theme-light-pqs {
  @keyframes paques-spin {
    0%,
    100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    }
  }

  @-webkit-keyframes paques-spin {
    0%,
    100% {
      -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      -webkit-transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(1800deg);
      -webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      -webkit-transform: rotateY(3600deg);
    }
  }

  &.theme-dark {
    .kt-splash-screen {
      background-color: #f2f3f8 !important;
      color: #2E3536;

      .paques-spin {
        width: 75px;
        line-height: 1.5;
        animation-name: paques-spin;
        -webkit-animation-name: paques-spin;
        animation: paques-spin 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        -webkit-animation: paques-spin 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
    }
  }
}

/* Workspace
--------- */

.theme-light-pqs {
  color: $t_dark_font;
  section {
    &.content {
      @include border-radius(0px);
      border: none;
      margin: 0px 200px 0px 230px;
      height: calc(100vh - 85px);
      top: 85px;
      background: #e1e4e6;
      overflow: overlay;
      transition: none !important;

      &.fitArea {
        top: 40px;
        height: calc(100vh - 40px) !important;
      }

      &.content-full {
        margin: 0px;
        width: 100%;
        // height: calc(100vh - 0px);

        &.content-full-max {
          height: calc(100vh);
          top: 0px;
          padding-top: 50px !important;

          &.pt-5 {
            padding-top: 25px !important;
          }
        }

        &.content-query {
          position: fixed;
          // width: 200%;
          // height: 200%;
          padding: 0px;
        }
      }

      &.content-dp {
        margin: 0px;
      }

      h3 {
        margin-bottom: 20px;
      }

      .top-0 {
        top: 0px;
      }

      .p100 {
        padding: 100px 0;
      }

      .border-transparent {
        border: 2px solid transparent;
      }
    }

    &.content-full-page {
      width: 100%;
      min-height: 100vh;
      padding: 25px;
      background-color: #181a1b;
      max-height: 100%;
    }

    &.content-pamedi {
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      -ms-border-radius: 0px;
      border-radius: 0px;
      border: none;
      // top: 35px;
      margin: 48px 5px 0 230px;
      padding: 0;
    }
  }
  a:not(.btn) {
    color: #212529; //#009ba7; //74ade2;
    text-decoration: none;
    font-size: 12px;
  }

  .pivot-container {
    height: 100%;

    .dataTables_filter {
      margin-bottom: 10px;
    }

    .dataTables_wrapper {
      height: 100%;

      .table-pivot {
        width: 100% !important;
        height: calc(100% - 80px);
        overflow: auto;
        display: block;
        margin-bottom: 10px;
      }
    }
  }

  #workspace {
    .card {
      margin-bottom: 0px;

      > .body {
        min-height: calc(100vh - 15rem);
        max-height: 100%;
        padding: 2% !important;

        .demo-chart {
          height: calc(100vh - 18rem);

          svg path {
            fill: transparent;
          }

          &.h-85 {
            height: calc(100vh - 23rem) !important;
          }
        }

        pq-bignumber-async {
          .demo-chart {
            margin-top: -35px;
          }
        }
      }
    }
  }

  #rowDashboard {
    pq-bignumber-async {
      .demo-chart {
        margin-top: -35px;
      }
    }

    .wrapTableView {
      overflow: hidden;
      max-height: calc(100% - 30px) !important;
    }

    .scrolling-table {
      overflow: auto;
      height: calc(100% - 80px) !important;

      .mat-table {
        max-height: 100% !important;
      }
    }

    .scrolling-table-comparison {
      overflow: auto;
      height: calc(100% - 40px) !important;

      .mat-table {
        max-height: 100% !important;
        margin-bottom: 0 !important;
      }
    }

    [pq-basic-chart-async] {
      height: 100%;
    }

    pq-tableview-async,
    pq-pivot-async,
    pq-filterbox-async {
      .card {
        > .body {
          overflow: auto;
        }
      }
    }

    .demo-chart {
      height: 100% !important;
      // height: calc(100% - 50px) !important;

      svg path {
        fill: transparent;
      }
    }

    &.dash-grid {
      padding: 0px;
    }

    &.grid-stack-static {
      .grid-stack-item-content {
        .card {
          .body {
            .demo-chart {
              &.h-85 {
                height: calc(100% - 43px) !important;
              }

              &.h-85-big {
                height: calc(100% - 30px) !important;
              }
            }
          }
        }
      }
    }

    .grid-stack-item-content {
      border-radius: 0.5rem;
      overflow: unset;
      text-align: left;
      background-color: transparent;
      // top: 10px;
      left: 5px;
      right: 5px;
      height: 100%;

      .card {
        margin-bottom: 0px;
        height: inherit;
        display: block;

        .header {
          // min-height: 42px;
          > h2 {
            text-align: left;
            display: flex;
            text-align: left;
            display: flex;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .loader {
            margin-right: 5px;
          }
        }

        > .body {
          height: calc(100% - 40px) !important;
          // height: calc(100% - 33px)!important;
          overflow-y: unset !important;
          // display: flex;

          &.ch-full {
            height: 75vh;
          }
          &.ch-half {
            height: 50vh;
          }
          &.ch-1-3 {
            height: 25vh;
          }
          &.roundeds {
            height: calc(100% - 2px) !important;
          }
        }

        .demo-chart {
          height: 100% !important;
        }
      }
    }

    .grid-stack-item {
      .ui-resizable-se,
      .ui-resizable-sw {
        bottom: 5px;
      }
    }
  }

  &.theme-dark {
    section {
      &.content,
      &.content-full {
        background: #181a1b;
      }
    }
  }

  // Menu Builder: Content Menu - Menu List
  .cdk-drag-placeholder {
    display: none;
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);

    background: #fff;
    opacity: 0.3;
  }

  .drop-inside {
    border: 1px dashed #aaa;
  }

  .drop-before {
    border-top: 1px dashed #aaa;
  }

  .drop-after {
    border-bottom: 1px dashed #aaa;
  }

  .node-item {
    display: block;
    position: relative;

    .node-item-wrapper {
      position: relative;
      flex-direction: row;
      display: flex;
      align-items: center;

      a,
      .img-menu {
        width: 20px;
        height: 20px;
        text-align: center;
        align-self: center;
      }

      img {
        vertical-align: baseline;
      }

      .node-title {
        margin: 5px;
        background: transparent;
        border: 2px solid #d4d4d6;
        border-color: rgba(128, 128, 128, 0.2);
        cursor: pointer;
        width: 100%;
        padding: 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;

        &:hover,
        &.active {
          background: rgba(128, 128, 128, 0.1);
          color: #009ba7;
          font-weight: 500;
          border: 2px solid #5cc5cd;
        }

        .item-notes {
          margin-left: 4px;
          margin-right: 10px;
          font-size: 12px;
          word-break: break-word;
        }
      }
    }

    .node-children {
      position: relative;
      transition: height 0.2s;
      margin-left: 22px;
    }
  }

  .leftbar-app {
    .node-title {
      width: 80%;
      color: #8c8c8c;
      padding: 5px 0 5px 10px;
      align-items: center;
      cursor: pointer;
      margin-right: 0;

      &:active {
        background: rgba(128, 128, 128, 0.1);
        color: #009ba7;
      }
    }
    .node-item {
      width: 100%;
    }
    .node-children {
      width: 100%;
    }
    .mat-icon-button {
      margin-left: auto;
      width: 14%;
      align-self: center;
    }
    .is-active {
      color: #5cc5cd !important;
      background: rgba(128, 128, 128, 0.1) !important;
    }
  }

  &.theme-dark {
    .node-item {
      .node-item-wrapper {
        .node-title {
          &:hover,
          &.active {
            color: #5cc5cd;
          }
        }
      }
    }
    a:not(.btn) {
      color: #d8d8d8; //#009ba7; //74ade2;
      text-decoration: none;
      font-size: 12px;
    }
  }
}

/* Sidebar
------- */

.theme-light-pqs {
  .sidebar {
    top: 85px;
    background: #ffffff;
    border-right: 1px solid #d4d4d6;
    width: 230px;
    z-index: 11;

    .menu {
      .list {
        // height: calc(100% - 95px) !important;
        // margin: 0px;
        // a {
        //     font-size: 10px;
        // }
        .panel-group {
          .panel {
            .panel-heading {
              .side-title {
                font-size: 10px;
                line-height: 1.1em;
              }
              a > span {
                font-size: 8px !important;
              }
            }
          }
          .panel-primary {
            .panel-title {
              font-size: 10px;
            }
          }
        }
        // .panel-content {
        //     color: #8e8e8e;
        // }
        .dp-sidebar {
          max-height: calc(90% - 65px) !important;
        }
        .dv-sidebar {
          max-height: calc(98% - 65px) !important;
        }
        button.btn-delete {
          background-color: transparent;
          background-repeat: no-repeat;
          border: none;
          font-size: 17px;

          &.active {
            color: #8c8c8c;
          }
        }

        a {
          i {
            transform: unset;
            color: #8c8c8c;
          }

          &:hover {
            i {
              transform: unset;
              color: #5cc5cd;
            }
          }
        }
      }
      .slimScrollDiv {
        height: auto !important;
      }
    }
  }

  .sidebar-style-2 {
    top: 53px;
    width: 210px;
    z-index: 11;
    border-radius: 10px;
    margin-top: 105px;
    margin-left: 5px;
    height: calc(100vh - 0px);
    position: fixed;
    background: #f7f7f7;

    .sidebar-list-menu {
      background: #e1e4e6;
      padding: 15px;
      border-bottom: 1px solid #cacaca;
    }
  }

  .navbar-right {
    transition: all 0.5s;
    font-family: 'Gilroy', sans-serif;
    width: 200px;
    height: calc(100vh - 85px);
    position: fixed;
    top: 85px;
    right: 0;
    // left: auto;
    box-shadow: none;
    z-index: 11;
    display: inline-block;
    background: #ffffff;
    border-left: 1px solid #d4d4d6;
    // overflow: auto;

    .menu {
      .list {
        height: calc(100% - 80px) !important;
      }
    }

    .button-container {
      z-index: 9;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      padding: 10px;
      background: #f7f7f7;
      border-bottom: 0.01em solid #d4d4d6;
      text-align: center;

      .mat-raised-button {
        box-shadow: none;
      }
    }
  }

  .dq-sc {
    max-height: calc(100vh - 15.25rem);
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 300px;
  }

  .dp-h93v {
    height: calc(100vh - 130px);
    overflow-y: auto;
  }

  .dv-sc {
    max-height: calc(100vh - 15.5rem);
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 300px;

    &.dvc-sc {
      max-height: calc(100vh - 12.9rem);
    }
  }

  // .right-sidebar-container {
  //     transition: all 0.5s;
  //     position: fixed !important;
  //     right: 0;
  //     margin: 0;
  //     width: 250px;
  //     z-index: 1;
  //     background: #ffffff;
  //     border-left: 1px solid #d4d4d6;
  // }

  &.theme-dark {
    .sidebar {
      background: #2e3435;
      border-right: 1px solid #181a1b;

      .slimScrollDiv {
        height: auto !important;
      }
    }

    .sidebar-style-2 {
      background: #282e2f;

      .sidebar-list-menu {
        background: #181a1b;
        border-bottom: 1px solid #393838;
      }
    }

    .navbar-right {
      background: #2e3435;
      border-left: 1px solid #181a1b;

      .button-container {
        background: #202627;
        border-bottom: 0.01em solid #181a1b;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .sidebar {
    left: inherit;
  }
}

/* Hide Menu
--------- */

.theme-light-pqs {
  .hide-menu {
    display: inline-block;
    position: fixed;
    bottom: 85px;
    margin-left: 229px;
    transition: all 0.5s;
    border: none;
    background: none;
    padding: unset;

    .btn-toggle {
      // width: 20px;
      // height: 50px;
      width: 15px;
      height: 40px;
      border-radius: 50%;
      position: relative;
      padding: 10px 5px;
      text-align: center;
      vertical-align: middle;
      display: inline-block;
      border-width: 2px;
      font-weight: 400;
      font-size: 0.8171em;
      // margin: 6px 1px;
      border: none;
      cursor: pointer !important;

      &.btn-toggle-menu {
        background: transparent;
        border-color: transparent;

        &:after {
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          // transform: rotate(45deg);
          // background: #FFFFFF;
          // border-radius: 0px 4px 4px 0px;
          // border: 1px solid #d4d4d6;

          // border-top: 10px solid transparent;
          // border-bottom: 10px solid transparent;
          // border-left: 10px solid white;

          background: white;
          transform: perspective(2vw) rotateY(30deg);
          border: 1px solid #d4d4d6;
          border-left: 1px solid #ffffff;
        }
        &:hover,
        &:focus {
          background: transparent !important;
          border-color: transparent !important;
        }
      }
    }

    .btn-menu {
      line-height: 21px;
      font-family: 'Material-Design-Iconic-Font';
      display: inline;
      font-size: 15px;
      margin: 20px 0px 20px -2px;

      &::after {
        content: '\f2fa';
      }
    }

    &.rtl-sidebar {
      margin-left: -15px;
      transform: rotateY(180deg);

      // .btn-toggle {
      //     &.btn-toggle-menu {
      //         &:after {
      //             border-radius: 4px 0px 0px 4px;
      //         }
      //     }
      // }

      // .btn-menu {
      //     &::after {
      //         content:'\f2fb';
      //     }
      // }
    }
  }

  &.theme-dark {
    .hide-menu {
      .btn-toggle {
        &.btn-toggle-menu {
          &:after {
            background: #2e3435;
            border: 1px solid #202627;
            border-left: 1px solid #2e3435;
          }
        }
      }
    }
  }
}

/* Sidebar Toggle
-------------- */

.theme-light-pqs {
  &.ls-toggle-menu {
    .sidebar {
      width: 230px;
      left: -230px;
    }
    section.content {
      margin-left: 0px;
    }

    .pql_accordion {
      margin-left: 0px;
      width: calc(100% - 200px);
    }

    &.right_icon_toggle {
      .pql_accordion {
        width: calc(100%);
      }
    }

    &.ls-closed {
      .pql_accordion {
        width: calc(100%);
      }
    }
  }

  &.right_icon_toggle {
    // .navbar-right{
    //     width: 200px;
    //     right: -200px;
    // }
    section.content {
      margin-right: 0px;
    }

    .pql_accordion {
      margin-right: 0px;
      width: calc(100% - 230px);
    }

    .footer_accordion {
      width: calc(100%) !important;
    }
  }

  &.ls-closed {
    .navbar-right {
      width: 0;
    }
    section.content {
      margin: 0px;
    }
  }

  &.left_icon_toggle {
    .sidebar {
      width: 230px;
      left: -230px;

      .hide-menu {
        .btn-menu {
          &::after {
            content: '\f2fb';
          }
        }
      }
    }

    .footer_accordion {
      margin-left: 0px;
      width: calc(100% - 200px);
    }
  }

  &.right_icon_toggle {
    .navbar-right {
      width: 200px;
      right: -200px;

      .hide-menu {
        .btn-menu {
          &::after {
            content: '\f2fb';
          }
        }
      }
    }
  }
}

/* Accordion
--------- */

.theme-light-pqs {
  .pql_accordion {
    position: fixed;
    width: calc(100% - 430px);
    bottom: 40px;
    margin-left: 230px;
    margin-right: 200px;
    transition: all 0.5s;
    z-index: 10;
    // right: 0;
    // left: 0;
    // border: 1px solid #d4d4d6;
    // border-bottom: none;
    // border-top: none;

    .pql_body {
      height: calc(100vh - 130px);
      // text-align: center;

      .body {
        border-radius: 0.35rem !important;
      }
    }
  }

  .pql_accordion,
  .footer_accordion {
    .h4 {
      border-top: 1px solid #e1e4e6;
    }
  }

  .footer_accordion {
    position: fixed;
    width: calc(100% - 430px);
    bottom: 0;
    z-index: 12;
    background: #f7f7f7;
    margin-left: 230px;
    margin-right: 200px;
    transition: all 0.5s;
    z-index: 10;

    .result_body {
      overflow: overlay;
      height: calc(100vh - 125px);
      transition: all 0.5s;

      .mat-table {
        .ellipsis_cell > ul li p > span {
          padding: 0;
        }
        .mat-header-cell,
        .mat-cell {
          padding: 3px;
          border: none;
          border-left: 0.01px solid #dfdfdf;
        }
      }
    }

    .panel .shade-handle {
      + .panel-heading + .panel-collapse {
        .result_body {
          height: calc(100vh - 130px);

          .mat-cell {
            cursor: text;
          }

          .data-null {
            color: #8c8c8c !important;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  &.theme-dark {
    .pql_body {
      .card-pqs .body {
        background-color: #2e3435;
      }
    }
    .pql_accordion,
    .footer_accordion {
      background: #202627;
      .h4 {
        border-top: 1px solid #212627;
      }
    }

    .footer_accordion {
      .result_body {
        .mat-table {
          .mat-header-cell,
          .mat-cell {
            border-left: 0.01px solid #3e4448;
          }
        }
      }
    }
  }
}

/* Sidebar Menu
------------ */

.theme-light-pqs {
  .right-sidebar {
    height: calc(100vh - 40px);
    background: #ffffff;
    left: -300px;
    width: 225px;

    &.open {
      left: 20px;
    }
  }

  .sidebar-menu {
    padding-left: 20px;
    list-style: none;

    .side-item {
      margin-bottom: 15px;
      padding-left: 10px;

      &.active {
        .side-link:not(.btn) {
          background-color: #e1e4e6;
          border-radius: 0.2875rem;
          padding: 5px 10px;
        }
      }

      .side-link {
        color: #222222;
        padding: 5px 10px;
        font-size: 12px;

        &.disabled {
          color: #8c8c8c !important;
          pointer-events: none;
        }

        &.side-link:focus,
        &.side-link:hover,
        &.side-link:active {
          background-color: #e1e4e6;
          border-radius: 0.2875rem;
          padding: 5px 10px;
          -moz-transition: all 0.25s;
          -o-transition: all 0.25s;
          -webkit-transition: all 0.25s;
          transition: all 0.25s;
        }

        &.side-link:disabled {
          opacity: 0.65;
        }
      }
    }
  }

  &.theme-dark {
    .right-sidebar {
      background: #383f43;
    }

    .sidebar-menu {
      .side-item {
        &.active {
          .side-link:not(.btn) {
            background-color: rgba(222, 222, 222, 0.5);
          }
        }

        .side-link {
          color: #ffffff;

          &.side-link:focus,
          &.side-link:hover,
          &.side-link:active {
            background-color: rgba(222, 222, 222, 0.5);
          }
        }
      }
    }
  }
}

/* Top Navigation
-------------- */

.theme-light-pqs {
  .navbar {
    background: #ffffff;
    min-height: 40px;
    transition: all 0.5s;
    z-index: 13;

    .icons {
      color: black;
    }

    a {
      &:not(.btn):not(.dropdown-item) {
        color: #222222;
        font-weight: 500;
      }
    }

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-nav {
      flex-direction: row;

      .nav-item {
        .nav-link.notif-visible {
          .zmdi-notifications-active {
            vertical-align: 8.5px;
          }
          .badge {
            vertical-align: 15px;
          }
        }
      }

      .nav-link:not(.btn) {
        font-size: 0.55em;
        padding: 0.45rem 0.6rem;
        line-height: 0.7142em;

        &.disabled {
          color: #808080;
        }
      }

      .nav-item.active .nav-link:not(.btn),
      .nav-item .nav-link:not(.btn):focus,
      .nav-item .nav-link:not(.btn):hover,
      .nav-item .nav-link:not(.btn):active {
        background-color: $opacity-gray-5;
      }

      .dropdown-menu {
        position: absolute;
      }

      &.menu-nav {
        display: none;

        a.nav-link {
          &:hover,
          &.active {
            background-color: rgba(222, 222, 222, 0.5);
            border-radius: 6px;
          }
        }
      }
    }

    .navbar-brand {
      padding-top: 0.35rem;
      padding-bottom: 0.35rem;
    }
  }

  kt-menu-horizontal {
    width: calc(100% - 65px);
  }

  &.theme-dark {
    .navbar {
      background: #383f43;

      a {
        &:not(.btn):not(.dropdown-item) {
          color: #ffffff;
        }
      }

      .icons {
        color: #999;
      }

      .navbar-brand {
        i {
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 560px) {
  .theme-light-pqs {
    .navbar {
      .navbar-collapse {
        .navbar-nav {
          display: none;

          .dropdown-menu {
            position: fixed !important;
            left: 5%;
            width: 90%;
          }

          &.menu-nav,
          &.right-nav {
            display: flex;
          }
        }
      }
    }
  }
}

/* Sub Top Navigation
------------------ */

.theme-light-pqs {
  .sub-top-nav {
    position: fixed;
    top: 42px;
    width: 100%;
    z-index: 12;
    margin: 0;
    background: #eceff1;
    border-bottom: 1px solid #d4d4d6;
    transition: all 0.5s;

    .nav {
      padding: 0px;
      flex-wrap: unset;
      .nav-item {
        width: calc(100% - 175px);
        margin-top: 7px;
        .nav-link {
          padding: 4px;
        }
      }
    }
  }

  &.theme-dark {
    .sub-top-nav {
      background: #272e2f;
      // border-bottom: 1px solid #181a1b;
    }
  }
}

/* Button
------ */

.theme-light-pqs {
  .btn {
    line-height: 1.3em;
    color: #222222;
    // background: #eeeeee;

    i {
      vertical-align: text-bottom;
    }

    &.disabled {
      pointer-events: initial;
    }
  }

  .btn:hover {
    // color: #5CC5CD;
    box-shadow: none;
  }

  .btn-outline {
    border: none;
    // line-height: 20px;
    color: #212529;
    background: #d4d4d6;
    // height: 1.5rem !important;
    // height: 24px!important;
    // height: 20px !important;
    min-height: 26px !important;
    min-width: 1.5rem !important;
    border-radius: 0.2875rem;
    font-size: 10px;

    &:hover {
      color: #ffffff !important;
      background-color: #117a8b !important;
    }

    &.disabled:hover,
    &.disabled:hover > i {
      color: #212529 !important;
      background: #d4d4d6 !important;
    }

    &.btn-outline-mini {
      line-height: 27px;
      // width: 1.5rem;
      width: 24px;
      height: 20px;

      span,
      i {
        line-height: 25px;
      }

      &:focus i {
        color: #212529;
      }
    }

    &.btn-outline-maxi {
      // height: 2.75rem !important;
      // width: 2.75rem;
      height: 40px !important;
      width: 40px;

      img {
        filter: brightness(0.65);
        width: 28px;
      }

      &:focus,
      &:active,
      &:hover {
        border: none;
        color: #ffffff;
        background: #117a8b;
        transition: 0.3s all;

        img {
          filter: none;
        }
      }

      &.active {
        img {
          filter: none;
        }
      }
    }

    &.btn-qcommand {
      height: 30px !important;
      width: 30px;
      padding: 7px;

      img {
        filter: invert(1);
      }

      &:hover {
        img {
          filter: none;
          transition: 0.3s all;
        }
      }
    }

    &.btn-lg {
      height: 2.5rem !important;
      font-size: 12px;
      line-height: 9px;
    }

    &.bg-transparent:hover,
    &.bg-transparent:not(:disabled):not(.disabled):active {
      color: #5cc5cd !important;
      background-color: transparent !important;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #fff;
      background-color: #117a8b !important;
      border-color: #117a8b;
    }

    &.btn-toggle-table {
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: #fff;
        background-color: #8c8c8c !important;
        border-color: #8c8c8c;
      }
      &:hover {
        background-color: #8c8c8c !important;
      }
    }
  }

  .btn-green {
    color: #ffffff;
    background-color: #84cfea !important;
  }

  .btn-danger {
    background-color: #ee2558;
  }

  .btn-light {
    background-color: #f4f4f4;
  }

  .btn.btn-outline-view {
    color: #00d4ae !important;
    border-color: #00d4ae !important;
    background: #fff !important;
    border: 2px solid !important;
  }

  .btn-icon {
    line-height: 0.8rem;
  }

  .btn-dark {
    background-color: #f4f4f4;

    &:hover,
    &:focus,
    &:active {
      background-color: #299da4 !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }

    &.active {
      background-color: #299da4 !important;
    }
  }

  .btn-color-palette,
  .btn-color-palette:active {
    white-space: break-spaces;
    text-align: left;
    margin: 0px;
    background-color: #eceff1 !important;
    border: 1px solid #e2e5ec !important;
    padding: 8px 20px 5px 10px;

    &:after {
      position: absolute;
      right: 10px;
      top: 38%;
    }

    span {
      margin-bottom: 3px;
    }

    + .dropdown-menu {
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: 5px;
      min-width: 0px;

      .dropdown-item {
        flex-flow: wrap;
        padding: 7px 13px 5px;
        margin-bottom: 0px;

        span {
          margin-bottom: 3px;
        }
      }
    }
  }

  .signin-other-account {
    background: transparent;
    border-style: solid;
    border-color: #ff9f0e;
    font-size: 12px;
    color: #ff9f0e !important;
    border-radius: 0px;
    width: 100%;
  }

  .signin-btn {
    background: linear-gradient(180deg, #ff9f0e 0%, #f77b00 100%) !important;
    font-size: 12px;
    border-radius: 0px;
    width: 100%;
  }

  .signin-btn-app {
    background: #f40155 !important;
    color: white;
    font-size: 12px;
    border-radius: 0px;
    width: 100%;
  }

  .dtp-btn-now {
    background: #eeeeee !important;
  }

  .dtp-btn-clear {
    background: #eeeeee !important;
  }

  .dtp-btn-cancel {
    background: #eeeeee !important;
  }

  .dtp-btn-ok {
    background: #eeeeee !important;
  }

  .btn-result {
    position: absolute;
    top: 7px;
    margin: 0px;
    z-index: 1;
    min-height: 20px !important;

    &.close-result {
      right: 15px;
    }

    &.resize-result {
      right: 70px;
    }
  }

  .link {
    color: #222222 !important;
    font-weight: 500;
  }

  &.theme-dark {
    .btn {
      color: #ffffff;
      // background: #383f43;
    }

    .btn-outline {
      color: #d8d8d8 !important;
      background-color: #383f43;

      &:hover {
        color: #ffffff !important;
        background-color: #117a8b !important;
      }

      &.disabled:hover,
      &.disabled:hover > i {
        color: #d8d8d8 !important;
        background-color: #383f43 !important;
      }

      &.btn-outline-mini {
        &:focus i {
          color: #d8d8d8;
        }
      }

      &.btn-qcommand {
        img {
          filter: none;
        }
      }

      &.btn-outline-maxi {
        img {
          filter: none;
        }
      }

      &.bg-transparent:hover {
        color: #5cc5cd !important;
        background-color: transparent !important;
      }

      &.btn-toggle-table {
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          color: #fff;
          background-color: #272e2f !important;
          border-color: #272e2f;
        }
        &:hover {
          background-color: #272e2f !important;
        }
      }
    }

    .btn-green {
      color: #ffffff;
      background-color: #84cfea;
    }

    .btn-dark {
      background-color: #626567;
    }

    .btn-color-palette,
    .btn-color-palette:active {
      background-color: #383f43 !important;
      border: none !important;
    }

    .btn-danger {
      background-color: #ee2558;
    }

    .dtp-btn-now {
      background: #383f43 !important;
    }

    .dtp-btn-clear {
      background: #383f43 !important;
    }

    .dtp-btn-cancel {
      background: #383f43 !important;
    }

    .dtp-btn-ok {
      background: #383f43 !important;
    }

    .link {
      color: #ffffff !important;
    }
  }
}

/* Input
----- */

.theme-light-pqs {
  .form-group {
    // margin-bottom: 0.8rem;
    margin-bottom: 12px;
  }

  .form-control,
  .form-control:focus {
    box-shadow: none;
    border: 1px solid #ced4da;
    background-color: #eceff1;
    outline: none;
    padding: 0.4rem 0.6rem;
    font-size: 10px;
    min-height: 27px;
    -webkit-appearance: menulist !important;
    -moz-appearance: menulist !important;
    -ms-appearance: menulist !important;
    -o-appearance: menulist !important;
    appearance: menulist !important;
    // cursor: pointer;
  }

  .form-bottomline,
  .form-bottomline:focus {
    border-bottom: 0.01em solid #999 !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-color: transparent !important;
    border-radius: 0px;

    // &.group-bottomline {
    //     border-bottom-left-radius: 0px;
    //     border-bottom-right-radius: 0.25rem;
    // }

    &[disabled],
    &[readonly] {
      + .group-bottomline {
        background-color: #c6c6c8 !important;
        opacity: 0.5;
        margin-left: 0px;
      }
    }
  }

  .input-group {
    i {
      color: #74788d !important;
    }
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #c6c6c8 !important;
    // opacity: 0.5;
    color: #181a1b !important;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(0.55);
    height: 15px;
    width: 15px;
  }

  &.theme-dark {
    .form-control,
    .input-group .input-group-append .btn-outline-secondary,
    .input-group .input-group-prepend .btn-outline-secondary,
    .input-group-text,
    .bootstrap-select > .dropdown-toggle {
      color: #fff;
    }

    .pad-23 {
      padding: 0 0 0 23px;
    }

    .form-control,
    .form-control:focus {
      // box-shadow: none;
      border: transparent;
      color: #bec0c1 !important;
      background-color: #272e2f;
    }

    .form-control:disabled,
    .form-control[readonly] {
      background-color: #464646 !important;
      // opacity: 0.5;
      color: #bec0c1 !important;
    }

    .bg-logservice {
      background-color: #272e2f !important;
    }

    .select2:focus {
      > option {
        border: 0px;
      }
      > option:checked {
        background-color: #8c8c8c !important;
      }
      > option:hover {
        background-color: #8c8c8c !important;
      }
    }

    .show-tick {
      > option:checked {
        background-color: #8c8c8c !important;
      }
      > option:hover {
        background-color: #000 !important;
      }
    }
  }
}

/* Input Group
----------- */

.theme-light-pqs {
  .input-group {
    .showPassword {
      float: right;
      margin-right: 20px;
      margin-top: -20px;
      position: relative;
      z-index: 2;
    }
    .showPassword2 {
      float: right;
      margin-right: 10px;
      margin-top: -24px;
      position: relative;
      z-index: 2;
    }
  }
  
  &.theme-dark {
    .input-group-text {
      color: #8c8c8c;
      padding: 0 10px;
    }
  }
}

/* Dropify
------- */

.theme-light-pqs {
  .dropify-wrapper {
    border-color: transparent;
    border-radius: 5px;
  }
}

/* Big Icon
------- */

.theme-light-pqs {
  &.theme-dark {
    .bg-big_icon {
      background: #434848 !important;
    }

    .big_icon {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .big_icon:before {
      transform: none;
      top: 40px;
      left: 25px;
      font-size: 37px;
      color: #f3c404;
    }
  }
}

/* Tooltip
------- */

.theme-light-pqs {
  .tooltip {
    font-family: Roboto, sans-serif;

    .tooltip-inner {
      min-width: 0px;
    }

    .arrow {
      margin: 1px;
    }
  }
}

/* Tags Input
---------- */

.theme-light-pqs {
  .bootstrap-tagsinput {
    border: 1px solid #ced4da;
    background-color: #eceff1;
    border-radius: 0.25rem;
    border: 1px solid #3e3e3e !important;
    width: 100%;

    input {
      color: #fff;
    }
  }

  &.theme-dark {
    .bootstrap-tagsinput {
      border: transparent;
      background: #394142;
    }
  }
}

/* Badge
----- */

.theme-light-pqs {
  .badge {
    font-weight: 500;
    margin-bottom: 0px;
  }

  .wrapLabel {
    white-space: nowrap;
    width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  .bootstrap-tagsinput {
    padding-top: 3px;
    .tag {
      margin-right: 1px;
    }
  }
}

/* Label
----- */

.theme-light-pqs {
  label {
    font-size: 12px;
    margin-bottom: 0.2rem;
  }

  .label-white-space {
    width: 120px;
    white-space: normal;
    line-height: 12px;
  }
}

/* Icon
---- */

.theme-light-pqs {
  .zmdi {
    font-size: 14px;
  }
  .btn i {
    font-size: 12px !important;
  }

  i.icon-group {
    font-size: 16px !important;

    + i {
      font-size: 10px !important;
      background: #212529;
      color: #d4d4d6;
      border-radius: 20px;
      position: absolute;
      top: 13px;
      left: 32px;
      padding: 0px 2px 0px 2px;
      border: 0.5px solid #d4d4d6;
      transition: all 0.2s;
    }
  }

  button:hover {
    i.icon-group {
      + i {
        background: #ffffff;
        color: #117a8b;
        border: 0.5px solid #117a8b;
        transition: all 0.2s;
      }
    }
  }

  &.theme-dark {
    i.icon-group {
      + i {
        background: #d8d8d8;
        color: #383f43;
        border: 0.5px solid #383f43;
      }
    }
  }
}

/* Chart
----- */

.theme-light-pqs {
  .c3_chart {
    height: 15rem;
  }

  .big-number {
    // height: 100%;
    // position: relative;
    line-height: normal;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    overflow-wrap: anywhere;
  }
}

/* Checkbox
-------- */

.theme-light-pqs {
  .checkbox {
    &.simple {
      width: 16px;
      height: 16px;
    }

    label {
      padding-left: 25px;
      line-height: 17px;
    }
  }
  .checkbox label::before,
  .checkbox label::after {
    width: 16px;
    height: 16px;
    border-color: #808080;
  }
}

/* Radio
-------- */

.theme-light-pqs {
  .radio {
    margin-bottom: 0px;

    label::before,
    label::after {
      width: 16px;
      height: 16px;
      top: 5px;
    }
    input[type='radio']:checked + label::after {
      left: 9px;
    }

    input[type='radio'].checked {
      + label::before {
        background: #5cc5cd;
        color: #ffffff;
        border-color: #5cc5cd;
      }

      + label::after {
        background-color: #ffffff;
        border-color: #ffffff;
        width: 4px;
        height: 4px;
        top: 11px;
        left: 9px;
        opacity: 1;
      }
    }
  }

  .radio-img {
    [type='radio'] {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }

    img {
      background-color: #f4f4f4;
      padding: 10px 30px;
      border-radius: 4px;
    }

    [type='radio'] + img {
      cursor: pointer;
    }
    [type='radio']:checked + img {
      background-color: #299da4 !important;
    }
  }

  &.theme-dark {
    .radio-img {
      img {
        background-color: #626567;
      }
    }
  }
}

/* Scrollbar
--------- */

.theme-light-pqs {
  ::-webkit-scrollbar {
    // width: 7px;
    width: calc(1.3vh);
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: inherit;
    border-radius: inherit;
  }
  ::-webkit-scrollbar-thumb {
    background: #d4d4d6;
    border-radius: 25px;
  }
  ::-webkit-scrollbar-corner {
    display: none;
  }

  &.theme-dark {
    ::-webkit-scrollbar-thumb {
      background: #4c555a;
    }
  }
}

/* DataTables
---------- */

.theme-light-pqs {
  .dataTables_wrapper .dataTables_filter input {
    border-radius: 4px;
    height: 25px;
    border: 1px solid #e2e5ec;
    background-color: #eceff1;
    padding-left: 5px;
  }
  .dataTables_wrapper select {
    border-radius: 4px;
    height: 25px;
    border-bottom: none;
    margin: 0 3px;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current,
  .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    border: 1px solid transparent;
    color: #222222;
    background: #d3d3d5;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover,
  .dataTables_wrapper .dataTables_paginate .paginate_button:active {
    // border: 1px solid transparent;
    box-shadow: none;
    background: transparent;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
    background: transparent !important;
    // border: 1px solid transparent;
    color: transparent !important;
  }
  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: none;
    margin-bottom: 5px;
  }

  // table.dataTable thead .sorting_asc,
  // table.dataTable thead .sorting_desc {
  //     filter: brightness(0.5);
  // }

  .pagination .page-item.active > .page-link {
    background: #5cc5cd;
  }

  [type='Search']::-webkit-search-cancel-button {
    filter: grayscale(100%);
  }

  .table-responsive {
    height: 250px;

    table.dataTable {
      width: 90%;
      border: 1px solid #505050;

      th:first-child {
        border-right: 1px solid #505050;
        width: 5%;
      }

      td:first-child {
        border-right: 1px solid #505050;

        span {
          padding: 0 0 0 8px;
        }
      }
    }
  }

  table.dataTable.sentiment-table {
    thead {
      th {
        vertical-align: middle;

        &:before,
        &:after {
          bottom: 40% !important;
          // display: none;
        }
      }
    }
  }

  &.theme-dark {
    .dataTables_wrapper .dataTables_filter input {
      background-color: #394142;
      border: none;
    }
    .dataTables_wrapper select {
      color: #ffffff;
      background-color: #394142;
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button.current,
    .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
      color: #ffffff !important;
      background: #394142;
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button:hover,
    .dataTables_wrapper .dataTables_paginate .paginate_button:active {
      background: transparent;
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
    .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
      color: #4e4d4d !important;
    }
    table.dataTable thead .sorting {
      background: url(../../images/sort_both.png) !important;
      background-repeat: no-repeat !important;
      background-position: center right !important;
    }
    table.dataTable thead .sorting_desc {
      background: url(../../images/sort_desc.png) !important;
      background-repeat: no-repeat !important;
      background-position: center right !important;
    }
    table.dataTable thead .sorting_asc {
      background: url(../../images/sort_asc.png) !important;
      background-repeat: no-repeat !important;
      background-position: center right !important;
    }

    [type='search']::-webkit-search-cancel-button {
      filter: brightness(200%) contrast(200%) grayscale(100%) brightness(150%);
      cursor: pointer;
    }

    table.dataTable.sentiment-table {
      thead {
        background: rgba(20, 24, 25, 0.35) !important;
        th {
          background: inherit;
        }
      }
    }
  }
}

/* Datetime Picker
--------------- */

.theme-light-pqs {
  .dtp > .dtp-content > .dtp-date-view > header.dtp-header,
  .dtp div.dtp-date,
  .dtp div.dtp-time {
    background: #383f43;
  }
  .dtp-picker-days {
    background-color: #ffffff !important;
    tr {
      > th {
        background-color: #eeeeee !important;
      }
    }
  }
  .dtp table.dtp-picker-days tr td a.selected {
    background: #2e3435;
  }
  .dtp table.dtp-picker-days tr > td > a,
  .dtp .dtp-picker-time > a {
    padding: 0.5em 0.6em 0.5em 0.5em;
  }
}


@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

/* Setting Page
------------ */

.theme-light-pqs {
  .setting-box {
    color: #777;
    text-align: center;
    min-height: 44px;
  }
  .w_icon {
    height: 25px;
    width: 25px;
    line-height: 24px;
    i {
      font-size: 14px;
    }
    &::after {
      height: 37px;
      width: 37px;
    }

    &.red {
      background: rgba(255, 72, 72, 0.294);
      color: #ff0000;
    }
    &.red::after {
      background: rgba(255, 72, 72, 0.2);
    }
  }
  .cpu-list {
    position: relative;
    overflow: auto;
    width: auto;
    max-height: 975px;
  }

  &.theme-dark {
    .setting-box {
      h5 { color: #2E3536; }
    }
  }
}

/* Placeholder
----------- */

.theme-light-pqs {
  ::placeholder {
    color: #6c757d !important;
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #6c757d;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #6c757d;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: #6c757d;
  }
}

/* Light Gallery
------------- */

.theme-light-pqs {
  .lg-backdrop,
  .lg-outer {
    z-index: 1055 !important;
  }
}

/* Popover
------- */

.theme-light-pqs {
  .popover-query-commands {
    position: absolute;
    left: -130px;
  }
  .popover {
    font-family: Roboto, sans-serif;
    max-width: 190px;

    &.pds-popover {
      left: -6px;
      top: 14px;

      .mat-menu-content:not(:empty) {
        padding: 0;
      }
    }

    &.popover-qcommand {
      min-width: 325px;
      max-width: 500px !important;
      border-radius: 4px;
      cursor: pointer;
    }

    .arrow {
      margin: 1px;
    }

    .popover-header {
      color: #383838;
      font-weight: 900;
      margin-top: 0px;
    }

    .popover-body {
      padding-top: 0rem;
      color: #666666;

      ul {
        padding-left: 1.25rem;
      }

      &.error {
        color: #e73f5b;
      }
    }
  }

  &.show-popover {
    .cdk-overlay-container,
    .cdk-global-overlay-wrapper {
      height: 0;
      width: 0;
    }
  }
}

/* Carousel
-------- */

.theme-light-pqs {
  .carousel-indicators li {
    background-color: #636b6f;
  }

  .carousel .carousel-inner {
    box-shadow: none !important;
  }
}

/* Doc List
-------- */

.theme-light-pqs {
  .doc-list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    overflow: auto;
    display: contents;

    &.detail-preview {
      max-height: calc(100vh - 250px);
      display: block;
    }
    li {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      padding-right: 15px;
      border-bottom: 1px solid #eee;

      h6 {
        text-transform: none;
        margin-bottom: 0.25rem;
        color: #808080;
      }
      p {
        color: #6c757d;
        font-size: 12px;
        margin-bottom: 0px;
      }

      &:hover {
        background: rgba(128, 128, 128, 0.2);
        cursor: pointer;

        h6,
        p {
          color: #5cc5cd;
        }
      }

      &.grid-v {
        width: 30vh;
      }
    }
  }

  .doc-content {
    height: calc(100vh - 255px);
    width: 100%;
  }

  &.theme-dark {
    .doc-list {
      li {
        border-color: #3e3e3e;
      }
    }
  }
}

/* Breadcrumb
---------- */

.theme-light-pqs {
  .breadcrumb {
    padding: 0.5rem 1rem;
    margin-bottom: 0px;
    background-color: transparent;
    font-size: 12px;
  }
}

/* Range Sliders
------------- */

.theme-light-pqs {
  .irs-demo {
    .irs {
      margin-top: 0px;
    }
  }
  .irs--flat {
    .irs-from,
    .irs-to,
    .irs-single {
      background-color: #5cc5cd;

      &:before {
        border-top-color: #5cc5cd;
      }
    }

    .irs-bar {
      background-color: #5cc5cd;
    }

    .irs-handle {
      > i:first-child {
        background-color: #5cc5cd;
      }

      &.state_hover > i:first-child,
      &:hover > i:first-child {
        background-color: #117a8b;
      }
    }
  }
}

/* Switch
------------- */

.theme-light-pqs {
  .switch {
    visibility: hidden;
    position: absolute;
    margin-left: -9999px;

    + label {
      display: block;
      position: relative;
      cursor: pointer;
      outline: none;
      user-select: none;
    }
  }

  .switch--shadow {
    + label {
      padding: 2px;
      width: 35px;
      height: 19px;
      border-radius: 60px;
      background-color: #dedede;

      &:before,
      &:after {
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        content: '';
      }

      &:before {
        right: 1px;
        background-color: #dedede;
        border-radius: 60px;
        transition: background 0.4s;
      }

      &:after {
        width: 17px;
        height: 17px;
        background-color: #eceff1;
        border-radius: 100%;
        transition: all 0.4s;
      }
    }

    &:checked + label {
      background-color: #0498b1 !important;

      &:before {
        background-color: #0498b1 !important;
      }

      &:after {
        transform: translateX(15px);
        background-color: #5cc5cd !important;
      }
    }
  }

  &.theme-dark {
    .switch--shadow {
      + label {

        &:before {
          background-color: #DEDEDE;
        }

        &:after {
          background-color: #ECEFF1;
        }
      }

      &:checked + label {
        background-color: #FFC082 !important;
  
        &:before {
          background-color: #FFC082 !important;
        }
  
        &:after {
          transform: translateX(15px);
          background-color: #FD7B03 !important;
        }
      }
    }
  }
}

/* Material Custom CSS
---------------------- */
//Global
.theme-light-pqs {
  .ng-select {
    outline: none !important;

    .ng-placeholder {
      font-size: 11px !important;
      margin-left: 8px;
    }
  }
  .ng-select.ng-select-opened > .ng-select-container,
  .ng-select.ng-select-focused .ng-select-container {
    box-shadow: none;
    border: 1px solid #ced4da !important;
  }
  .ng-select.ng-select-multiple {
    .ng-select-container .ng-value-container {
      margin-left: 3px;
      font-size: 11px !important;
      white-space: inherit;
      overflow: unset;
      text-overflow: unset;
      // padding: 3px 4px;

      .ng-placeholder {
        // margin-top: -8px;
        margin-left: 3px;
        top: 54%;
        font-size: 11px !important;
      }
    }
  }

  .ng-select.ng-select-single {
    .ng-select-container .ng-value-container {
      .ng-value {
        white-space: normal;
        overflow: unset;
        text-overflow: unset;
        padding: 4px 8px;
      }
    }
  }

  .ng-select.custom {
    min-height: 27px !important;
    border-radius: 4px;
    outline: none;

    .ng-placeholder {
      font-size: 11px !important;
      margin-left: 8px;
    }
  }
  .ng-select.custom .ng-select-container {
    min-height: 27px !important;
    border: 1px solid #ced4da;
    background-color: #eceff1;
    border-radius: 4px;
  }

  &.theme-dark {
    .ng-select .ng-select-container {
     height: auto;
    }
    .ng-select.ng-select-opened > .ng-select-container,
    .ng-select.ng-select-focused .ng-select-container {
      border: 1px solid #ced4da !important;
    }

    .ng-select.custom {
      border: transparent;
      color: #bec0c1 !important;
      background-color: #ffffff;
    }
    .ng-select.custom .ng-select-container {
      border: 1px solid #ced4da;
      color: #bec0c1 !important;
      background-color: #ffffff;
    }
  }
}

// Right click query
.theme-light-pqs {
  .menu-title {
    padding: 0 16px;
    line-height: 30px;
  }
  .mat-menu-item {
    padding: 0 15px;
  }
}

// Treeview container
.theme-light-pqs {
  .treeview-container {
    width: 98%;
    // max-height: calc(100vh - 16.25rem) !important;
    height: calc(100vh - 15rem) !important;
    display: inline-block;
    max-height: none !important;
  }
  .treeview-text {
    display: none;
  }
}

// Treeview list hover
.theme-light-pqs {
  .treeview-container {
    .treeview-item {
      + * {
        .treeview-item {
          margin-left: 2rem;
        }
      }

      .row-item {
        padding-left: 10px;
        border-radius: 2px;
        width: 175px;
        cursor: pointer;

        .fa {
          width: 0.4rem !important;
        }

        .mat-icon {
          padding-top: 3px !important;
          padding-right: 10px;
        }

        img {
          position: relative;
          // top: -5px;
          // margin-left: 1px;
          filter: brightness(0.6);
          height: 20px;
          align-self: center;
        }

        .form-check-label {
          cursor: pointer;
          color: #2E3536;
        }

        &:hover {
          img {
            filter: brightness(3);
          }
        }
      }
    }
  }

  &.theme-dark {
    .treeview-container {
      .treeview-item {
        .row-item {
          .form-check-label {
            color: #2E3536;
          }
        }
        .form-check-label:hover {
          color: #ffffff;
        }
        .row-item:hover {
          color: #ffffff !important;
          background-color: #FD7B03 !important;
        }
        .row-item-hilite {
          color: #ffffff;
          background-color: #FD7B03;
          animation: orange-dissolve 3s ease-out forwards;
        }
      }
    }
  }
}

// Treeview right click
.theme-light-pqs {
  .tree-rc-btn {
    height: 28px !important;
    color: #222222;

    img {
      filter: invert(1);
      padding-bottom: 3px;
      margin-right: 5px;
    }
  }

  .disabled-gray {
    filter: invert(27%) sepia(75%) saturate(500%) hue-rotate(346deg) brightness(75%) contrast(3%);
  }

  .dropdown-divider {
    border-top: 0.5px solid #8c8c8c;
  }

  &.theme-dark {
    .tree-rc-btn {
      color: #394142;

      img {
        filter: invert(1);
      }
    }
  }
}

//Chip/ Tagging
.theme-light-pqs {
  .ng-select .ng-select-container {
    padding-right: 4px;
    padding-left: 4px;
  }
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    border-radius: 5px !important;
    font-size: 11px;
  }
  .ng-select .ng-select-container .ng-value-container .ng-value {
    font-size: 11px;
  }

  .ng-select .ng-clear-wrapper {
    height: 18px;
    right: 3px;

    .ng-clear {
      left: 4px;
    }
  }

  &.theme-dark {
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
      background: #fafafa;
    }
    .ng-select .ng-select-container .ng-value-container .ng-value {
      color: #2E3536;
    }
    .ng-select .ng-clear-wrapper {
      background: #aeaeae;
    }
    .ng-dropdown-panel .ng-dropdown-footer {
      background-color: $t_dark1;
      border-color: $t_dark_border;
    }
  }
}

// Mat Chip
.theme-light-pqs {
  select-chip {
    .mat-form-field-wrapper {
      padding-bottom: 12px;

      .mat-form-field-infix {
        padding: 0;
        border-top: none;

        .mat-chip-list-wrapper {
          border: 1px solid #d9d9d9;
          background: #edeff1;
          border-radius: 4px;
          margin: auto;

          .mat-chip {
            font-size: 10px;

            &.mat-standard-chip {
              background-color: #fafafa;
              border: 1px solid #e8e8e8;
              color: #212529;
              border-radius: 4px;
              padding: 10px 8px;
              font-weight: 400;
              margin: 4px 2px 0px 4px;

              .mat-chip-remove {
                color: #212529;
                opacity: 1;
                font-size: 12px !important;

                &.mat-icon {
                  width: 16px;
                  height: 12px;
                }
              }
            }
          }
        }

        .mat-form-field-label-wrapper {
          left: 0.6rem;

          .mat-form-field-label {
            top: 1.58125em;
            font-size: 10px !important;
            color: #6c757d !important;
          }
        }
      }

      .mat-form-field-underline {
        display: none;
      }
    }
  }

  &.theme-dark {
    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      .mat-form-field-infix {
        .mat-chip-list-wrapper {
          border: 1px solid #ced4da;
          background: #ffffff;

          .mat-chip {
            &.mat-standard-chip {
              background-color: #888888;
              color: #ffffff;
              border-color: #888888;

              .mat-chip-remove {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}

// Mat Option
.theme-light-pqs {
  .mat-autocomplete-panel {
    background: #ffffff;
    border-radius: 4px !important;
    margin: 4px 0;

    .mat-option {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      line-height: 20px;
      height: 20px;
      padding: 0 10px;

      .mat-option-text {
        color: #222222 !important;
        font-size: 10px;
      }

      &:hover:not(.mat-option-disabled),
      &:focus:not(.mat-option-disabled) {
        background: #127a8b;

        .mat-option-text {
          color: #ffffff !important;
        }
      }

      &.mat-selected:not(.mat-active):not(:hover) {
        background: transparent;
      }
    }
  }

  &.theme-dark {
    .mat-autocomplete-panel {
      background: #ffffff;
      .mat-option {
        .mat-option-text {
          color: #2E3536 !important;
        }
      }
    }
  }
}

//Table
.theme-light-pqs {
  .mat-table-container {
    min-width: 0;
  }

  mat-table.over-inherit,
  .over-inherit {
    overflow: auto;
  }

  mat-table.chartHeight {
    max-height: calc(100vh - 270px);
  }

  mat-table.chartHeightwithFilter {
    max-height: calc(100vh - 300px);
  }

  .mat-paginator {
    background: transparent;

    .mat-paginator-container {
      .mat-paginator-range-actions {
        .btn_custom-paging-container {
          .mat-icon-button.mat-custom-page {
            width: unset;
            height: unset;
            line-height: unset;
            margin: 0 10px;
          }
        }
      }

      .mat-form-field {
        font-size: 14px;
        width: 60px;
      }
    }
  }

  .btmPaginator {
    position: absolute !important;
    bottom: -3px !important;
    right: 0px !important;
    width: 100%;
    overflow-x: auto;
    padding: 0 20px;
  }

  .scrollPaginator .mat-paginator {
    // width: 600px;
    margin-left: auto;
    width: max-content;
  }

  // Ellipsis text for data processing result table
  .ellipsis_cell {
    position: relative;
    padding: 8px 3px !important;
    min-width: 180px !important;
  }
  // .ellipsis_cell > ul li p
  // {
  //     position: relative;
  // }
  // .ellipsis_cell span {
  //   position: absolute;
  //   padding: 0 20px 0 30px;
  //   left: 5px;
  //   right: 0;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }
  .ellipsis_cell > ul li p > span {
    // position: absolute;
    // left: 0;
    // right: 15px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // line-height: 1em;
    position: absolute;
    // padding: 0 20px 0 30px;
    padding: 0 20px 0 13px;
    left: 5px;
    right: 0;
    // bottom: 0;
    // top: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  // .ellipsis_cell > ul li p:after
  // {
  //     content: attr(title);
  //     width: 100px;
  //     overflow: hidden;
  //     height: 0;
  //     display: block;
  // }
  .elipstxt {
    position: absolute;
    left: 5px;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  // Ellipsis text for data visualization table & table comparison
  .ellipsistxt {
    position: relative;
  }
  .ellipsistxt span {
    position: absolute;
    padding: 0 20px 0 5px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mat-table-formula {
    max-height: calc(100vh - 350px);
  }

  [pq-tablecomparison-async] {
    .mat-table {
      .mat-row .mat-cell:nth-child(1n + 2) {
        justify-content: flex-end;
      }
    }
  }

  .mat-column-Chart_Positions {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 15% !important;
    width: 10% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;

    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .mat-column-Target_Application {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 18% !important;
    width: 10% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;

    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .fixed-table {
    width: 100%;
    white-space: nowrap;
    table-layout: fixed;
  }
}

//Alert
.theme-light-pqs {
  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-position: right calc(0.375em + 0.9rem) center;
  }
}

//Spinner
.theme-light-pqs {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #117a8b;
  }

  &.theme-dark {
    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: #5cc5cd;
    }
  }
}

//Monaco Editor
.theme-light-pqs {
  .template-editor {
    height: calc(100vh - 120px) !important;
    text-align: left;

    &.template-editor-agg-for {
      height: calc(100vh - 470px) !important;
    }

    .editor-container {
      border: 1px solid #eee;
    }
  }
  // &.theme-dark {
  //    .monaco-editor,
  //    .margin,
  //    .view-lines,
  //    .minimap-decorations-layer,
  //    .minimap,
  //    .slider-mouseover {
  //        background-color: #343A40;
  //    }

  //    .monaco-editor {
  //        .line-numbers {color: #cccccc6b;}
  //        .mtk1 {color: white;}
  //        .margin-view-overlays {border-right: 1px solid #cccccc1c;}
  //        .view-lines {padding-left: 1em;}
  //    }
  //  }
  .monaco-editor {
    // background-color: #ECEFF1;

    // .margin, .monaco-editor-background, .inputarea.ime-input {
    //     background-color: #ECEFF1;
    // }

    .mtk1 {
      color: #3a3a3a;
    }
    .mtk19 {
      color: #c700c7;
    }
    .line-numbers {
      color: #808080;
    }
    // .current-line ~ .line-numbers {
    //     color: #545454;
    // }

    // .minimap-decorations-layer {
    //     background: rgba(0,0,0,.3);
    // }
    // .decorationsOverviewRuler {
    //     filter: brightness(0.7);
    // }

    .action-item.disabled {
      border-bottom-color: #808080 !important;

      .action-label.icon.separator.disabled {
        display: none !important;
      }
    }
    .action-item.focused {
      .action-menu-item {
        color: rgb(255, 255, 255) !important;
        background-color: #808080 !important;
      }
    }
  }

  &.theme-dark {
    .template-editor {
      .editor-container {
        border: 1px solid #eee;
      }
    }

    .monaco-editor {
      background-color: #FFFFFF;

      .margin,
      .monaco-editor-background,
      .monaco-editor .inputarea.ime-input {
        background-color: #FFFFFF;
      }

      .mtk1 {
        color: #394142;
      }
      .mtk6 {
        color: #569cd6;
      }
      .mtk7 {
        color: #b5cea8;
      }
      .mtk8 {
        color: #608b4e;
      }
      .mtk19 {
        color: #ff00ff;
      }
      .cursor {
        background-color: #aeafad;
        border-color: #eee;
        color: #515052;
      }
      .view-overlays .current-line {
        border: 2px solid #eee;
      }
      .selectionHighlight {
        background-color: #ffffff;
      }
      .focused .selectionHighlight {
        background-color: #ffffff;
      }
      .current-line ~ .line-numbers {
        color: #c6c6c6;
      }
      .lines-content .cigr {
        box-shadow: 1px 0 0 0 #404040 inset;
      }

      .selected-text {
        background-color: #e2e2e2;
      }
      .focused .selected-text {
        background-color: #e2e2e2;
      }

      // .minimap canvas {
      //   filter: invert(0.88) hue-rotate(125deg);
      // }
      .minimap-shadow-visible,
      .scroll-decoration {
        box-shadow: #000000 -6px 0 6px -6px inset;
      }
      .minimap-slider,
      .minimap-slider .minimap-slider-horizontal {
        background: rgba(121, 121, 121, 0.2);
      }
      // .minimap-decorations-layer {
      //     background: rgba(0,0,0,.7);
      // }

      // .decorationsOverviewRuler {
      //   filter: brightness(0.3);
      //   // filter: invert(1);
      // }
      .monaco-scrollable-element {
        box-shadow: rgb(0, 0, 0) 0px 2px 4px !important;

        .scrollbar > .slider.active {
          background: hsla(0, 0%, 74.9%, 0.4);
        }
      }

      .monaco-action-bar,
      .action-menu-item {
        color: rgb(240, 240, 240) !important;
        background-color: rgb(60, 60, 60) !important;
      }

      .monaco-quick-open-widget {
        color: rgb(204, 204, 204) !important;
        background-color: rgb(30, 30, 30) !important;
        box-shadow: rgb(0, 0, 0) 0px 5px 8px !important;

        .monaco-scrollable-element {
          box-shadow: none !important;
        }

        .monaco-inputbox {
          background-color: rgb(60, 60, 60) !important;
          color: rgb(204, 204, 204) !important;

          .input {
            background-color: rgb(60, 60, 60) !important;
            color: rgb(204, 204, 204) !important;
          }
        }
        .monaco-tree-row:hover {
          background-color: #2a2d2e !important;
        }
        .monaco-tree-row.focused {
          background-color: rgba(173, 214, 255, 0.15) !important;
        }
      }

      .find-widget {
        background-color: #252526;
        box-shadow: 0 2px 8px #000000;

        .monaco-sash {
          background-color: #454545;
        }
      }
      .monaco-highlighted-label {
        color: #000000;
      }
    }
  }
}

//Radio Material
.theme-light-pqs {
  .mat-radio-container {
    width: 16px;
    height: 16px;
  }
  .mat-radio-outer-circle,
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #5cc5cd;
    width: 16px;
    height: 16px;
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple) {
    background-color: #5cc5cd;
    width: 16px;
    height: 16px;
  }
}

//Button Material
.theme-light-pqs {
  .mat-button.mat-primary {
    color: #5cc5cd;
  }
  .mat-button.mat-primary .mat-button-focus-overlay {
    background-color: #5cc5cd;
  }
  .mat-icon-button {
    width: auto;
    height: 28px;
    line-height: 28px;
    margin-left: 6px;

    > .mat-button-ripple.mat-ripple,
    .mat-button-focus-overlay {
      display: none;
    }
  }
}

//Progress Material
.theme-light-pqs {
  .mat-progress-bar-background,
  .mat-progress-bar-buffer {
    background-color: #5cc5cd;
  }
  .mat-progress-bar-fill::after {
    background-color: #117a8b;
  }
  .progress .progress-bar {
    background-color: #117a8b !important;
    border-radius: 8px;
    font-size: 10px;
    height: 12px;
    font-weight: 500;
  }

  &.theme-dark {
    .progress .progress-bar {
      background-color: #5cc5cd !important;
    }
  }
}

//Background Overlay
.theme-light-pqs {
  .bg-overlay {
    background-color: rgba(255, 255, 255, 0.45);
  }

  .accordion-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 13;
    cursor: pointer;
  }

  .mat-dialog-container {
    color: #8c8c8c;
    background: transparent;
    box-shadow: none;
    // text-align: -webkit-center;

    .modal .modal-dialog .modal-body {
      &.scroll-inside {
        overflow-y: auto;
        max-height: calc(100vh - 15rem);
        margin: 20px 0px;
      }
    }
  }

  &.theme-dark {
    .bg-overlay {
      background-color: rgba(0, 0, 0, 0.45);
    }
  }
}

//Pagination Table Result
.theme-light-pqs {
  .result-pagination {
    position: fixed;
    left: -1%;
    bottom: 0;
    width: 100%;
    z-index: 11;
    // position: absolute;
    background: #f7f7f7;

    .mat-paginator-container {
      min-height: 40px;
    }
  }

  &.theme-dark {
    .result-pagination {
      background: #F6F6F6;
      color: #394142 !important;
    }
  }
}

//App Notification
.theme-light-pqs {
  &.notif-displayed {
    .navbar-right {
      margin-right: -25%;
    }
  }

  .navbar-right.dropdown-menu,
  .navbar .navbar-nav .nav-item .nav-notif {
    border-radius: 0px;
    overflow: auto;
    max-height: calc(100vh - 114px);
    background-color: #eceff1;
    border-radius: 5px;

    .button-container {
      background: transparent;
      border-bottom: none;
    }

    ul.menu {
      li {
        a {
          background-color: #1f93a42e;

          .icon-circle {
            border-radius: 50px;
            min-width: 36px;
            max-height: 36px;
            background-color: #1f93a4;

            i {
              line-height: normal;
              padding: 11px;
            }
          }

          .menu-info {
            margin-top: 3px;
            h4 {
              font-size: 11px;
              color: #222222;
              font-weight: 600;
              letter-spacing: 0.3px;
            }
            p {
              font-size: 9px;
              color: #1f93a4;
              font-weight: 800;

              i {
                vertical-align: text-bottom;
              }
            }
          }
        }

        &.is_click {
          a {
            background-color: transparent !important;

            .menu-info {
              h4 {
                font-weight: 400;
              }
              p {
                color: #8c8c8c;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  &.theme-dark {
    .navbar-right.dropdown-menu,
    .navbar .navbar-nav .nav-item .nav-notif {
      background-color: #2e3435;

      ul.menu {
        li {
          a {
            // .icon-circle {
            //     background-color: #202627;
            // }

            .menu-info {
              h4 {
                color: #cccccc;
              }
            }
          }
        }
      }
    }
  }
}

//App Footer
.theme-light-pqs {
  .app-footer {
    padding: 6px 0;
    border-top: 1px solid #d4d4d6;
    font-size: 10px;
    line-height: 17px;
    color: #636c72;
    background: #eceff1;
    transition: all 0.5s;
    width: 100%;
    z-index: 999;
  }

  &.theme-dark {
    .app-footer {
      background: #2e3435;
      border-top: 1px solid #181a1b;
    }
  }
}

//Datepicker Material
.theme-light-pqs {
  .mat-form-field.mat-primary.mat-form-field-type-mat-input.mat-form-field-can-float.mat-form-field-has-label,
  .mat-date {
    &.mat-form-field-appearance-legacy,
    &.mat-form-field-appearance-fill {
      // width: 100%;
      width: 125px;
      margin-right: 10px;
      margin-bottom: 10px;
      // margin-top: -3px;

      .mat-form-field-wrapper {
        padding-bottom: 0px;
        .mat-form-field-flex {
          box-shadow: none;
          background-color: #eceff1;
          outline: none;
          // padding: .3rem .6rem;
          font-size: 10px;
          min-height: 27px;
          padding-top: 0em !important;
          border-radius: 4px;
          border: 1px solid #e2e5ec;
          padding: 0.75em 0.75em 0 0.75em;
          color: #495057;

          .mat-form-field-infix {
            padding-bottom: 0em !important;
            border-top: 0.9em solid transparent;

            .mat-form-field-label-wrapper {
              .mat-form-field-label {
                font-size: 10px;
              }
            }
          }

          svg path {
            fill: #808080;
          }
        }
        .mat-form-field-underline,
        .mat-form-field-underline:before,
        .mat-form-field-ripple {
          height: 0px;
        }
      }

      .mat-form-field-suffix {
        align-self: center;

        .mat-icon-button {
          display: block;
          font-size: inherit;
          width: 1.5em;
          height: 1.5em;
        }
        .mat-datepicker-toggle-default-icon {
          margin: auto;
          display: block;
          width: 1.5em;
          height: 1.5em;
        }
      }

      .mat-form-field-label {
        margin-top: -0.3em;
      }
    }

    &.no-label {
      width: 100%;
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-infix {
            top: -4px;
            padding-top: 0;
            border-top: 1em solid transparent !important;

            .mat-form-field-label-wrapper {
              .mat-form-field-label {
                left: 0;
                top: 5px !important;
              }
            }
          }
        }
      }
      .mat-form-field-subscript-wrapper {
        margin-top: 2.2em;
      }
      .mat-form-field-prefix,
      .mat-form-field-suffix {
        top: 1px;
      }
    }

    &.mat-form-field-appearance-legacy.mat-form-field-can-float {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-infix {
            border-top: 0.77em solid transparent;
          }
          .mat-form-field-label {
            margin-top: -0.5em;
          }
        }
      }
      &.mat-form-field-should-float {
        .mat-form-field-wrapper {
          .mat-form-field-flex {
            .mat-form-field-label {
              margin-top: 0.3em;
            }
          }
        }
      }
    }
  }

  .mat-datepicker-content {
    .mat-calendar-controls {
      .mat-icon-button {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }

    .mat-calendar-table {
      background: inherit;
      .mat-calendar-table-header {
        th,
        .mat-calendar-table-header-divider {
          background: inherit;
        }
      }
      .mat-calendar-body-selected {
        background-color: #5cc5cd;
      }
    }

    .time-container {
      .table {
        margin: 0px;
        background-color: inherit;
        color: rgba(0, 0, 0, 0.87);

        .tbody {
          tr td {
            border-top: 1px solid #ebedf2;
            vertical-align: middle;
          }

          tr:first-child td {
            border-top: 0px;
          }
        }
      }
    }
  }

  .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
  .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    background-color: rgb(92 197 205 / 30%);
  }

  &.theme-dark {
    .mat-form-field.mat-primary.mat-form-field-type-mat-input.mat-form-field-can-float.mat-form-field-has-label,
    .mat-date {
      &.mat-form-field-appearance-legacy,
      &.mat-form-field-appearance-fill {
        .mat-form-field-wrapper {
          .mat-form-field-flex {
            background-color: #FFFFFF;
            border: 1px solid #ced4da;
            color: #394142;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

//Timepicker Material
.theme-light-pqs,
.theme-light-pqs.theme-dark {
  .ngx-mat-timepicker-table {
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
    margin: auto;

    .mat-icon-button {
      margin-left: 0;
    }
  }

  .mat-datepicker-content {
    .time-container {
      padding: 10px;
    }

    .actions {
      padding: 10px 15px 10px;
      border-top: 1px solid #d8dce6;

      button {
        transition: all 0.15s;

        &:hover {
          background-color: #5cc5cd;
          color: #ffffff;
        }

        .mat-icon {
          height: auto;
          width: auto;
        }
      }
    }
  }
}

//Scrollbar Material
.theme-light-pqs {
  .scroll-host {
    background: #ffffff;
  }

  &.theme-dark {
    .scroll-host {
      background: #f6f1f1;
    }
  }
}

//Hide rocketchat
// .theme-light-pqs {
//     .rocketchat-widget {
//         display: none;
//     }
// }

//Input Material
.theme-light-pqs {
  .mat-form-field-fluid {
    .mat-form-field-flex {
      .mat-form-field-infix {
        padding: 0px;
        border-top: none;
      }
    }
    .mat-form-field-underline {
      display: none;
    }
  }

  .mat-input-element {
    caret-color: #495057;
    min-height: 17px !important;
    width: 95%;
    // padding: 0px 0px 0px 8px;
  }

  mat-form-field[floatLabel='never'] {
    .mat-form-field-label {
      left: 10px;
      top: 20px !important;
      font-size: 10px;
    }

    textarea {
      + .mat-form-field-label-wrapper .mat-form-field-label {
        top: 16px !important;
      }
    }
  }

  &.theme-dark {
    .mat-input-element {
      caret-color: #495057;
      // padding: 0px 0px 0px 8px;
    }
  }
}

//Select Material
.theme-light-pqs {
  .mat-select {
    &.form-control:focus {
      border-color: transparent;
    }
  }
  .mat-select-panel {
    background: #ffffff !important;
  }
  .mat-form-field.mat-focused {
    &.mat-primary .mat-select-arrow {
      color: #808080;
    }
    .mat-form-field-ripple {
      background-color: #5cc5cd;
    }
  }

  &.theme-dark {
    .mat-select-panel {
      background: #ffffff !important;
    }
  }
}

//Dropdown Material
.theme-light-pqs {
  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        color: #222222;
        border-radius: 0 !important;

        .ng-option-label {
          white-space: pre-wrap;
        }

        &.ng-option-child {
          padding-left: 20px;
        }

        &:hover,
        &.ng-option-selected {
          color: #f7f7f7 !important;
          background-color: #127a8b !important;
        }
      }

      .ng-option-disabled {
        color: #808080;
        text-transform: uppercase;
      }
    }
  }

  &.theme-dark {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          color: #f7f7f7;

          &:hover,
          &.ng-option-selected {
            color: #f7f7f7 !important;
            background-color: #FD7B03 !important;
          }
        }
      }
    }
  }
}

// Modal Material
.theme-light-pqs {
  .dialog-common {
    background-color: #ffffff !important;

    .mat-dialog-title {
      font-size: 16px;
      margin-bottom: 30px;
    }

    .mat-dialog-content {
      font-size: 14px;
      overflow-wrap: break-word;
    }

    .mat-dialog-actions {
      button {
        font-weight: 400;
        font-size: 12px;
        min-width: 55px;
        line-height: 27px;
        opacity: 0.5;
        color: #8c8c8c !important;
        background-color: transparent !important;

        &:hover {
          opacity: unset;
          background-color: transparent !important;
        }

        .mat-button-focus-overlay {
          display: none;
        }
      }

      button:last-child {
        opacity: unset;
        color: #212529 !important;
        background-color: #d4d4d6 !important;

        &:hover {
          color: #ffffff !important;
          background-color: #117a8b !important;
          transition: all 0.15s;
        }
      }
    }
  }

  &.theme-dark {
    .dialog-common {
      background-color: #ffffff !important;

      .mat-dialog-actions {
        button:last-child {
          color: #d8d8d8 !important;
          background-color: #ffffff !important;

          &:hover {
            background-color: #117a8b !important;
          }
        }
      }
    }
  }
}

// Toast Material
.theme-light-pqs {
  .toastr-main-wrapper {
    top: unset !important;
    bottom: 80px !important;
    right: 30px !important;
    height: auto !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .toastr-card {
      background-color: #ffffff !important;
      box-shadow: 0px 10px 50px 0px rgb(0 0 0 / 20%);

      .toastr-card-content .toastr-body {
        color: #8c8c8c !important;
      }

      &.info {
        border-color: #5cc5cd !important;
      }
    }
  }

  &.theme-dark {
    .toastr-main-wrapper {
      .toastr-card {
        background-color: #ffffff !important;
        box-shadow: 0px 10px 50px 0px rgb(0 0 0 / 20%);

        &.info {
          border-color: #FD7B03 !important;
        }
      }
    }
  }
}

.theme-light-pqs {
  .cdk-overlay-container {
    .mat-select-panel-wrap {
      z-index: 9999999 !important;
    }
  }
  //Logo Size on Application Builder
  .appBuilderLogo {
    max-width: 100%;
    height: 20px;
    min-height: 30px;
  }
}

@font-face {
    font-family: "Lato-Regular";
	src: url(../../fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url(../../fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Oswald-Regular";
  src: url(../../fonts/Oswald-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(../../fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway-Regular";
  src: url(../../fonts/Raleway-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url(../../fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url(../../fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Impact";
  src: url(../../fonts/Impact.woff) format("woff");
}

@font-face {
  font-family: "Verdana";
  src: url(../../fonts/Verdana.woff) format("woff");
}
//Step Wizard

.theme-light-pqs {
  .sw-theme-arrows {
    border: none;

    &.sw-dark {
      background: transparent;
      color: #8c8c8c;

      > .nav {
        border-bottom: none;
        border-radius: 5px;
        margin-bottom: 10px;

        .nav-link {
          margin-right: 0px;
          margin-left: 0px;
          border-top: 2px solid #000;
          text-align: left;
          padding-left: 80px;
          font-size: 10px;
          text-transform: uppercase;

          img {
            position: absolute;
            top: 10px;
            left: 35px;
            filter: contrast(0);
            top: calc(50% - 17px);
          }

          strong {
            font-size: 16px;
          }

          .arrows {
            top: calc(50% - 33px);
            right: -31px;
            width: 30px;
            height: 62px;
            margin: 1px;
            position: absolute;
            display: block;
            z-index: 3;

            &::before {
              position: absolute;
              content: '';
              border-color: transparent;
              border-style: solid;
              left: 0;
              border-width: 35px 0 35px 23px;
              box-sizing: border-box;
              border-left-color: #e1e4e6;
              top: calc(50% - 34px);
            }

            &::after {
              position: absolute;
              content: '';
              border-color: transparent;
              border-style: solid;
              left: 0;
              border-width: 31px 0 31px 20px;
              box-sizing: border-box;
              border-left-color: #000;
              top: calc(50% - 30px);
            }
          }

          &.inactive {
            color: #808080;
            border-color: #d4d4d6;
            background: #d4d4d6;
            border-top: 2px solid #808080;

            .arrows {
              &::after {
                border-left-color: #d4d4d6;
              }
            }
          }

          &.active {
            color: #222222;
            border-color: #f7f7f7;
            background: #f7f7f7;
            border-top: 2px solid #5cc5cd;

            strong {
              color: #5cc5cd;
            }

            img {
              filter: none;
            }

            .arrows {
              &::after {
                border-left-color: #f7f7f7;
              }
            }
          }

          &.done {
            color: #808080;
            border-color: #f7f7f7;
            background: #f7f7f7;
            border-top: 2px solid #808080;

            .arrows {
              &::after {
                border-left-color: #f7f7f7;
              }
            }
          }

          &::after {
            // border-left-color: #394142;
            content: none;
          }

          &::before {
            // border-left: 30px solid #181A1B;
            // margin-left: 2px;
            content: none;
          }
        }

        .nav-item:first-child .nav-link {
          padding-left: 56px;

          img {
            left: 13px;
          }
        }
      }
    }

    .nav-pills .nav-link,
    .nav-item .nav-link,
    .navbar,
    .nav-tabs .nav-link {
      transition: none !important;
    }
  }

  &.theme-dark {
    .sw-theme-arrows {
      &.sw-dark {
        > .nav {
          .nav-link {
            .arrows {
              &::before {
                border-left-color: #181a1b;
              }
            }

            &.inactive {
              background: #272e2f;

              .arrows {
                &::after {
                  border-left-color: #272e2f;
                }
              }
            }

            &.active {
              color: #ffffff;
              background: #394142;

              .arrows {
                &::after {
                  border-left-color: #394142;
                }
              }
            }

            &.done {
              background: #394142;

              .arrows {
                &::after {
                  border-left-color: #394142;
                }
              }
            }
          }
        }
      }
    }
  }
}

//Multi Select

.theme-light-pqs {
  .ms-container {
    // background: transparent url(../img/switch.png) no-repeat 50% 50%;
    width: auto !important;

    .ms-list {
      border-radius: 0.25rem !important;
      background: #eceff1;
      border: 1px solid #ced4da;
      position: relative;
      height: 200px;
      padding: 0;
      overflow-y: auto;
      margin-top: 10px;
    }

    .ms-selectable,
    .ms-selection {
      background: transparent;
      border-bottom: 1px solid #ced4da;
      padding: 3px 15px 3px 15px;
      cursor: pointer;

      li.ms-elem-selectable,
      li.ms-elem-selection {
        font-size: 10px;
        border-bottom: 1px solid #ced4da;
        color: #212529;
      }

      li.ms-hover,
      li.ms-hover {
        color: #ffffff !important;
        background-color: #117a8b !important;
      }

      a {
        color: #212529;
        font-size: 10px;
      }

      &:hover {
        background-color: #117a8b !important;
        a {
          color: #ffffff !important;
        }
      }
    }
  }

  &.theme-dark {
    .ms-container {
      .ms-list {
        background: #394142;
        border: none;
      }

      .ms-selectable,
      .ms-selection {
        border-bottom: 1px solid #2e3435;

        li.ms-elem-selectable,
        li.ms-elem-selection {
          border-bottom: 1px solid #2e3435;
          color: #d8d8d8;
        }

        a {
          color: #d8d8d8;
        }
        // li.ms-hover, li.ms-hover {
        //     color: #ffffff !important;
        //     background-color: #117a8b !important;
        // }
      }
    }
  }
}

//Select2 Plugin

.theme-light-pqs {
  .select2-container .select2-choice {
    height: unset;
    line-height: unset;
    background-color: transparent;

    .select2-arrow {
      top: -5px;
    }
  }
  .select2-dropdown-open.select2-drop-above .select2-choice,
  .select2-dropdown-open.select2-drop-above .select2-choices {
    border: none;
  }

  .select2-drop {
    background: #eceff1;
    color: #808080;
    font-size: 10px;
  }

  .select2-search input {
    border: 1px solid #808080;
    border-radius: 4px;
    color: #888888;
  }

  .select2-results .select2-no-results,
  .select2-results .select2-searching,
  .select2-results .select2-ajax-error,
  .select2-results .select2-selection-limit {
    background: transparent;
  }

  &.theme-dark {
    .select2-drop {
      background: #394142;
    }
  }
}

//Small Icon

.theme-light-pqs {
  .sm-icon {
    color: #ffffff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background-color: rgba(222, 222, 222, 0.5);
    text-align: center;
    margin-right: 10px;
    display: inline-block;

    label {
      cursor: pointer;
      font-size: 10px;
      display: flex;
      justify-content: center;
      color: #222222;
    }
  }

  &.theme-dark {
    .sm-icon {
      label {
        color: #ffffff;
      }
    }
  }
}

//Dropdown Toggle

.theme-light-pqs {
  .dropdown-toggle {
    &:after {
      color: #212529;
      vertical-align: unset;
    }

    + .dropdown-menu {
      a {
        color: #222222;
        font-size: 12px;
      }
    }

    &:hover {
      &:after {
        color: #d8d8d8;
      }
    }
  }

  .show > .btn-outline.dropdown-toggle {
    color: #ffffff;
    background-color: #117a8b;
  }

  .btn-info + .btn.dropdown-toggle:before {
    display: none;
  }

  &.theme-dark {
    .dropdown-toggle {
      &:after {
        color: #d8d8d8;
      }

      + .dropdown-menu {
        a {
          color: #495057;
        }
      }
    }

    .show > .btn-outline.dropdown-toggle {
      color: #ffffff !important;
      background-color: #FD7B03 !important;
      border: none;
    }
  }
}

//Collapse Group

.theme-light-pqs {
  .collapse-group {
    border-radius: 8px;
    // overflow: hidden;
    margin-top: 10px;

    .collapse-panel {
      // width: 100%;
      // color: #888888;
      margin-bottom: 10px;
      border-bottom: 1px solid #cccccc;
      // overflow: hidden;

      .collapse-check {
        position: absolute;
        opacity: 0;
        z-index: -1;

        &.checked + .collapse-label::after {
          content: '\002D';
        }
        // &:checked ~ .tab-content {
        //     max-height: 100vh;
        //     padding: 10px 0 10px 0;
        // }
      }

      .collapse-label {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        cursor: pointer;
        margin-bottom: 10px;

        &::after {
          content: '\002B';
          width: 1em;
          height: 1em;
          text-align: center;
        }
      }

      // .tab-content {
      //     max-height: 0;
      //     padding: 0 1em;
      // }
    }
  }

  &.theme-dark {
    .collapse-group {
      .collapse-panel {
        border-bottom: 1px solid #383838;
      }
    }
  }
}

//CK Editor
.theme-light-pqs {
  .cke_chrome {
    border: none;
  }

  .cke_top {
    border-bottom: none;
    padding: 10px 10px 5px;
    box-shadow: unset;
    background-image: none;
    background: #eceff1;
  }

  .cke_bottom {
    border-top: none;
    box-shadow: none;
    background: #eceff1;
  }

  .cke_toolgroup {
    border: none;
    border-bottom-color: unset;
    box-shadow: none;
    background-image: none;
    background: #d4d4d6;
  }

  a.cke_button_on {
    background: #117a8b;
    background-image: none;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }

    .cke_button_icon {
      filter: invert(1) brightness(2);
    }

    .cke_button_label {
      color: #ffffff;
    }
  }

  a.cke_button_off,
  a.cke_button_disabled {
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      background-image: none;
      background: #117a8b;

      .cke_button_icon,
      .cke_button_arrow,
      .cke_combo_arrow {
        filter: invert(1) brightness(2);
      }

      .cke_button_label {
        color: #ffffff;
      }
    }
  }

  a.cke_combo_button {
    border: none;
    box-shadow: none;
    background-image: none;
    background: #d4d4d6;
  }

  .cke_combo_off {
    a.cke_combo_button {
      &:hover,
      &:focus,
      &:active {
        border: none;
        box-shadow: none;
        background-image: none;
      }
    }
  }

  .cke_combo_on a.cke_combo_button {
    border: none;
    box-shadow: none;
    background-image: none;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  .cke_button_label,
  .cke_combo_text {
    text-shadow: none;
  }

  a.cke_path_item {
    color: #474747 !important;
    text-shadow: none;

    &:hover,
    &:focus,
    &:active {
      text-shadow: none;
      box-shadow: none;
    }
  }

  //Dialog
  .cke_dialog_background_cover {
    background-color: rgba(0, 0, 0, 0.95) !important;
  }

  .cke_dialog {
    border-radius: 4px;
  }

  .cke_dialog_body {
    border: none;
    padding: 15px;
    background: #ffffff;
  }

  .cke_dialog_title {
    border-bottom: none;
    box-shadow: none;
    background: #ffffff;
    background-image: none;
    padding: 10px 10px;
    text-shadow: none;
    font-size: 16px;
  }

  .cke_dialog_footer {
    outline: none;
    box-shadow: none;
    background: #ffffff;
    background-image: none;
  }

  a.cke_dialog_ui_button {
    border: none;
    border-bottom-color: unset;
    box-shadow: none;
    background: transparent;
    background-image: none;

    &:hover,
    &:focus,
    &:active {
      border-color: unset;
      background: transparent;
      background-image: none;
      box-shadow: none;
      padding: 4px 0;
    }

    span {
      color: #8c8c8c !important;
      text-shadow: none;
    }
  }

  a.cke_dialog_ui_button_ok {
    color: #212529 !important;
    text-shadow: none;
    border-color: unset;
    background: #d4d4d6;
    background-image: none;
    transition: all 0.3s;

    &:hover,
    &:focus,
    &:active {
      border-color: unset;
      background: #117a8b;
      background-image: none;
      transition: all 0.3s;

      span {
        color: #ffffff !important;
      }
    }

    span {
      color: #212529 !important;
    }
  }

  .cke_dialog_footer_buttons a.cke_dialog_ui_button span {
    font-weight: 200;
  }

  // .cke_dialog_ui_hbox_first, .cke_dialog_ui_hbox_child, .cke_dialog_ui_hbox_last {
  //     float: right;
  // }

  .cke_dialog .cke_resizer_ltr {
    display: none;
  }

  .cke_dialog iframe.cke_pasteframe {
    background-color: #eceff1;
    border: 1px solid #ced4da;
  }

  input.cke_dialog_ui_input_text,
  input.cke_dialog_ui_input_password,
  textarea.cke_dialog_ui_input_textarea,
  input.cke_dialog_ui_input_text:focus,
  input.cke_dialog_ui_input_password:focus,
  textarea.cke_dialog_ui_input_textarea:focus,
  input.cke_dialog_ui_input_text:hover,
  input.cke_dialog_ui_input_password:hover,
  textarea.cke_dialog_ui_input_textarea:hover {
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: none;
    background-color: #eceff1;
    border: 1px solid #ced4da;
  }

  select.cke_dialog_ui_input_select,
  input.cke_dialog_ui_input_text:focus,
  input.cke_dialog_ui_input_password:focus,
  textarea.cke_dialog_ui_input_textarea:focus,
  select.cke_dialog_ui_input_select:focus {
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #eceff1;
    border: 1px solid #ced4da;
    box-shadow: none;
  }

  .cke_dialog .ImagePreviewBox {
    border-radius: 4px;
    padding: 5px 8px;
    background-color: #eceff1;
    border: 1px solid #ced4da;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .cke_dialog_tabs {
    left: 15px;
  }

  a.cke_dialog_tab {
    border: none;
    background: #eceff1;
    border: 1px solid #ced4da !important;
    color: #383838;
  }

  a.cke_dialog_tab_selected,
  a.cke_dialog_tab_selected:hover,
  a.cke_dialog_tab_selected:focus,
  a.cke_dialog_tab:hover,
  a.cke_dialog_tab:focus {
    background: transparent;
  }

  &.theme-dark {
    .cke_top {
      background: #272e2f;
    }

    .cke_bottom {
      background: #272e2f;
    }

    .cke_wysiwyg_frame,
    .cke_wysiwyg_div,
    textarea.cke_source {
      background-color: #202627;
    }

    textarea.cke_source {
      color: #c7c2c2;
    }

    .cke_toolgroup {
      background: #383f43;
    }

    .cke_button_icon {
      filter: invert(1) brightness(2);
    }

    a.cke_combo_button {
      background: #383f43;
    }

    .cke_combo_off {
      a.cke_combo_button {
        &:hover,
        &:focus,
        &:active {
          background: #394142;
        }
      }
    }

    .cke_combo_on a.cke_combo_button {
      background: #394142;
    }

    .cke_button_label,
    .cke_combo_text {
      color: #fff;
    }

    .cke_button_arrow,
    .cke_combo_arrow {
      filter: invert(1);
    }

    a.cke_path_item {
      color: #8c8c8c !important;

      &:hover,
      &:focus,
      &:active {
        background-color: #394142;
        color: #fff;
      }
    }

    //Dialog
    .cke_dialog_body {
      background: #2e3435;
    }

    .cke_dialog_title,
    .cke_dialog_footer,
    .cke_dialog_contents {
      background: #2e3435;

      table {
        background-color: transparent;
      }
    }

    .cke_dialog_contents {
      border-top: 1px solid #394142;
    }

    .cke_reset_all,
    .cke_reset_all *,
    .cke_reset_all a,
    .cke_reset_all textarea {
      color: #8c8c8c;
    }

    a.cke_dialog_close_button {
      filter: invert(1) brightness(2);
      top: 20px;
      right: 20px !important;
    }

    .cke_dialog iframe.cke_pasteframe {
      background-color: #8b8e90;
      border: unset;
    }

    a.cke_dialog_ui_button_ok {
      background: #383f43;

      &:hover,
      &:focus,
      &:active {
        background: #117a8b;
      }

      span {
        color: #ffffff !important;
      }
    }

    input.cke_dialog_ui_input_text,
    input.cke_dialog_ui_input_password,
    textarea.cke_dialog_ui_input_textarea,
    input.cke_dialog_ui_input_text:focus,
    input.cke_dialog_ui_input_password:focus,
    textarea.cke_dialog_ui_input_textarea:focus,
    input.cke_dialog_ui_input_text:hover,
    input.cke_dialog_ui_input_password:hover,
    textarea.cke_dialog_ui_input_textarea:hover {
      background-color: #394142;
      border: none;
    }

    select.cke_dialog_ui_input_select,
    input.cke_dialog_ui_input_text:focus,
    input.cke_dialog_ui_input_password:focus,
    textarea.cke_dialog_ui_input_textarea:focus,
    select.cke_dialog_ui_input_select:focus {
      background-color: #394142;
      border: none;
    }

    .cke_dialog a.cke_btn_locked,
    .cke_dialog a.cke_btn_unlocked,
    .cke_dialog a.cke_btn_reset {
      filter: invert(1) brightness(1);
    }

    .cke_dialog .ImagePreviewBox {
      background-color: #394142;
      border: none;
    }

    a.cke_dialog_tab {
      background: transparent;
      border: 1px solid #394142 !important;
    }

    a.cke_dialog_tab_selected,
    a.cke_dialog_tab_selected:hover,
    a.cke_dialog_tab_selected:focus,
    a.cke_dialog_tab:hover,
    a.cke_dialog_tab:focus {
      background: #394142;
    }

    .cke_dialog a.cke_btn_locked,
    .cke_dialog a.cke_btn_unlocked,
    .cke_dialog a.cke_btn_reset {
      filter: invert(1) brightness(1);
    }
  }
}

//Project List
.theme-light-pqs {
  .project_list {
    &.table_list {
      table,
      .mat-table {
        background-color: inherit;

        td,
        th,
        .mat-cell {
          padding: 15px 10px;
          border-top: unset;
          border: unset;
          border-bottom: 1px solid #d4d4d6;
          min-width: auto;
          white-space: inherit;
          word-break: break-word;
        }
        th {
          font-size: 12px;
          font-weight: 500 !important;
        }
        .mat-header-cell {
          border: unset;
          min-width: auto;
          height: 100%;
          width: auto;
          font-size: 12px !important;
          padding: 15px 10px;
          height: 20px;
          min-height: 40px;
        }
        tbody tr,
        .mat-row,
        .mat-cell {
          background: transparent !important;
          height: 20px;
          min-height: 40px;
          // white-space: break-spaces;
        }
        tbody tr,
        .mat-row {
          &:hover {
            background-color: rgba(20, 24, 25, 0.1) !important;
          }
        }
        .mat-sort-header-arrow {
          position: relative;
          right: unset;
        }
        .link {
          color: #222222;
          font-weight: 500;
        }
        .text-neutral {
          color: #c3c3c3;
        }
        .text-muted {
          color: #808080;
        }
        small {
          font-size: 10px;
        }
        // tr > td:nth-child(6) {
        //     border-left: 1px solid #c3c3c3;
        //     padding-left: 20px;
        // }
      }
    }
  }

  &.theme-dark {
    .project_list {
      &.table_list {
        table,
        .mat-table {
          td,
          th,
          .mat-cell {
            border-bottom: 1px solid #202627;
          }
          .link {
            color: #ffffff;
          }
        }
      }
    }
  }
}

//Confirmation Dialog
.theme-light-pqs {
  // .kt-mat-dialog-container__wrapper {
  //     width: 500px !important;
  // }

  .kt-portlet {
    background-color: #ffffff;

    .kt-portlet__head {
      border-bottom: none;
      padding-top: 10px;

      .kt-portlet__head-label .kt-portlet__head-title {
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #8c8c8c;
      }

      .kt-portlet__head-progress {
        top: 0;
      }
    }

    .kt-portlet__body {
      color: #8c8c8c;
      font-size: 14px;
      overflow-wrap: break-word;

      .kt-form__group {
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .kt-portlet__foot {
      border-top: none;
      .kt-form__actions {
        padding-bottom: 24px;
        padding-top: 0px;

        .mat-button.mat-button-base {
          font-weight: 400;
          color: #8c8c8c;
          font-size: 12px;
          min-width: 55px;
          line-height: 27px;
          opacity: 0.5;

          &.mat-primary {
            color: #212529;
            background-color: #d4d4d6;
            float: right;
            opacity: 1;
            transition: all 0.15s;

            &:hover {
              color: #ffffff;
              background-color: #117a8b !important;
              transition: all 0.15s;
            }
          }

          &:hover {
            opacity: 1;
          }

          .mat-button-focus-overlay {
            background: transparent;
          }
        }
      }
    }
  }

  &.theme-dark {
    .kt-portlet {
      background-color: #FFFFFF;
      color: #394142;

      .kt-portlet__head {
        .kt-portlet__head-label .kt-portlet__head-title {
          color: #394142;
        }
      }

      .kt-portlet__foot {
        border-top: none;

        .kt-form__actions {
          padding-bottom: 24px;
          padding-top: 0px;

          .mat-button.mat-button-base {
            color: #394142;

            &.mat-primary {
              color: #ffffff;
              background-color: #FD7B03;
            }
          }
        }
      }
    }
  }
}

// Material Tooltip
.theme-light-pqs,
.theme-light-pqs.theme-dark {
  .mat-tooltip {
    font-size: 12px;
    color: #666666;
    background: #ffffff;
    box-shadow: 0px 10px 50px 0px rgb(0 0 0 / 20%);
  }
}

.mat-snack-bar-container {
  color: #fff !important;
  background-color: #ee2558 !important;
  border-color: #fff !important;
  z-index: 999;
}
.mat-simple-snackbar-action {
  color: #fff;
  font-size: 15px;
}
.shimmer {
  display: inline-block;
  -webkit-mask: linear-gradient(-85deg, #000 30%, rgba(217, 217, 217, 0.333), #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  border-radius: 5px;
  // height: 13em;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.width-30 {
  width: 30%;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.fixed-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

// tambahan untuk markup
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.hide-div {
  display: none;
}

.padding-horizontal {
  padding: 0 0.5em;
}

.cdk-overlay-container {
  z-index: 2000;
}

/* List Group
---------- */

.theme-light-pqs {
  .list-group {
    width: 100%;

    .list-single {
      display: flex;
      justify-content: start;
      align-items: center;
      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem;
      border-bottom: 1px solid #ced4da;

      .list-side {
        display: flex;
        justify-content: start;
        align-items: center;
      }

      .list-info {
        .list-title {
          font-size: 14px;
          margin-bottom: 0;
        }

        .list-desc {
          font-size: 12px;
          margin-bottom: 0;
        }
      }

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none !important;
      }
    }
  }

  .hr-notification {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  }

  &.theme-dark {
    .list-group {
      .list-single {
        border-bottom: 1px solid #ced4da;
      }
    }

    .hr-notification {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
    }
  }

  .custom-min-width {
    min-width: 2px !important;
  }
}

@keyframes orange-dissolve {
  0% {
    background-color: #FD7B03;
  }
  100% {
    background-color: transparent;
  }
}        

svg.leader-line {
  pointer-events: all !important;
}

.pagination-flex {
  display: flex !important;
  align-items: center;
  flex-direction: row;
  column-gap: 30px;
  justify-content: center;
}
