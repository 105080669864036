/* Tabs
---- */

.theme-light-pqs {
    .nav-tabs {
      padding: 0px 0px 12px;
      display: table;
      width: 100%;
      background: #ffffff;
      border-radius: 100px;
  
      &.nav-tabs-vertical {
        display: inline-grid;
        text-align-last: left;
        max-height: calc(100vh - 14rem);
        overflow: auto;
  
        .nav-item .nav-link {
          padding-left: 0px;
          padding-top: 12px;
          border-bottom: 1px solid #d4d4d6;
        }
      }
  
      > .nav-item {
        display: table-cell;
        // width: calc(100% - 115px);
        text-align: center;
  
        > .nav-link {
          border-radius: 0px;
          font-size: 10px;
          padding: 8px;
          margin-right: 0px;
          cursor: pointer;
          transition: none !important;
  
          &:hover {
            color: #5cc5cd;
  
            i {
              color: #5cc5cd;
            }
          }
  
          &.active {
            // color: #222222;
            border-radius: 0px;
  
            i {
              color: #5cc5cd;
            }
          }
  
          &:active {
            color: #888888;
  
            i {
              color: #888;
            }
          }
  
          i {
            color: #888888;
          }
        }
      }
  
      > .nav-item-pmi {
        display: table-cell;
        // width: calc(100% - 115px);
        text-align: center;
  
        > .nav-link-pmi {
          width: 200px;
          border-radius: 0px;
          font-size: 10px;
          padding: 8px;
          margin-right: 5px;
          display: block;
          background: #2a2e2f;
          color: #888888;
          border-radius: 8px 8px 0 0;
  
          &:hover {
            color: #5cc5cd;
  
            i {
              color: #5cc5cd;
            }
          }
  
          &.active {
            border: 1px solid #5cc5cd;
            color: white;
            background: #394142;
            border-radius: 8px 8px 0 0 !important;
  
            i {
              color: #5cc5cd;
            }
          }
  
          &:active {
            color: #888888;
  
            i {
              color: #888;
            }
          }
  
          i {
            color: #888888;
          }
        }
      }
  
      &.nav-tabs-success {
        > .nav-item {
          > .nav-link,
          > .nav-link:hover,
          .nav-link i,
          .nav-link i:hover,
          .nav-link:not(.btn):hover {
            color: #2E3536;
            vertical-align: middle;
            font-size: 10px !important;
            background: transparent;
            display: flex;
            place-items: center;
            justify-content: center;
  
            &.active,
            &.active i,
            &.active:hover {
              border: none;
              color: #ffffff;
              background: rgb(10,99,111);
              background: -moz-linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
              background: -webkit-linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
              background: linear-gradient(90deg, rgba(10,99,111,1) 0%, rgba(19,190,213,1) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a636f",endColorstr="#13bed5",GradientType=1);
              border-radius: 5px;
              font-weight: 500;
            }
          }
        }
      }
    }
  
    .nav-tabs-pmi {
      width: 0;
      padding: 0 56em 0 0;
      position: fixed;
      z-index: 999;
      top: 87px;
      background: #e1e4e6;
    }
  
    &.theme-dark {
      .nav-tabs {
        &.nav-tabs-success {
          > .nav-item {
            > .nav-link,
            > .nav-link:hover,
            .nav-link i,
            .nav-link i:hover,
            .nav-link:not(.btn):hover {
              &.active,
              &.active i,
              &.active:hover {
                color: #222;
                background: #FD7B03;
              }
            }
          }
        }
  
        &.nav-tabs-vertical {
          .nav-item .nav-link {
            border-bottom: 1px solid #292e33;
            &.active {
              border-bottom: 1px solid #5cc5cd;
            }
          }
        }

        > .nav-item {
          > .nav-link {
            &:hover {
              color: #FD7B03;
    
              i {
                color: #FD7B03;
              }
            }
    
            &.active {
              &:hover {
                color: #ffffff;
      
                i {
                  color: #ffffff;
                }
              }

              i {
                color: #ffffff;
              }
            }
          }
        }
      }
      .nav-tabs-pmi {
        background: #181a1b;
      }
    }
  }