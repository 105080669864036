
//Fonts Family
$body-font-family: 'Gilroy', sans-serif;
$title-font-family: 'Gilroy', sans-serif;
$md-icon: 'Material-Design-Iconic-Font';
$font-size: 12px;
$title-font-size: 16px;

$grey-50:       #f5f5f5 !default;
$grey-100:      #f7f7f7 !default;
$grey-200:      #f0f0f0 !default;
$grey-300:      #eeeeee !default;
$grey-400:      #bdbdbd !default;
$grey-500:      #999999 !default;
$grey-600:      #777777 !default;
$grey-700:      #666666 !default;
$grey-800:      #444444 !default;
$grey-900:      #222222 !default;
$grey-1000:     #202627 !default;

$blue-grey-50:  #eceff1 !default;
$blue-grey-100: #cfd8dc !default;
$blue-grey-200: #b0bec5 !default;
$blue-grey-300: #90a4ae !default;
$blue-grey-400: #78909c !default;
$blue-grey-500: #607d8b !default;
$blue-grey-600: #546e7a !default;
$blue-grey-700: #455a64 !default;
$blue-grey-800: #37474f !default;
$blue-grey-900: #263238 !default;

$red:           #ee2558 !default;
$purple:        #6f42c1 !default;
$indigo:        #9988ff !default;
$orange:        #FF9948 !default;
$amber:         #f9bd65 !default;
$yellow:        #fdd932 !default;
$green:         #04BE5B !default;
$lime:          #82c885 !default;
$blue:          #46b6fe !default;
$blush:         #e47297 !default;
$pink:          #ff4dab !default;
$aqua:          #1cbfd0 !default;
$cyan:          #5CC5CD !default;
$dark:          #292a33 !default;
$black:         #000000 !default;
$white:         #ffffff !default;

$body:          $white;
$font-color:    $grey-900;
$border:        $grey-200;

$font-weight-300:   300 !default;
$font-weight-400:   400 !default;
$font-weight-500:   500 !default;
$font-weight-600:   600 !default;
$font-weight-700:   700 !default;

// Breakpoints
$break-xsmall:  640px;
$break-small:   768px;
$break-medium:  992px;
$break-1024:    1024px;
$break-large:   1200px;
$break-1280:    1280px;
$break-xlarge:  1440px;

//Colors
$colors:(
    red: #ee2558,
    pink: #ff4dab,
    purple: #6f42c1,
    deep-purple: #673AB7,
    blue: #46b6fe,
    cyan: #5CC5CD,
    green: #04BE5B,
    light-green: #8BC34A,
    yellow: #fdd932,
    orange: #FF9948,
    deep-orange: #f83600,
    grey: #9E9E9E,
    blue-grey: #607D8B,
    indigo: #9988ff,
    light-blue: #03A9F4,
    teal: #009688,
    lime: #82c885,
    amber: #FFC107,
    brown: #795548,
    blush: #e47297,
    black: #000000,
    white: #ffffff ) !default;
    
//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$sans-serif-family:          'Montserrat', 'Helvetica Neue',  Arial, sans-serif;

$none:                       0   !default;
$border-thin:                1px !default;
$border-thick:               2px !default;

$white-color:                #FFFFFF !default;

$smoke-bg:                   #F5F5F5 !default;
$light-black:                #444444    !default;

$black-bg:                   rgba(30,30,30,.97) !default;

$black-color:                #2c2c2c !default;
$black-hr:                   #444444 !default;

$light-gray:                 #E3E3E3 !default;
$medium-gray:                #ced4da !default;
$dark-gray:                  #9A9A9A !default;

$opacity-gray-3:             rgba(222,222,222, .3) !default;
$opacity-gray-5:             rgba(222,222,222, .5) !default;
$opacity-gray-8:             rgba(222,222,222, .8) !default;


$opacity-5:                  rgba(255,255,255, .5) !default;
$opacity-8:                  rgba(255,255,255, .8) !default;

$datepicker-color-days:             rgba(255,255,255, .8)  !default;
$datepicker-color-old-new-days:     rgba(255,255,255, .4)  !default;


$opacity-1:                  rgba(255,255,255, .1) !default;
$opacity-2:                  rgba(255,255,255, .2) !default;

$transparent-bg:             transparent !default;
$dark-background:            #555555 !default;

$default-color:              #888888 !default;
$default-states-color:       lighten($default-color, 6%) !default;
$default-color-opacity:      rgba($default-color, .6) !default;

$primary-color:              #0c7ce6  !default;
$primary-states-color:       lighten($primary-color, 6%) !default;
$primary-color-opacity:      rgba($primary-color, .3) !default;
$primary-color-alert:        rgba($primary-color, .8) !default;

$success-color:              $green !default;
$success-states-color:       lighten($success-color, 6%) !default;
$success-color-opacity:      rgba($success-color, .3) !default;
$success-color-alert:        rgba($success-color, .8) !default;

$info-color:                 $aqua !default;
$info-states-color:          lighten($info-color, 6%) !default;
$info-color-opacity:         rgba($info-color, .3) !default;
$info-color-alert:           rgba($info-color, .8) !default;

$warning-color:              $orange !default;
$warning-states-color:       lighten($warning-color, 6%) !default;
$warning-color-opacity:      rgba($warning-color, .3) !default;
$warning-color-alert:        rgba($warning-color, .8) !default;

$danger-color:               $red !default;
$danger-states-color:        lighten($danger-color, 6%) !default;
$danger-color-opacity:       rgba($danger-color, .3) !default;
$danger-color-alert:         rgba($danger-color, .8) !default;

/*     brand Colors              */
$brand-primary:              $primary-color !default;
$brand-info:                 $info-color !default;
$brand-success:              $success-color !default;
$brand-warning:              $warning-color !default;
$brand-danger:               $danger-color !default;
$brand-inverse:              $black-color !default;

$link-disabled-color:        $grey-600 !default;

/*      light colors         */
$light-blue:                 rgba($primary-color, .2);
$light-azure:                rgba($info-color, .2);
$light-green:                rgba($success-color, .2);
$light-orange:               rgba($warning-color, .2);
$light-red:                  rgba($danger-color, .2);

//== Components
//

$padding-input-vertical:        11px !default;
$padding-input-horizontal:      19px !default;

$padding-btn-vertical:         8px !default;
$padding-btn-horizontal:       18px !default;

$padding-base-vertical:        .5rem !default;
$padding-base-horizontal:      .7rem !default;

$padding-round-horizontal:     23px !default;

$padding-simple-vertical:      10px !default;
$padding-simple-horizontal:    17px !default;

$padding-large-vertical:       12px !default;
$padding-large-horizontal:     40px !default;

$padding-small-vertical:        5px !default;
$padding-small-horizontal:     12px !default;

$padding-label-vertical:        2px !default;
$padding-label-horizontal:     12px !default;

$margin-large-vertical:        30px !default;
$margin-base-vertical:         15px !default;

$margin-base-horizontal:       15px !default;

$margin-bottom:                 10px        !default;
$border:                        1px solid   !default;
$border-radius-extra-small:     0.125rem    !default;
$border-radius-small:           0.2875rem   !default;
$border-radius-large:           0.35rem     !default;
$border-radius-extreme:         0.975rem    !default;

$border-radius-large-top:      $border-radius-large $border-radius-large 0 0 !default;
$border-radius-large-bottom:   0 0 $border-radius-large $border-radius-large !default;

$btn-round-radius:             30px         !default;

$height-base:                  55px         !default;

$btn-icon-size:                 3.5rem       !default;
$btn-icon-size-regular:         2.375rem      !default;
$btn-icon-font-size-regular:    0.9375rem     !default;
$btn-icon-font-size-small:      0.6875rem      !default;
$btn-icon-size-small:           1.875rem     !default;
$btn-icon-font-size-lg:         1.325rem     !default;
$btn-icon-size-lg:              3.6rem         !default;

$font-size-h1:                 3em        !default;
$font-size-h2:                 2em        !default;
$font-size-h3:                 1.8em          !default;
$font-size-h4:                 1.614em      !default;
$font-size-h5:                 1.37em       !default;
$font-size-h6:                 0.9em          !default;

$font-paragraph:               1em          !default;
$font-size-navbar:             1em          !default;
$font-size-mini:               0.7142em     !default;
$font-size-small:              0.8171em     !default;
$font-size-base:               12px         !default;
$font-size-large:              1em          !default;
$font-size-large-navbar:       20px         !default;
$font-size-blockquote:         1.1em        !default; // ~ 15px

$line-height-general:        1.5            !default;
$line-height-nav-link:       1.625rem       !default;
$btn-icon-line-height:       2.4em          !default;
$line-height:                1.35em         !default;
$line-height-lg:             54px           !default;


$border-radius-top:        10px 10px 0 0     !default;
$border-radius-bottom:     0 0 10px 10px     !default;

$dropdown-shadow:          1px 2px 3px rgba($dark, 0.125);
$box-shadow-raised:        0px 10px 25px 0px rgba($dark, 0.3);

$general-transition-time:  300ms             !default;

$slow-transition-time:           370ms       !default;
$dropdown-coordinates:      29px -50px       !default;

$fast-transition-time:           150ms       !default;
$select-coordinates:         50% -40px       !default;

$transition-linear:         linear           !default;
$transition-bezier:         cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease:           ease 0s;


//$navbar-padding-a:               9px 13px;
$navbar-margin-a:                15px 0px;

$padding-social-a:               10px 5px;

$navbar-margin-a-btn:            15px 0px;
$navbar-margin-a-btn-round:      16px 0px;

$navbar-padding-a-icons:         6px 15px;
$navbar-margin-a-icons:          6px  3px;

$navbar-padding-base:            0.625rem;
//$navbar-margin-brand:             5px  0px;

$navbar-margin-brand-icons:      12px auto;
$navbar-margin-btn:              15px  3px;

$height-icon-sm:				 32px;
$width-icon-sm:					 32px;
$padding-icon-sm:			     4px;
$border-radius-icon-sm:			 7px;

$height-icon-message:			 40px;
$width-icon-message:			 40px;

$height-icon-message-sm: 		 20px;
$width-icon-message-sm:			 20px;

$white-navbar:                  rgba(#FFFFFF, .96);
$primary-navbar:                rgba(#34ACDC, .98);
$info-navbar:                   rgba(#5BCAFF, .98);
$success-navbar:                rgba(#4CD964, .98);
$warning-navbar:                rgba(#FF9500, .98);
$danger-navbar:                 rgba(#FF4C40, .98);

$topbar-x:             topbar-x !default;
$topbar-back:          topbar-back !default;
$bottombar-x:          bottombar-x !default;
$bottombar-back:       bottombar-back !default;

//Nucleo Icons

$nc-font-path:        '../fonts' !default;
$nc-font-size-base:   14px !default;
$nc-css-prefix:       now-ui !default;
$nc-background-color:     #eee !default;
$nc-li-width:         (30em / 14) !default;
$nc-padding-width:    (1em/3) !default;

// Social icons color
$social-twitter:                   #55acee !default;
$social-twitter-state-color:       darken(#55acee, 5%) !default;

$social-facebook: 			       #3b5998 !default;
$social-facebook-state-color:      darken(#3b5998, 5%) !default;

$social-google: 			       #dd4b39 !default;
$social-google-state-color:        darken(#dd4b39, 5%) !default;

$social-linkedin: 			         #0077B5 !default;
$social-linkedin-state-color:        darken(#0077B5, 5%) !default;
