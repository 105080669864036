﻿.page-loader-wrapper {
    z-index: 99999999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $grey-300;
    overflow: hidden;
    text-align: center;

    p {
        font-size: $font-size + 1px;        
        font-weight: $font-weight-700;
        color: $grey-600;
        margin-top: 10px;
    }

    .loader {
        position: relative;
        top: calc(40% - 30px);
    }
}